import styled from 'styled-components';
import { Dialog as MuiDialog, DialogContent, Typography } from '@mui/material';

const DialogWrapper = styled(MuiDialog)`
  .MuiDialog-paper {
    margin: 0;
    min-width: 320px;
    width: 330px;
    max-width: 560px;
    border-radius: ${({ theme }) => `${theme.palette.custom.radius.radiusSmall}`};
    background: white;
    box-shadow: none;
    padding: 56px 16px 16px;
    ${(props) => props.theme.breakpoints.up('lg')} {
      padding: 40px 16px;
      width: 560px;
    }
  }
`;

const Header = styled.div``;

const Content = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled(Typography)`
  margin-top: 8px;
  margin-bottom: 28px;
  max-width: 380px;
  text-align: center;
`;

const CancelButton = styled(Typography)`
  margin-top: 28px;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  cursor: pointer;
  svg * {
    stroke: ${({ theme }) => `${theme.palette.grey[600]}`};
  }
  position: absolute;
  top: 24px;
  right: 24px;
`;

const IllustrationWrapper = styled.div`
  width: 240px;
  height: 240px;
  svg {
    width: 100%;
    height: 100%;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 360px;
    height: 360px;
  }
`;

export const FeedBackModalStyles = {
  DialogWrapper,
  Header,
  Content,
  Text,
  CancelButton,
  IconWrapper,
  IllustrationWrapper,
};
