import { SVGProps } from 'react';

interface InfoPros {
  color?: string;
  props?: SVGProps<SVGSVGElement>;
}

export const InfoIcon = ({ props, color }: InfoPros): JSX.Element => (
  <svg {...props} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_4853_7978)'>
      <path
        d='M22.25 12C22.25 17.6609 17.6609 22.25 12 22.25C6.33908 22.25 1.75 17.6609 1.75 12C1.75 6.33908 6.33908 1.75 12 1.75C17.6609 1.75 22.25 6.33908 22.25 12Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.5469 7.42969C10.5469 6.8213 11.0401 6.32812 11.6484 6.32812C12.2568 6.32812 12.75 6.8213 12.75 7.42969C12.75 8.03808 12.2568 8.53125 11.6484 8.53125C11.0401 8.53125 10.5469 8.03808 10.5469 7.42969Z'
        fill={color}
      />
      <path
        d='M14.1094 16.2188H13.4062C13.0333 16.2188 12.6756 16.0706 12.4118 15.8069C12.1481 15.5432 12 15.1854 12 14.8125V11.2969C12 11.1104 11.9259 10.9315 11.794 10.7997C11.6622 10.6678 11.4833 10.5938 11.2969 10.5938H10.5938'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_4853_7978'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
