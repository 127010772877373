import { ar, enGB } from 'date-fns/locale';
import { setDefaultOptions } from 'date-fns';

export const setGlobalLocale = (locale: any) => {
  let localeObject;
  switch (locale) {
    case 'en':
      localeObject = enGB;
      document.documentElement.dir = 'ltr';
      break;
    default:
      localeObject = enGB;
      break;
  }
  setDefaultOptions({ locale: localeObject });
};
