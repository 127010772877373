import { useEffect, useState } from 'react';
import {
  useQueryData,
  GetComparableByIdResponse,
  DEFAULT_OVERRIDE_CACHE_TIME,
  useGetComparableById,
  Answer,
} from '@ecolytiq/js-data-provider';

import { RandomComparableById } from 'impactApp/modules/HomePage';
import { useDeps } from 'App/context';
import { useTranslation } from 'react-i18next';

export const useRandomComparablesByIdData = (id: string, answers?: Answer[]) => {
  const { httpClientService } = useDeps();

  const [response, setResponse] = useState<Omit<RandomComparableById, 'isLoading'>>({
    randomComparable: {},
  });

  const { i18n } = useTranslation();

  const { randomComparable } = response;

  const { isLoading, isFetching, data, refetch } = useQueryData<{ data: GetComparableByIdResponse }>(
    useGetComparableById(httpClientService, id),
    {
      staleTime: DEFAULT_OVERRIDE_CACHE_TIME,
      cacheTime: DEFAULT_OVERRIDE_CACHE_TIME,
    },
  );

  useEffect(() => {
    if (data?.data) {
      setResponse({
        randomComparable: data.data.randomComparable,
      });
    }
  }, [data, setResponse]);

  useEffect(() => {
    if (answers || i18n.language) refetch();
  }, [answers, refetch, i18n.language]);

  return {
    isLoading: isLoading || isFetching,
    randomComparable,
  };
};
