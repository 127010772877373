import { Typography } from '@mui/material';

import { Grid } from 'components';

import { ContentStyles } from './Content.styles';

type ContentProps = {
  image: JSX.Element | null;
  information: string | null;
  title: string | null;
};

export const Content = ({ image: imageFromProps, information, title }: ContentProps) => {
  const image = imageFromProps && (
    <Grid alignItems='center' justifyContent='flex-end' width='42%'>
      {imageFromProps}
    </Grid>
  );

  return (
    <ContentStyles.Wrapper>
      <ContentStyles.TextContent>
        <ContentStyles.Title
          color='grey.600'
          variant='subtitle2'
          sx={{ typography: { sm: 'subtitle2', lg: 'subtitle1' } }}
        >
          {title}
        </ContentStyles.Title>
        <ContentStyles.Content>
          <Typography color='grey.600' component='p' variant='body2' sx={{ typography: { lg: 'body' } }}>
            {information}
          </Typography>
        </ContentStyles.Content>
      </ContentStyles.TextContent>
      {image}
    </ContentStyles.Wrapper>
  );
};
