import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface HydroPowerProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const HydroPower = ({ props, isActive }: HydroPowerProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_7253_25318)'>
        <path
          d='M32.7344 22.8906L27.1928 29.3425C27.1497 29.3938 27.1221 29.4563 27.1132 29.5227C27.1043 29.5891 27.1146 29.6566 27.1427 29.7174C27.1708 29.7782 27.2157 29.8297 27.2721 29.866C27.3284 29.9022 27.394 29.9216 27.461 29.9219H29.9219V35.5469L35.4635 29.095C35.5066 29.0437 35.5342 28.9812 35.5431 28.9148C35.5519 28.8484 35.5417 28.7809 35.5136 28.7201C35.4854 28.6593 35.4406 28.6078 35.3842 28.5715C35.3278 28.5353 35.2623 28.5159 35.1953 28.5156H32.7344V22.8906Z'
          stroke={isActiveState ? '#ffffff' : main}
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M22.8906 22.1875C22.8906 23.3064 22.4462 24.3794 21.655 25.1706C20.8638 25.9618 19.7908 26.4062 18.6719 26.4062C17.553 26.4062 16.4799 25.9618 15.6888 25.1706C14.8976 24.3794 14.4531 23.3064 14.4531 22.1875C14.4531 19.375 17.9688 14.4531 18.6719 14.4531C19.375 14.4531 22.8906 19.375 22.8906 22.1875Z'
          stroke={isActiveState ? '#ffffff' : main}
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M18.6719 23.5938C18.2989 23.5938 17.9412 23.4456 17.6775 23.1819C17.4138 22.9181 17.2656 22.5605 17.2656 22.1875'
          stroke={isActiveState ? '#ffffff' : main}
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M26.4063 34.1144C26.1747 34.1313 25.9375 34.1406 25.7041 34.1406C24.0296 34.1412 22.3871 33.6816 20.956 32.8122C19.5249 31.9427 18.3602 30.6967 17.5891 29.2103'
          stroke={isActiveState ? '#ffffff' : main}
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M24.2969 15.9672C26.0506 15.6957 27.8455 15.9398 29.463 16.6698C31.0805 17.3998 32.4509 18.5843 33.4075 20.0791'
          stroke={isActiveState ? '#ffffff' : main}
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_7253_25318'>
          <rect width='24' height='24' fill='white' transform='translate(13 13)' />
        </clipPath>
      </defs>
    </svg>
  );
};
