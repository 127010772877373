import { useEffect, useState } from 'react';
import {
  useQueryData,
  GetTransactionsResponse,
  MonthYearInput,
  DEFAULT_OVERRIDE_CACHE_TIME,
  MetaCategoryType,
  useGetTransactions,
} from '@ecolytiq/js-data-provider';

import { Transactions } from 'impactApp/modules/HomePage';
import { useDeps } from 'App/context';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from 'impactApp/routes';

export const useTransactionsListData = (month: MonthYearInput, category: MetaCategoryType) => {
  const { httpClientService } = useDeps();
  const [response, setResponse] = useState<Omit<Transactions, 'isLoading'>>({
    transactions: [],
  });
  const { transactions } = response;
  const navigate = useNavigate();
  const { SSO_PAGE } = RoutePaths;
  const { isLoading, isFetching, data } = useQueryData<{ data: GetTransactionsResponse }>(
    useGetTransactions(httpClientService, month, category),
    {
      staleTime: DEFAULT_OVERRIDE_CACHE_TIME,
      cacheTime: 0,
    },
  );

  useEffect(() => {
    if (data?.data?.transactions) {
      setResponse({
        transactions: data.data.transactions.results.reverse(),
      });
    } else if (!isLoading && !data) {
      navigate(SSO_PAGE);
    }
  }, [data, setResponse, navigate, SSO_PAGE, isLoading]);

  return {
    isLoading: isLoading || isFetching,
    transactions,
  };
};
