import styled from 'styled-components';

import { ComparisonValue } from 'App/types';

const SQUARE_ICON_SIZE = '28px';

const arrowRotation = (trend: ComparisonValue): string => {
  const arrowRotations = {
    [ComparisonValue.DECREASE]: 'rotate(45deg)',
    [ComparisonValue.INCREASE]: 'rotate(-45deg)',
    [ComparisonValue.CONSTANT]: 'none',
  };

  return arrowRotations[trend];
};

export const ComparisonArrowIconWrapper = styled.span<{
  trend: ComparisonValue;
}>`
  display: flex;
  height: ${SQUARE_ICON_SIZE};
  width: ${SQUARE_ICON_SIZE};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, trend }) => theme.palette.custom.comparison[trend]};
  flex-shrink: 0;
  svg {
    transform: ${({ trend }) => arrowRotation(trend)};
  }
`;
