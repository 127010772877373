import { Typography } from '@mui/material';
import { Grid } from 'components';

import { useTranslation } from 'react-i18next';

import { formatNumber } from 'App/utils';

import { ComparisonStyles } from './Comparison.styles';

type ComparisonProps = {
  comparison: [string, string];
  comparisonMessage: string;
};

export const Comparison = ({ comparison, comparisonMessage }: ComparisonProps) => {
  const { t, i18n } = useTranslation();
  const currentLanguge = i18n.resolvedLanguage;

  return (
    <Grid alignItems='baseline' spacingInside={{ top: 0.5, bottom: 0.5 }}>
      {currentLanguge === 'en' ? (
        <>
          <ComparisonStyles.Value variant='h6' sx={{ typography: { sm: 'subtitle1', lg: 'h6' } }} color='grey.600'>
            {formatNumber(+comparison[0])} {t(comparison[1])}
          </ComparisonStyles.Value>
          <Typography variant='body2' color='grey.600'>
            {comparisonMessage}
          </Typography>
        </>
      ) : (
        <ComparisonStyles.Value variant='h6' sx={{ typography: { sm: 'subtitle1', lg: 'h6' } }} color='grey.600'>
          {comparisonMessage} {formatNumber(+comparison[0])} {t(comparison[1])}
        </ComparisonStyles.Value>
      )}
    </Grid>
  );
};
