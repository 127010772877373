import { SVGProps } from 'react';

export const KeyboardArrowLeftIcon = (props?: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg {...props} width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.5 12.25H3M3 12.25L6.75 16M3 12.25L6.75 8.5'
        stroke='#1F242F'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
