export const profileSettings = {
  title: 'Personal profile.',
  subtitle: 'To calculate the most accurate CO₂e footprint estimate, here are 2 short questions.',
  text: 'Just pick the answer that most closely matches your situation; you can always adjust it later.',
  startButton: 'Get started',
  submitButton: 'Submit',
  nextButton: 'Next',
  cardButton: "Haven't added you card yet? <br /> <a href='/card/'>Click here</a>",
  sliderTitle: 'Profile settings',
  slider: 'Provide detailed information on your lifestyle to get a more accurate carbon emission estimate.',
  steps: [
    {
      title: 'Your card was successfully added!',
      text: "We are now working to gather data for you, please check back again in a few days!<br/>  <br/> In the meantime, let's get to know you a bit better. This will help us calculate the most accurate footprint estimate.",
      buttonText: 'Continue',
    },
    {
      title: 'Curious about the biggest drivers of your carbon footprint estimate?',
      text: 'You will receive a breakdown of your carbon footprint estimate by various spending categories.',
      buttonText: 'Next',
    },
    {
      title: 'Tailor your estimate based on your lifestyle',
      text: 'We analyze your expenses to estimate your footprint estimate. Answer a few simple questions to help make this even more personalized for you.',
      buttonText: 'Get started',
    },
    {
      title: 'Calculation in progress',
      text: 'Buy something to see your impact. <br /> <br /> To generate your footprint estimate for last year we need up to 24 hours',
      buttonText: '',
    },
  ],
};
