export enum QuestionType {
  SELECTABLE = 'selectable',
  FREE_TEXT = 'free_text',
}

type SelectableAnswer = {
  text: string;
  id: string;
};

type SelectableQuestion = {
  question: string;
  questionId: string;
  type: QuestionType.SELECTABLE;
  answers: SelectableAnswer[];
};

type FreeTextQuestion = {
  question: string;
  questionId: string;
  type: QuestionType.FREE_TEXT;
  answerId: string;
};

export type FeedbackAnswer = {
  answer_id: string;
  free_text?: string;
};

export type Question = SelectableQuestion | FreeTextQuestion;
