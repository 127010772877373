import { useEffect, useState } from 'react';

import { AccordionItem } from '../AccordionItem';
import { AccordionAppearance } from '../enums';
import { ItemList } from '../types';

export const useAccordion = (
  itemList: ItemList,
  appearance: AccordionAppearance = AccordionAppearance.LIGHT,
  showCounter?: boolean,
  faqIndex?: number,
) => {
  const [expanded, setExpanded] = useState<number | false>(faqIndex || false);

  const scrollToHowItWorks = () => {
    const element = document.getElementById('how-it-works-list');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (faqIndex == 0 || faqIndex == 1 || faqIndex == -1) setExpanded(faqIndex);
  }, [faqIndex]);

  useEffect(() => {
    if (faqIndex == 0 || faqIndex == 1) scrollToHowItWorks();
  }, [expanded, faqIndex]);

  return itemList?.map(({ title, body, titleCollapsed }, index) => {
    const isExpanded = expanded === index;
    const handleChange = () => {
      if (expanded !== index) {
        setExpanded(index);
      } else {
        setExpanded(false);
      }
    };

    return (
      <AccordionItem
        key={title}
        counter={showCounter ? index : undefined}
        appearance={appearance}
        body={body}
        isExpanded={isExpanded}
        onChange={handleChange}
        title={title}
        titleCollapsed={titleCollapsed}
      />
    );
  });
};
