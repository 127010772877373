import styled from 'styled-components';

import { Typography } from '@mui/material';

import { Grid, Paper } from 'components';

const Wrapper = styled(Grid)`
  align-self: center;
  padding-top: 30px;
  padding-bottom: 44px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    padding-top: 80px;
    padding-bottom: 90px;
  }
`;

const PaperContainer = styled.div`
  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 32px 0 48px;
  }
`;

const Footprint = styled.div`
  padding-bottom: 8px;
  display: flex;
  justify-content: space-around;
  color: ${({ theme }) => theme.palette.common.white};
  ${(props) => props.theme.breakpoints.up('lg')} {
    padding-bottom: 16px;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
`;

const Details = styled(Typography)`
  margin-bottom: 16px;
  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-bottom: 8px;
  }
`;

const ButtonWrapper = styled.div`
  padding-top: 32px;
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: center;
  ${(props) => props.theme.breakpoints.up('lg')} {
    padding-top: 56px;
  }
`;

const InsightsContainer = styled.div`
  padding: 40px 28px 32px;
  max-width: 465px;
  margin: 0 auto;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 60px 0 112px;
  }
`;

const InsightsWrapper = styled.div`
  background: linear-gradient(
    ${({ theme }) => `${theme.palette.common.white}`} 40%,
    ${({ theme }) => `${theme.palette.secondary.main}`} 0%
  );
  ${(props) => props.theme.breakpoints.up('md')} {
    background: none;
  }
`;

const QuestionsWrapper = styled.div`
  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 100px 0 56px;
    max-width: 465px;
    margin: 0 auto;
  }
`;

const ComparableWrapper = styled.div`
  margin-bottom: 16px;
  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-bottom: 12px;
  }
`;

const PaperWrapper = styled(Paper.RoundedTop)``;

export const TransactionDetails = {
  Details,
  Footprint,
  Info,
  Wrapper,
  InsightsWrapper,
  ButtonWrapper,
  InsightsContainer,
  QuestionsWrapper,
  ComparableWrapper,
  PaperWrapper,
  PaperContainer,
};
