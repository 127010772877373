import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface YesIconProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const YesIcon = ({ props, isActive }: YesIconProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} width='41' height='40' viewBox='0 0 41 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.2389 20.3438L15.2388 20.3438C14.9278 20.0456 14.9196 19.5557 15.22 19.2475L15.2201 19.2473C15.5226 18.9368 16.0238 18.9283 16.3371 19.2287L16.3371 19.2287L20.4431 23.1651L20.4431 23.1652C20.7542 23.4633 20.7624 23.9533 20.462 24.2615L20.4619 24.2616C20.3077 24.4199 20.1023 24.5001 19.8939 24.5001C19.6948 24.5001 19.4978 24.4268 19.3449 24.2803C19.3448 24.2802 19.3448 24.2802 19.3448 24.2802L15.2389 20.3438Z'
        fill={isActiveState ? '#ffffff' : main}
        stroke={isActiveState ? '#ffffff' : main}
      />
      <path
        d='M19.343 24.2778L19.3429 24.2777C19.0331 23.9782 19.027 23.4883 19.3287 23.1813L19.3287 23.1813L26.6464 15.7363L26.6465 15.7363C26.9504 15.4269 27.4515 15.4204 27.7637 15.7222C28.0735 16.0217 28.0796 16.5116 27.7779 16.8186L27.7779 16.8186L20.4602 24.2636L20.4601 24.2636C20.3059 24.4205 20.1015 24.4999 19.8943 24.4999C19.6939 24.4999 19.4959 24.4257 19.343 24.2778Z'
        fill={isActiveState ? '#ffffff' : main}
        stroke={isActiveState ? '#ffffff' : main}
      />
    </svg>
  );
};
