import { Legend, LineChart, Line, XAxis, YAxis, ReferenceLine, Tooltip, ResponsiveContainer, Label } from 'recharts';

import { useTheme } from 'styled-components';

import { convertGramsToKg, convertGramsToLargerUnits, formatCash } from 'App/utils';

import { setMonthDeltaAction, useUserContext } from 'App/context';

import { alpha, useMediaQuery } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { useEffect, useState } from 'react';

import { ChartWrapperStyles } from './Chart.styles';

import { ChartMarker } from './ChartMarker';

import { ChartWithoutData } from './ChartWithoutData';

import { TickYAxis } from './TickYAxis';

import { MONTHS_IN_YEAR } from './constants';

import { ChartData } from './types';

import { CustomTooltip } from './CustomTooltip';

const AXIS_TICK_ITERATOR = 500;
const STROKE_DASH = '7 5';

type ChartProps = {
  data: ChartData;
  averageValue?: number;
};

const calculateChartTicks = (data: ChartData) => {
  const dataSequenceInKg = data.flat().map((item) => Number(convertGramsToKg(item.value, 0)));
  const minValue = 0;
  return (nationalAverage: number) => {
    const maxValue =
      Math.ceil(Math.max(Math.max(...dataSequenceInKg), nationalAverage) / AXIS_TICK_ITERATOR) * AXIS_TICK_ITERATOR;

    return [minValue, maxValue];
  };
};

export const Chart = ({ averageValue, data }: ChartProps) => {
  const [{ monthDelta }, dispatch] = useUserContext();
  const [index, setIndex] = useState(0);
  const { t }: any = useTranslation();

  useEffect(() => {
    if (monthDelta >= 6) {
      setIndex(1);
    } else {
      setIndex(0);
    }
  }, [monthDelta]);

  const {
    palette: {
      primary: { main: stroke },
    },
  } = useTheme();

  const convertedAverage = averageValue && convertGramsToLargerUnits(averageValue / MONTHS_IN_YEAR);
  const nationalAverage = (convertedAverage && +convertedAverage[0]) || 0;
  const labelValue = averageValue && convertGramsToKg(averageValue / MONTHS_IN_YEAR, 0);

  const ticks = calculateChartTicks(data)(nationalAverage);
  const widthYAxis = ticks[1] > 10000 ? 75 : 65;
  const nationalAverageLine = averageValue && (
    <ReferenceLine
      stroke={`${alpha(stroke, 0.3)}`}
      strokeDasharray={STROKE_DASH}
      strokeWidth='3'
      y={averageValue / 1000 / MONTHS_IN_YEAR}
    >
      <Label position='right' value={`${labelValue && formatCash(Number(labelValue))}`} />
    </ReferenceLine>
  );
  const legend = averageValue && <Legend verticalAlign='bottom' wrapperStyle={{ width: '100%' }} />;
  const onClickCallback = (clickEventData: any) =>
    clickEventData && dispatch(setMonthDeltaAction(clickEventData.activePayload[0].payload.monthDelta));
  const convertedData = data[index]
    .map(({ value, ...restData }) => ({
      ...restData,
      footprint: Number(convertGramsToKg(value, 0)),
    }))
    ?.reverse();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isXl = useMediaQuery(theme.breakpoints.up('xl'));

  let chartHeight = 188;

  if (isXl) {
    chartHeight = 330;
  } else if (isMd) {
    chartHeight = 255;
  }
  return data[index] && data[index].length > 0 ? (
    <ChartWrapperStyles id='chart-container'>
      <ResponsiveContainer height={chartHeight} width='100%'>
        <LineChart
          data={convertedData}
          margin={{
            top: 20,
            right: 30,
            left: 0,
            bottom: 5,
          }}
          onClick={(e) => onClickCallback(e)}
        >
          <Tooltip content={<CustomTooltip />} />
          <Line
            dataKey='footprint'
            dot={<ChartMarker monthDelta={monthDelta} />}
            legendType='none'
            stroke={stroke}
            strokeWidth={2}
            type='monotone'
          />
          <Line
            dataKey={t('chart:nationalAverage')}
            legendType='plainline'
            stroke={stroke}
            strokeDasharray={STROKE_DASH}
          />
          {nationalAverageLine}
          <YAxis
            axisLine={false}
            tick={<TickYAxis unitMass={t('enums:unitOfMass:kg')} />}
            tickLine={false}
            ticks={ticks}
            width={widthYAxis}
          />
          <XAxis axisLine={false} dataKey='date' height={60} tickLine={false} tickMargin={25} />
          {legend}
        </LineChart>
      </ResponsiveContainer>
    </ChartWrapperStyles>
  ) : (
    <ChartWithoutData />
  );
};
