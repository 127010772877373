import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface NautilusProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const Nautilus = ({ props, isActive }: NautilusProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M24.281 27.5897C24.2475 27.6838 24.2236 27.754 24.1975 27.8233C23.4808 29.7282 22.7642 31.6327 22.048 33.5372C21.7768 34.2575 21.5075 34.9787 21.2345 35.6986C21.1588 35.8986 21.2029 36.0638 21.3758 36.1803C21.5534 36.2996 21.7236 36.2583 21.8727 36.1129C21.9163 36.0702 21.9594 36.0266 22.0026 35.9835C25.5335 32.4416 29.0644 28.9001 32.5953 25.3581C32.9564 24.9957 33.3207 24.6364 33.6758 24.2685C33.8818 24.0551 33.8359 23.7445 33.5845 23.6413C33.4905 23.6028 33.3762 23.6023 33.2712 23.6023C30.5835 23.6005 27.8958 23.6009 25.2082 23.6009H24.9155C24.9522 23.4991 24.9765 23.4206 25.0077 23.3449C25.851 21.3179 26.6947 19.2914 27.5384 17.2649C27.7756 16.6951 28.0142 16.1252 28.25 15.5549C28.3308 15.3595 28.2863 15.1902 28.1133 15.0714C27.9468 14.9571 27.7807 14.9851 27.6325 15.1191C27.5361 15.2063 27.4421 15.2953 27.3476 15.3847C24.0662 18.4816 20.7844 21.5785 17.5031 24.6754C16.7314 25.404 15.9592 26.1317 15.1875 26.8607C14.971 27.0653 14.9315 27.3282 15.1242 27.4705C15.2297 27.5484 15.3899 27.5847 15.5252 27.5847C18.3427 27.5911 21.1602 27.5893 23.9777 27.5893H24.281V27.5897Z'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-miterlimit='10'
      />
    </svg>
  );
};
