import * as CSSType from 'csstype';
import { Variant } from '@mui/material/styles/createTypography';
import { formatNumber } from 'App/utils';

import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import { Grid } from '@mui/material';

import { HeaderWithValueStyles } from './HeaderWithValue.styles';

type HeaderWithValueProps = {
  value: [string, string];
  unitVariant?: Variant;
  isNegative?: boolean;
  isExtraLarge?: boolean;
  justifyContent?: CSSType.Property.JustifyContent;
  color: string;
  valueColor?: string;
};

export const HeaderWithValue = ({
  isNegative = false,
  justifyContent,
  unitVariant,
  isExtraLarge,
  value,
  color,
  valueColor,
}: HeaderWithValueProps) => {
  const valueWithVector = isNegative ? `- ${value[0]}` : value[0];
  const { t }: any = useTranslation();
  const sanitizer = DOMPurify.sanitize;

  return (
    <Grid alignItems='baseline' id='header-with-value' justifyContent={justifyContent}>
      <HeaderWithValueStyles.Value
        $isExtraLarge={isExtraLarge}
        variant='h2'
        sx={{
          typography: unitVariant ? unitVariant : { sm: 'h2', lg: 'h1' },
        }}
        color={valueColor || color}
      >
        {formatNumber(+valueWithVector)}
      </HeaderWithValueStyles.Value>
      <HeaderWithValueStyles.Unit
        variant='h6'
        sx={{
          typography: unitVariant ? unitVariant : { sm: 'h6', lg: 'h4' },
        }}
        color={color}
      >
        {t(value[1])}{' '}
      </HeaderWithValueStyles.Unit>
      <HeaderWithValueStyles.Suffix
        variant='h6'
        sx={{
          typography: unitVariant ? unitVariant : { sm: 'h6', lg: 'h4' },
        }}
        color={color}
        dangerouslySetInnerHTML={{
          __html: sanitizer(t('common:carbonDioxide')),
        }}
      />
    </Grid>
  );
};
