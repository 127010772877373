import styled from 'styled-components';
import { Link } from 'react-router-dom';

const InfoButton = styled(Link)`
  cursor: pointer;
  padding: 8px;
  display: block;
  background: transparent;
  border: 0;
`;

export const FoodSettingsButtonStyles = {
  InfoButton,
};
