import { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Layout, CURRENT_DATE } from 'App/components';

import { Categories, CategoriesTranslation, SubCategoriesImages } from 'App/types';

import { theme } from 'App/style';

import {
  GoBackButton,
  Header as LayoutHeader,
  InnerContainer,
  BackgroundWrapper,
  ButtonAppearance,
  Button,
  useModal,
} from 'components';

import { Grid, Container, useMediaQuery } from '@mui/material';

import { useUserContext } from 'App/context';

import { subMonths } from 'date-fns';

import { RoutePaths } from 'impactApp/routes';

import { useTranslation } from 'react-i18next';

import { HowItWorksModal } from '../HomePage';

import { DidYouKnow, FoodSettingsButton, ReportDetailsHeader, ReportDetailsTransactionList } from './submodules';

import { Report } from './ReportDetails.styles';

import { useCO2MonthlyData } from './useCO2MonthlyData';

import { useRandomInsightsByCategoryData } from './useRandomInsightsByCategoryData';

export const ReportDetails = () => {
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  const { Modal, openCallback: openModal } = useModal();

  const { t }: any = useTranslation();

  const { category } = useParams<{
    category: string;
  }>();

  const [{ monthDelta }] = useUserContext();

  useEffect(() => {
    document.title = 'CO2 Report Details';
  });

  const date = subMonths(CURRENT_DATE, monthDelta);

  const categoryType = category?.toUpperCase() as Categories;
  const { totalCo2ByMonth } = useCO2MonthlyData(
    {
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    },
    categoryType,
  );

  const { randomInsightByCategory, isLoading } = useRandomInsightsByCategoryData(categoryType);

  const insight: any = randomInsightByCategory;
  const { HOME } = RoutePaths;

  const Insight = insight?.title ? (
    <Report.InsightsWrapper>
      <DidYouKnow
        isLoading={isLoading}
        category={
          insight?.categories?.length > 0
            ? SubCategoriesImages[insight?.categories[0] as keyof typeof SubCategoriesImages]
            : SubCategoriesImages['ex:misc.zero']
        }
        content={insight?.text}
        title={insight?.title}
      />
    </Report.InsightsWrapper>
  ) : (
    ''
  );

  return (
    <Layout
      hideContainer
      header={
        <LayoutHeader
          button={<GoBackButton url={HOME} />}
          transparent
          title={t(CategoriesTranslation[category?.toUpperCase() as keyof typeof CategoriesTranslation])}
          rightButton={<FoodSettingsButton />}
          showDesktopNavigation
        />
      }
    >
      <HowItWorksModal Modal={Modal} />
      <ReportDetailsHeader category={categoryType} currentTotalCo2ByMonth={totalCo2ByMonth} />
      <BackgroundWrapper>
        <Report.Wrapper>
          <Container maxWidth='xl' disableGutters={!isMd}>
            <InnerContainer>
              <Grid
                container
                justifyContent='center'
                alignItems='flex-start'
                flexDirection={{ sm: 'column', md: 'row' }}
                columnSpacing={{ md: 3, lg: 7 }}
                pt={{ md: 8 }}
              >
                <Grid item md={6} lg={6} sx={{ width: { sm: '100%', md: 'auto' } }}>
                  <Report.ListWrapper>
                    <ReportDetailsTransactionList category={categoryType} currentTotalCo2ByMonth={totalCo2ByMonth} />
                  </Report.ListWrapper>
                </Grid>
                <Grid item md={6} lg={6} sx={{ width: { sm: '100%', md: 'auto' } }}>
                  {Insight}
                  <Report.FAQ>
                    <Button
                      appearance={ButtonAppearance.SECONDARY}
                      isTransparent
                      iconRight
                      text={t('reportDetails:faq')}
                      onClick={() => openModal()}
                    />
                  </Report.FAQ>
                </Grid>
              </Grid>
            </InnerContainer>
          </Container>
        </Report.Wrapper>
      </BackgroundWrapper>
    </Layout>
  );
};
