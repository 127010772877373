import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface NoneProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const Wind = ({ props, isActive }: NoneProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_7253_14895)'>
        <path
          d='M15 27.6301H31.5C33.43 27.6301 35 29.2001 35 31.1301C35 33.0601 33.43 34.6301 31.5 34.6301C29.57 34.6301 28 33.0601 28 31.1301V30.6301M15 24.6301H31.5C33.42 24.6301 35 23.0601 35 21.1301C35 19.2101 33.42 17.6301 31.5 17.6301C30.5726 17.6327 29.6838 18.0023 29.028 18.6581C28.3722 19.3139 28.0026 20.2026 28 21.1301V21.6301'
          stroke={isActiveState ? '#ffffff' : main}
          stroke-width='1.33678'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M15 21.63H22.31C22.842 21.63 23.3621 21.4722 23.8045 21.1767C24.2469 20.8811 24.5916 20.461 24.7952 19.9694C24.9988 19.4779 25.0521 18.937 24.9483 18.4152C24.8445 17.8934 24.5883 17.4141 24.2121 17.0379C23.8359 16.6617 23.3566 16.4055 22.8348 16.3017C22.313 16.1979 21.7721 16.2512 21.2806 16.4548C20.789 16.6584 20.3689 17.0031 20.0733 17.4455C19.7778 17.8879 19.62 18.408 19.62 18.94V19.32'
          stroke={isActiveState ? '#ffffff' : main}
          stroke-width='1.33678'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_7253_14895'>
          <rect width='30' height='30' fill='white' transform='translate(10 10)' />
        </clipPath>
      </defs>
    </svg>
  );
};
