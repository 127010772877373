import styled from 'styled-components';

import { Grid, Typography } from '@mui/material';

const Wrapper = styled(Grid)`
  padding-top: 48px;
  padding-left: 24px;
  padding-right: 24px;
  ${(props) => props.theme.breakpoints.up('lg')} {
    background: transparent;
  }
`;

const DeleteDataTitle = styled(Typography)`
  margin-bottom: 24px;
  li a {
    word-break: break-all;
  }
`;

const TextBody = styled(Typography)<{ component: string }>`
word-break: break-word;
p {
  font-size: ${({ theme }) => `${theme.typography.body1.fontSize}`};
      line-height: ${({ theme }) => `${theme.typography.body1.lineHeight}`};
      font-weight: ${({ theme }) => `${theme.typography.body1.fontWeight}`};
      letter-spacing: 0;
      }
      h4 {
      margin-top: 20px;
      font-size: ${({ theme }) => `${theme.typography.h4.fontSize}`};
      line-height: ${({ theme }) => `${theme.typography.h4.lineHeight}`};
      font-weight: ${({ theme }) => `${theme.typography.h4.fontWeight}`};
      }
  h2 {
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
  }



  strong {
    font-weight: 700;
    font-size: ${({
      theme: {
        typography: { subtitle2 },
      },
    }) => `${subtitle2.fontSize}`};
    line-height ${({
      theme: {
        typography: { subtitle2 },
      },
    }) => `${subtitle2.lineHeight}`};
  }

  ol {
    font-size: 1.6rem;
  }

  p {
    margin-bottom: 16px;
  }

  img {
    max-width: 100%;
  }

  td {
    vertical-align: baseline;
    padding-bottom: 12px;
    &:first-child {
      width: 40%;
      padding-right: 12px;
    }
  }


`;

const StaticPageContainer = styled.div`
  flex-grow: 1;
  display: flex;
  > div {
    background: transparent;
    padding-bottom: 80px;
  }
`;

const ButtonWrapper = styled.div`
  button {
    color: ${({ theme }) => `${theme.palette.common.black}`};
    padding: 0;
    justify-content: flex-start;
    svg * {
      stroke: ${({ theme }) => `${theme.palette.common.black}`};
    }
  }
  &:hover {
    button {
      color: ${({ theme }) => `${theme.palette.common.black}`};
      padding: 0;
      justify-content: flex-start;
      svg * {
        stroke: ${({ theme }) => `${theme.palette.common.black}`};
      }
    }
  }
`;

export const StaticPageDetailsStyles = {
  Wrapper,
  TextBody,
  StaticPageContainer,
  DeleteDataTitle,
  ButtonWrapper,
};
