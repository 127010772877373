import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface BusinessClassProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const BusinessClass = ({ props, isActive }: BusinessClassProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} width='50' height='51' viewBox='0 0 50 51' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_7253_17353)'>
        <g clip-path='url(#clip1_7253_17353)'>
          <path
            d='M19.7134 15.3811C19.7134 16.2928 20.0755 17.167 20.7201 17.8117C21.3647 18.4563 22.239 18.8184 23.1506 18.8184C24.0623 18.8184 24.9365 18.4563 25.5812 17.8117C26.2258 17.167 26.5879 16.2928 26.5879 15.3811C26.5879 14.4695 26.2258 13.5952 25.5812 12.9506C24.9365 12.306 24.0623 11.9439 23.1506 11.9439C22.239 11.9439 21.3647 12.306 20.7201 12.9506C20.0755 13.5952 19.7134 14.4695 19.7134 15.3811Z'
            stroke={isActiveState ? '#ffffff' : main}
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M33.1899 39.4315C32.9333 39.4605 32.6735 39.4385 32.4254 39.367C32.1773 39.2954 31.9457 39.1756 31.744 39.0145C31.5422 38.8533 31.3742 38.654 31.2495 38.4278C31.1249 38.2017 31.0461 37.9532 31.0176 37.6965L30.5213 33.2444C30.451 32.7817 30.2167 32.3596 29.8613 32.055C29.5058 31.7505 29.0527 31.5838 28.5846 31.5854H25.7955C24.8664 31.5878 23.9667 31.2599 23.257 30.6603C22.5472 30.0606 22.0737 29.2283 21.9209 28.3118L21.2125 24.0575C21.1257 23.5437 21.2466 23.0164 21.5485 22.5917C21.8504 22.167 22.3087 21.8796 22.8225 21.7928C23.3363 21.706 23.8635 21.8268 24.2882 22.1287C24.7129 22.4307 25.0003 22.8889 25.0871 23.4027L25.7955 27.6532H28.5846C29.9919 27.6474 31.3543 28.1479 32.4231 29.0633C33.4919 29.9788 34.1958 31.2481 34.4064 32.6395C34.4142 32.6945 34.9301 37.2565 34.9301 37.2565C34.9594 37.5137 34.9376 37.7742 34.8659 38.0229C34.7942 38.2716 34.674 38.5037 34.5123 38.7059C34.3505 38.908 34.1505 39.0762 33.9235 39.2007C33.6966 39.3252 33.4473 39.4037 33.1899 39.4315Z'
            stroke={isActiveState ? '#ffffff' : main}
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M16.2762 39.4432H25.9424C26.1083 39.4433 26.2723 39.4084 26.4238 39.3408C26.5752 39.2731 26.7107 39.1743 26.8213 39.0507C26.9319 38.9271 27.0152 38.7815 27.0657 38.6235C27.1162 38.4655 27.1328 38.2986 27.1144 38.1338L26.9376 36.5363C26.9061 36.2553 26.7723 35.9958 26.5617 35.8072C26.3511 35.6186 26.0785 35.5141 25.7958 35.5136C23.9359 35.5183 22.1351 34.8611 20.7154 33.6597C19.2956 32.4583 18.3496 30.791 18.0465 28.956L17.6131 26.3528C17.5531 25.9924 17.3937 25.6559 17.1529 25.3811C16.9121 25.1062 16.5994 24.9041 16.25 24.7972'
            stroke={isActiveState ? '#ffffff' : main}
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_7253_17353'>
          <rect width='30' height='30' fill='white' transform='translate(10 10.6939)' />
        </clipPath>
        <clipPath id='clip1_7253_17353'>
          <rect width='30' height='30' fill='white' transform='translate(10 10.6939)' />
        </clipPath>
      </defs>
    </svg>
  );
};
