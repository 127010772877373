import { useEffect } from 'react';

import { Layout, FAQ, Teaser } from 'App/components';

import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';

import { BackgroundWrapper, Header } from 'components';

import { FAQPageStyles } from './FAQPage.styles';

export const FAQPage = () => {
  const { t }: any = useTranslation();

  useEffect(() => {
    document.title = 'CO2 FAQ';
  });

  return (
    <Layout hideContainer isFullHeight header={<Header title={t('homePage:appName')} transparent hideLeftButton />}>
      <Teaser hasFixedHeader isAlignCenter title={t('faqs:title')} />
      <FAQPageStyles.FAQsContainer>
        <BackgroundWrapper>
          <FAQPageStyles.Wrapper container justifyContent='center'>
            <Grid item md={6}>
              <FAQ />
            </Grid>
          </FAQPageStyles.Wrapper>
        </BackgroundWrapper>
      </FAQPageStyles.FAQsContainer>
    </Layout>
  );
};
