import { IconButton as MuiIconButton } from '@mui/material';
import styled from 'styled-components';

export const IconWrapper = styled.div`
  display: inherit;
`;

export const Content = styled(MuiIconButton)`
  padding: 8px;
  margin: 0;
`;

export const IconButton = {
  Content,
  IconWrapper,
};
