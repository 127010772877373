import {
  HomePage,
  TransactionDetailsPage,
  TransactionDetailsEditPage,
  FoodSettingsPage,
  ProfileSettings,
  LogoutPage,
  SSOPage,
  ReportDetails,
  LandingPage,
  CardPage,
  FeedbackPage,
  FAQPage,
  RegisterComplete,
  PasswordRecovery,
  PasswordReset,
  AccountConfirmation,
  PageNotFound,
  StaticPageDetails,
  HelpPage,
} from 'impactApp/modules';
import { RoutePaths } from 'impactApp/routes/routePaths';
import { routes } from 'impactApp/translations';

const {
  HOME,
  REPORTS,
  TRANSACTION_DETAILS,
  TRANSACTION_DETAILS_EDIT,
  PROFILE_SETTINGS,
  FOOD_SETTINGS,
  LOGOUT_PAGE,
  SSO_PAGE,
  LANDING_PAGE,
  CARD_PAGE,
  FEEDBACK_PAGE,
  FAQ_PAGE,
  REGISTER_COMPLETE,
  ACCOUNT_CONFIRMATION,
  PASSWORD_RESET,
  PASSWORD_RECOVERY,
  PAGE_NOT_FOUND,
  STATIC_PAGE_DETAILS,
  HELP_PAGE,
} = RoutePaths;

const {
  homePage,
  reports,
  transactions,
  transactionsEdit,
  profileSettings,
  foodSettings,
  logoutPage,
  ssoPage,
  landingPage,
  cardPage,
  feedbackPage,
  faqPage,
  registerComplete,
  accountConfirmation,
  passwordRecovery,
  passwordReset,
  pageNotFound,
  staticPage,
  helpPage,
} = routes;

export const ROUTES = {
  HOMEPAGE: {
    name: homePage,
    id: 'id_home_page',
    url: HOME,
    component: HomePage,
    private: true,
  },
  REPORTS: {
    name: reports,
    id: 'id_reports',
    url: `${REPORTS}/:category`,
    component: ReportDetails,
    private: true,
  },
  TRANSACTIONS: {
    name: transactions,
    id: 'id_transactions',
    url: TRANSACTION_DETAILS,
    component: TransactionDetailsPage,
    private: true,
  },
  TRANSACTIONS_EDIT: {
    name: transactionsEdit,
    id: 'id_transactions_edit',
    url: TRANSACTION_DETAILS_EDIT,
    component: TransactionDetailsEditPage,
    private: true,
  },
  PROFILE_SETTINGS: {
    name: profileSettings,
    id: 'id_profile_settings_page',
    url: PROFILE_SETTINGS,
    component: ProfileSettings,
    private: true,
  },
  FOOD_SETTINGS: {
    name: foodSettings,
    id: 'id_food_settings_page',
    url: FOOD_SETTINGS,
    component: FoodSettingsPage,
    private: true,
  },
  LOGOUT_PAGE: {
    name: logoutPage,
    id: 'id_logout_page',
    url: LOGOUT_PAGE,
    component: LogoutPage,
    private: false,
  },
  SSO_PAGE: {
    name: ssoPage,
    id: 'id_sso_page',
    url: SSO_PAGE,
    component: SSOPage,
    private: false,
  },
  LANDING_PAGE: {
    name: landingPage,
    id: 'id_landing_page',
    url: LANDING_PAGE,
    component: LandingPage,
    private: false,
  },
  CARD_PAGE: {
    name: cardPage,
    id: 'id_card_page',
    url: CARD_PAGE,
    component: CardPage,
    private: true,
  },
  FEEDBACK_PAGE: {
    name: feedbackPage,
    id: 'id_feedback_page',
    url: FEEDBACK_PAGE,
    component: FeedbackPage,
    private: true,
  },
  FAQ_PAGE: {
    name: faqPage,
    id: 'id_faq_page',
    url: FAQ_PAGE,
    component: FAQPage,
    private: true,
  },
  REGISTER_COMPLETE: {
    name: registerComplete,
    id: 'id_register_complete_page',
    url: REGISTER_COMPLETE,
    component: RegisterComplete,
    private: true,
  },
  ACCOUNT_CONFIRMATION: {
    name: accountConfirmation,
    id: 'id_account_confirmation_page',
    url: ACCOUNT_CONFIRMATION,
    component: AccountConfirmation,
    private: true,
  },
  PASSWORD_RESET: {
    name: passwordReset,
    id: 'id_password_reset_page',
    url: PASSWORD_RESET,
    component: PasswordReset,
    private: false,
  },
  PASSWORD_RECOVERY: {
    name: passwordRecovery,
    id: 'id_password_recovery_page',
    url: PASSWORD_RECOVERY,
    component: PasswordRecovery,
    private: false,
  },
  STATIC_PAGE_DETAILS: {
    name: staticPage,
    id: 'id_static_page',
    url: STATIC_PAGE_DETAILS,
    component: StaticPageDetails,
    private: false,
  },
  HELP_PAGE: {
    name: helpPage,
    id: 'id_help_page',
    url: HELP_PAGE,
    component: HelpPage,
    private: false,
  },
  PAGE_NOT_FOUND: {
    name: pageNotFound,
    id: 'id_page_not_found',
    url: PAGE_NOT_FOUND,
    component: PageNotFound,
    private: false,
  },
};
