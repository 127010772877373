import { Fragment } from 'react';

import { ListItem, ListStyles } from 'components';
import { HighlightedColor, Categories } from 'App/types';
import { renderIcon } from 'impactApp/modules/ReportDetails/utils';
import { Transaction } from '@ecolytiq/js-data-provider';

type TransactionListItemProps = {
  category: Categories;
  date: string;
  description: string;
  footprint: string;
  id: string;
  value: string;
  redirectURL: string;
  transaction?: Transaction;
  removeLastDivider?: boolean;
};

export const TransactionListItem = ({
  category,
  date,
  description,
  footprint,
  id,
  value,
  redirectURL,
  transaction,
  removeLastDivider,
}: TransactionListItemProps) => {
  const { WHITE } = HighlightedColor;
  return (
    <Fragment key={id}>
      <ListItem
        redirectURL={redirectURL}
        appearance={WHITE}
        date={date}
        icon={renderIcon(category as Categories)}
        id={id}
        title={description}
        value={value}
        weightValue={footprint}
        transaction={transaction}
        flipValues
      />
      {removeLastDivider && <ListStyles.Divider />}
    </Fragment>
  );
};
