export const poc = {
  title: 'PoC Dashboard',
  subtitle: 'Select a Profile:',
  deleteData: { text: 'Delete data', title: 'Do you really want to delete your data?', url: 'Delete my account' },
  urlText: 'See footprint estimate',
  deleteSuccess: {
    text: 'Your data and your account were successfully deleted. If you want to use the app again please create a new account. You will now be logged out.',
    title: 'Do you really want to delete your data?',
    button: 'Logout',
  },
};
