import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface MeatLessProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const MeatLess = ({ props, isActive }: MeatLessProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg width='51' height='50' viewBox='0 0 51 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M24.4937 22.2626C24.3842 22.2626 24.2954 22.1737 24.2954 22.0642C24.2954 21.9545 24.3842 21.8657 24.4937 21.8657'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
      />
      <path
        d='M28.8758 22.2627C28.7662 22.2627 28.6775 22.1738 28.6775 22.0642C28.6775 21.9546 28.7662 21.8658 28.8758 21.8658'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
      />
      <path
        d='M24.4934 22.2626C24.6029 22.2626 24.6917 22.1737 24.6917 22.0642C24.6917 21.9545 24.6029 21.8657 24.4934 21.8657'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
      />
      <path
        d='M28.876 22.2626C28.9855 22.2626 29.0742 22.1737 29.0742 22.0642C29.0742 21.9545 28.9855 21.8657 28.876 21.8657'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
      />
      <path
        d='M23.259 26.6133C24.6656 25.4578 28.611 25.4265 30.112 26.605'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M25.9158 27.9857V27.587'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M27.5093 27.9857L27.4919 27.587'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M31.7109 20.3292C33.2709 20.6112 35.3158 21.7078 35.6492 23.3749C34.8698 24.4545 32.9212 24.9337 30.9726 23.3749C30.7112 25.5425 29.9786 26.9616 29.0635 28.9295C28.9138 29.2502 28.6799 29.5208 28.3886 29.7102C28.0974 29.8995 27.7605 30 27.4166 30.0001H25.9548C25.6109 30 25.274 29.8995 24.9828 29.7102C24.6915 29.5208 24.4576 29.2502 24.308 28.9295C23.3928 26.9616 22.6602 25.5425 22.3988 23.3749C20.4502 24.9337 18.5016 24.4545 17.7222 23.3749C18.0556 21.7078 20.1005 20.6112 21.6606 20.3292'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M26.7926 17.2689C26.476 16.152 27.1248 14.99 28.2417 14.6735C29.5615 14.2995 30.7748 15.5727 32.125 15.5904C33.4092 15.6072 34.4463 14.966 33.4102 13.3333C34.2651 13.4681 35.0064 14.0842 35.2579 14.9715C36.32 18.7192 27.7187 20.5368 26.7926 17.2689Z'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linejoin='round'
      />
      <path
        d='M26.6328 17.2689C26.9494 16.152 26.3006 14.99 25.1838 14.6735C23.864 14.2995 22.6507 15.5727 21.3005 15.5904C20.0163 15.6072 18.9792 14.966 20.0153 13.3333C19.1604 13.4681 18.419 14.0842 18.1676 14.9715C17.1054 18.7192 25.7067 20.5368 26.6328 17.2689Z'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linejoin='round'
      />
      <line
        x1='36.0301'
        y1='10.5303'
        x2='16.0301'
        y2='30.5303'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
      />
      <path
        d='M22.8779 36.046L21.646 34.1741H22.83L23.5726 35.3924L24.3221 34.1741H25.5061L24.2605 36.046L25.5643 38H24.3768L23.5726 36.6893L22.765 38H21.581L22.8779 36.046ZM28.4936 38H27.4362V35.1049L27.4464 34.6292L27.4635 34.1091C27.2879 34.2847 27.1658 34.4 27.0974 34.4547L26.5225 34.9167L26.0126 34.2802L27.6244 32.9969H28.4936V38Z'
        fill={isActiveState ? '#ffffff' : main}
      />
    </svg>
  );
};
