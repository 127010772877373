import { BrowserRouter } from 'react-router-dom';

import { useEffect } from 'react';

import { httpClient, QueryProvider, httpClientOauth } from '@ecolytiq/js-data-provider';

import { httpClient as strapiHttpClient, QueryProvider as StrapiQueryProvider } from '@ecolytiq/js-cms-provider';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

import { LocalizationProvider } from '@mui/x-date-pickers';

import { Config } from 'App/config';

import { AppsSchema, SingleApp } from './types';

import { theme, ThemeProvider } from './style';

import { workerService } from './test/api';

import { appConfig } from './appConfig/appConfig';

import { DepsProvider, UserProvider, FeedbackProvider, TCProvider } from './context';

import { AutoLogout } from './components';

import '../App/i18n';

type AppProps = {
  readonly apps: AppsSchema;
};

const renderApp = (App: SingleApp) => <App.Component key={App.id} />;

export const App = ({ apps }: AppProps) => {
  const {
    REACT_APP_MOCK_API: isMockEnvEnabled,
    REACT_APP_ECOLYTIQ_API_GRAPHQL_URL,
    REACT_APP_ECOLYTIQ_CMS_GRAPHQL_URL,
    REACT_APP_ECOLYTIQ_CMS_ACCESS_TOKEN,
    REACT_APP_ECOLYTIQ_KEYCLOAK_URL,
  } = Config.getAll();
  const apiHost = isMockEnvEnabled === 'true' ? '' : REACT_APP_ECOLYTIQ_API_GRAPHQL_URL;
  const keycloakHost = REACT_APP_ECOLYTIQ_KEYCLOAK_URL;

  const strapiApiHost = REACT_APP_ECOLYTIQ_CMS_GRAPHQL_URL;
  const httpClientService = httpClient(apiHost);
  const httpClientServiceOAuth = httpClientOauth(keycloakHost);
  const strapiHttpClientService = strapiHttpClient(strapiApiHost);
  strapiHttpClientService.setInterceptor(({ headers, ...restRequestConfig }: any) => {
    return {
      ...restRequestConfig,
      headers: { ...headers, Authorization: `Bearer ${REACT_APP_ECOLYTIQ_CMS_ACCESS_TOKEN}` },
    };
  });

  useEffect(() => {
    document.querySelector('meta[name="description"]')?.setAttribute('content', appConfig.metaDescription);

    const style = document.createElement('style');
    let fontStyles = '';
    const fonts = appConfig.theme.fonts;
    Object.keys(fonts).forEach((fontKey) => {
      const font = fonts[fontKey as keyof typeof fonts];
      fontStyles += `
            @font-face {
              font-family: '${font.fontFamily}';
              font-style: normal;
              font-weight: ${font.fontWeight};
              font-display: ${font.fontDisplay};
              src: url('${font.fontUrl}') format('woff2');
            }
          `;
    });

    style.innerHTML = fontStyles;
    document.head.appendChild(style);
  });

  return (
    <DepsProvider
      strapiHttpClientService={strapiHttpClientService}
      httpClientServiceOAuth={httpClientServiceOAuth}
      httpClientService={httpClientService}
      workerService={workerService}
    >
      <UserProvider>
        <TCProvider>
          <QueryProvider>
            <StrapiQueryProvider>
              <FeedbackProvider>
                <ThemeProvider theme={theme}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <BrowserRouter>
                      {apps.map(renderApp)}
                      <AutoLogout />
                    </BrowserRouter>
                  </LocalizationProvider>
                </ThemeProvider>
              </FeedbackProvider>
            </StrapiQueryProvider>
          </QueryProvider>
        </TCProvider>
      </UserProvider>
    </DepsProvider>
  );
};
