export const cardPage = {
  title: 'Add your card',
  text: 'Add your Visa Card to start calculating your carbon footprint estimate',
  tc: 'Your Visa Card Number is securely entered directly with Visa in the next screen ecolytiq does not have access and cannot initiate transactions. You will now be redirected to a Visa hosted site to enter your card number',
  undercard:
    'By clicking continue, I authorize Visa to enroll my eligible payment card in the App and share transaction details (such as date, time, amount and merchant) from purchases (of the last 12 months and future transactions) with ecolytiq. I acknowledge and agree that this data is used to enable the app to function by ecolytiq using the transaction details to calculate carbon dioxide values for my purchases. I understand that I can withdraw my authorization at any time by deleting my ecolytiq account.',
  ios: {
    buttonText: 'Add Card',
    text: 'Your Visa Card Number is securely entered directly with Visa in the next screen ecolytiq does not have access and cannot initiate transactions. You will now be redirected to a Visa hosted site to enter your card number',
    modalText:
      'By clicking continue in the following screen, I authorize Visa to enroll my eligible payment card in the App and share transaction details (such as date, time, amount and merchant) from purchases (of the last 12 months and future transactions) with ecolytiq. <br> <br> I acknowledge and agree that this data is used to enable the app to function by ecolytiq using the transaction details to calculate carbon dioxide values for my purchases. I understand that I can withdraw my authorization at any time by deleting my ecolytiq account.',
  },
};
