import { CheckboxIcon } from 'components/Icons';
import styled, { css } from 'styled-components';

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  height: 15px;
  width: 15px;
`;

const Icon = styled(CheckboxIcon)``;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div<{ checked?: boolean; disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border: 1px solid ${({ theme, checked }) => (checked ? theme.palette.common.black : theme.palette.grey[400])};
  ${Icon} {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `};
`;

export const CheckboxStyles = {
  CheckboxContainer,
  Icon,
  HiddenCheckbox,
  StyledCheckbox,
};
