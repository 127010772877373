export enum RoutePaths {
  HOME = '/dashboard',
  REPORTS = '/overview',
  REPORT_DETAILS = '/overview/:category',
  TRANSACTIONS = '/transactions',
  TRANSACTION_DETAILS = '/transactions/:id',
  TRANSACTION_DETAILS_EDIT = '/transactions/:id/edit',
  PROFILE_SETTINGS = '/profile',
  FOOD_SETTINGS = '/settings',
  LOGOUT_PAGE = '/logout',
  SSO_PAGE = '/sso',
  LANDING_PAGE = '/',
  CARD_PAGE = '/card',
  FEEDBACK_PAGE = '/feedback',
  FAQ_PAGE = '/faq',
  REGISTER_COMPLETE = '/register-complete',
  ACCOUNT_CONFIRMATION = '/account-confirmation',
  PASSWORD_RESET = '/password-reset',
  PASSWORD_RECOVERY = '/password-recovery',
  STATIC_PAGE = '/page',
  STATIC_PAGE_DETAILS = '/page/:pageId',
  HELP_PAGE = '/help',
  PAGE_NOT_FOUND = '*',
}
