import styled from 'styled-components';

import { Grid } from '@mui/material';

export const ChartWrapperStyles = styled(Grid)`
  font-size: 12px;

  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 16px;
  }

  tspan {
    fill: ${({ theme }) => `${theme.palette.grey[600]}`};
  }

  .recharts-tooltip-cursor {
    display: none;
  }

  .recharts-cartesian-axis-tick,
  .recharts-reference-line {
    font-size: ${({ theme }) => `${theme.typography.label.fontSize}`};
    line-height: ${({ theme }) => `${theme.typography.label.lineHeight}`};
    font-weight: ${({ theme }) => `${theme.typography.label.fontWeight}`};
    letter-spacing: ${({ theme }) => `${theme.typography.label.letterSpacing}`};
  }

  .recharts-cartesian-axis-tick-value {
    font-size: ${({ theme }) => `${theme.typography.numbers.fontSize}`};
    line-height: ${({ theme }) => `${theme.typography.numbers.lineHeight}`};
    font-weight: ${({ theme }) => `${theme.typography.numbers.fontWeight}`};
    letter-spacing: ${({ theme }) => `${theme.typography.numbers.letterSpacing}`};
    font-family: ${({ theme }) => `${theme.typography.fontFamily}`};
    ${(props) => props.theme.breakpoints.up('lg')} {
      font-size: ${({ theme }) => `${theme.typography.subtitle3.fontSize}`};
      line-height: ${({ theme }) => `${theme.typography.subtitle3.lineHeight}`};
      font-weight: ${({ theme }) => `${theme.typography.subtitle3.fontWeight}`};
      letter-spacing: 0;
    }
  }

  .recharts-legend-item-text {
    color: ${({ theme }) => `${theme.palette.primary.light}`} !important;
    font-size: ${({ theme }) => `${theme.typography.caption.fontSize}`};
    line-height: ${({ theme }) => `${theme.typography.caption.lineHeight}`};
    font-weight: ${({ theme }) => `${theme.typography.caption.fontWeight}`};
    font-family: ${({ theme }) => `${theme.typography.fontFamily}`};
  }

  .recharts-line-dots {
    stroke: ${({ theme }) => `${theme.palette.common.white}`};
  }

  .recharts-dot {
    margin-left: 4px;
    fill: ${({ theme }) => `${theme.palette.primary.main}`};
    stroke: ${({ theme }) => `${theme.palette.primary.main}`};
  }

  .recharts-default-legend {
    li {
      display: flex !important;
      align-items: center;
      justify-content: center;
      margin: 0 !important;
      font-size: ${({ theme }) => `${theme.typography.caption.fontSize}`};
      line-height: ${({ theme }) => `${theme.typography.caption.lineHeight}`};
      font-weight: ${({ theme }) => `${theme.typography.caption.fontWeight}`};
    }
  }

  .recharts-surface {
    /*! @noflip */
    direction: ltr;
    line {
      stroke: ${({ theme }) => `${theme.palette.primary.light}`};
    }
  }
`;
