import { Grid } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { Config } from 'App/config';

import { RoutePaths } from 'impactApp/routes';

import { Layout, BASIC_GRADIENT_BACKGROUND_HEIGHT } from 'App/components';

import { Button, ButtonAppearance } from 'components';

import { appConfig } from 'App/appConfig/appConfig';

import { useSessionData } from './useSessionData';

import { LandingPageStyles } from './LandingPage.styles';

export const LandingPage = () => {
  const { t }: any = useTranslation();

  const navigate = useNavigate();

  const { CARD_PAGE } = RoutePaths;

  const { REACT_APP_ECOLYTIQ_KEYCLOAK_URL, REACT_APP_ECOLYTIQ_KEYCLOAK_CLIENT_ID, REACT_APP_MOCK_API } =
    Config.getAll();

  useEffect(() => {
    document.title = 'CO2 Calculator';
  });

  const queryParams = new URLSearchParams(window.location.hash.substring(1));

  const code = queryParams.get('code') || '';

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const infoParam = urlParams.get('info');
    if (infoParam) return navigate(`${CARD_PAGE}?info=${infoParam}`);
  }, []);

  useSessionData(code);

  const descriptionContent = t('landingPage:list', { returnObjects: true }).map((content: string) => (
    <LandingPageStyles.Item key={content.slice(0, 15)} variant='body1'>
      {content}
    </LandingPageStyles.Item>
  ));

  return (
    <Layout bgHeight={BASIC_GRADIENT_BACKGROUND_HEIGHT} bgColor hideContainer isFullHeight>
      <LandingPageStyles.CurveWrapper>
        <Grid
          container
          columns={{ sm: 5, md: 8, lg: 12 }}
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          flexWrap='nowrap'
        >
          <LandingPageStyles.LogoWrapper>
            <img src={appConfig.logo} />
          </LandingPageStyles.LogoWrapper>

          <LandingPageStyles.ImgWrapper>
            <img src={appConfig.loginImage} />
          </LandingPageStyles.ImgWrapper>
          <LandingPageStyles.Wrapper>
            <Grid
              display='flex'
              flexDirection='column'
              alignItems='center'
              item
              md={4}
              lg={3}
              minWidth={{ md: '480px' }}
              margin='0 auto'
            >
              <LandingPageStyles.Title variant='h6'>{t('landingPage:title')}</LandingPageStyles.Title>
              <LandingPageStyles.ListWrapper>{descriptionContent}</LandingPageStyles.ListWrapper>
              <LandingPageStyles.ButtonWrapper>
                <Button
                  appearance={ButtonAppearance.PRIMARY}
                  onClick={() =>
                    REACT_APP_MOCK_API === 'true'
                      ? navigate(CARD_PAGE)
                      : window.open(
                          `${REACT_APP_ECOLYTIQ_KEYCLOAK_URL}/auth?client_id=${REACT_APP_ECOLYTIQ_KEYCLOAK_CLIENT_ID}&redirect_uri=${window.location.origin}&response_type=code&scope=openid&response_mode=fragment`,
                          '_self',
                        )
                  }
                  text={t('landingPage:button')}
                />
              </LandingPageStyles.ButtonWrapper>
            </Grid>
          </LandingPageStyles.Wrapper>
        </Grid>
      </LandingPageStyles.CurveWrapper>
    </Layout>
  );
};
