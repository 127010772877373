import { SVGProps } from 'react';

export const CategoryLiving = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg {...props} width='240' height='240' viewBox='0 0 240 240' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clip-path='url(#clip0_6799_37185)'>
      <g style={{ mixBlendMode: 'multiply' }} opacity='0.3'>
        <ellipse cx='134.5' cy='226.559' rx='87.5' ry='10' fill='#C4C4C4' />
      </g>
      <path
        d='M209.086 15.381C208.423 13.9341 206.765 13.0113 205.182 13.218C203.421 11.6678 200.549 11.5792 198.692 13.0187C197.352 12.1255 195.532 12 194.081 12.7013C192.63 13.4026 191.592 14.8938 191.444 16.5031C189.846 16.1635 188.078 16.9534 187.253 18.3634C186.428 19.7733 186.62 21.7074 187.695 22.9329C185.912 23.4127 184.668 25.3616 184.962 27.185C185.264 29.0083 187.069 30.4478 188.91 30.3371L208.15 19.7438C209.344 18.6882 209.734 16.8205 209.071 15.3736L209.086 15.381Z'
        fill='#E0E0E0'
      />
      <path
        d='M188.351 30.8464C189.433 22.5785 196.667 16.5251 204.976 16.9385L205.801 16.9828C214.684 17.9499 218.824 26.048 217.616 34.9139L214.853 64.5752L214.706 66.0147L193.949 69.1226L194.354 61.5485C194.354 61.5485 185.861 59.7916 186.178 51.2062C186.332 47.0575 187.341 38.5459 188.351 30.8538V30.8464Z'
        fill='#EDBE9E'
      />
      <path
        opacity='0.3'
        d='M194.288 61.5263C194.288 61.5263 199.739 62.1316 205.668 58.7949C205.668 58.7949 202.722 64.8409 194.222 63.6376L194.288 61.5263Z'
        fill='black'
      />
      <path
        d='M190.207 39.1881C190.155 39.8304 190.649 40.4135 191.326 40.48C191.997 40.5464 192.586 40.0814 192.637 39.4391C192.689 38.7969 192.195 38.2137 191.518 38.1399C190.847 38.0734 190.258 38.5385 190.199 39.1807L190.207 39.1881Z'
        fill='black'
      />
      <path
        d='M189.978 35.1353C190.118 35.3051 191.083 34.6629 192.394 34.7293C193.705 34.781 194.641 35.5044 194.788 35.3494C194.862 35.283 194.722 34.9877 194.317 34.6555C193.919 34.3307 193.227 33.9985 192.409 33.9616C191.591 33.9247 190.884 34.1904 190.472 34.4857C190.052 34.7736 189.897 35.0615 189.971 35.1353H189.978Z'
        fill='black'
      />
      <path
        d='M201.815 39.978C201.764 40.6202 202.257 41.2034 202.935 41.2698C203.605 41.3363 204.194 40.8712 204.253 40.229C204.305 39.5867 203.804 39.0035 203.134 38.9371C202.463 38.8707 201.874 39.3357 201.815 39.978Z'
        fill='black'
      />
      <path
        d='M202.898 36.0286C203.045 36.1984 204.003 35.5562 205.314 35.6226C206.625 35.6743 207.561 36.3977 207.708 36.2427C207.782 36.1763 207.642 35.881 207.237 35.5488C206.839 35.224 206.146 34.8918 205.329 34.8549C204.511 34.818 203.804 35.0837 203.392 35.379C202.972 35.6669 202.817 35.9548 202.891 36.0286H202.898Z'
        fill='black'
      />
      <path
        d='M197.639 45.5662C197.646 45.4924 196.836 45.3078 195.525 45.0716C195.193 45.0199 194.877 44.9387 194.832 44.7025C194.766 44.4589 194.921 44.0972 195.105 43.7133C195.459 42.916 195.834 42.0745 196.225 41.196C197.786 37.6157 198.935 34.6629 198.788 34.6038C198.641 34.5448 197.263 37.3942 195.702 40.9746C195.326 41.8604 194.965 42.702 194.619 43.5066C194.472 43.8831 194.236 44.3039 194.376 44.8206C194.457 45.079 194.693 45.2709 194.906 45.3374C195.12 45.4112 195.304 45.426 195.473 45.4481C196.807 45.5884 197.632 45.6327 197.639 45.5588V45.5662Z'
        fill='black'
      />
      <path
        d='M201.47 46.6736C201.256 46.6514 201.182 48.0836 199.908 49.0359C198.634 49.9955 197.124 49.7593 197.102 49.9586C197.079 50.0472 197.426 50.2539 198.067 50.3056C198.693 50.3573 199.628 50.2022 200.402 49.619C201.175 49.0359 201.543 48.2165 201.617 47.6333C201.698 47.0353 201.558 46.6736 201.462 46.681L201.47 46.6736Z'
        fill='black'
      />
      <path
        d='M202.818 30.8464C202.928 31.2081 204.254 31.1048 205.786 31.3706C207.326 31.5994 208.548 32.1161 208.769 31.8061C208.865 31.6585 208.666 31.3115 208.18 30.935C207.701 30.5659 206.921 30.1968 206 30.0418C205.079 29.8941 204.225 29.9975 203.65 30.2042C203.076 30.4035 202.774 30.6693 202.818 30.8464Z'
        fill='#E0E0E0'
      />
      <path
        d='M190.656 31.3483C190.87 31.6583 191.791 31.3926 192.888 31.4295C193.978 31.4295 194.892 31.7322 195.12 31.4295C195.223 31.2819 195.09 30.9718 194.7 30.6692C194.317 30.3665 193.661 30.1007 192.91 30.086C192.159 30.0712 191.496 30.3148 191.105 30.6027C190.708 30.8906 190.56 31.1933 190.656 31.3483Z'
        fill='#E0E0E0'
      />
      <path
        d='M189.367 26.956C189.367 26.956 191.893 21.6926 194.538 20.5705C197.742 19.2048 198.92 22.7187 201.941 22.0322C204.961 21.3456 208.025 20.039 210.956 22.4972C214.168 25.1917 212.518 26.8896 212.533 30.7061C212.606 46.179 215.199 50.6821 216.356 43.455C216.481 42.6651 218.72 42.141 219.214 38.7895C219.855 34.4488 220.282 29.1559 219.464 26.0111C218.772 23.3609 217.38 21.4859 214.816 19.3598C210.772 16.0158 204.997 15.654 201.057 15.8607C197.116 16.06 189.33 20.2088 189.359 26.9486L189.367 26.956Z'
        fill='#E0E0E0'
      />
      <path
        d='M215.833 43.1966C215.951 41.8752 217.144 40.886 218.455 41.0779C220.046 41.3068 221.777 42.3034 221.505 45.6105C221.026 51.4645 215.258 49.7814 215.266 49.6116C215.266 49.4935 215.612 45.6548 215.833 43.204V43.1966Z'
        fill='#EDBE9E'
      />
      <path
        opacity='0.3'
        d='M216.709 47.5077C216.709 47.5077 216.805 47.5889 216.975 47.6775C217.137 47.7661 217.416 47.8473 217.733 47.7587C218.367 47.5815 218.971 46.644 219.081 45.5884C219.14 45.0569 219.066 44.5401 218.912 44.1046C218.772 43.6616 218.514 43.3368 218.212 43.2556C217.91 43.1597 217.674 43.3295 217.586 43.4845C217.498 43.6395 217.52 43.765 217.49 43.7724C217.475 43.7871 217.372 43.6616 217.439 43.4254C217.475 43.3147 217.549 43.1818 217.696 43.0785C217.844 42.9751 218.057 42.9308 218.271 42.9751C218.72 43.0415 219.089 43.4919 219.258 43.9717C219.449 44.4515 219.545 45.0347 219.479 45.6327C219.346 46.8064 218.654 47.8621 217.785 48.0392C217.365 48.113 217.033 47.958 216.864 47.8104C216.695 47.648 216.673 47.5151 216.695 47.5077H216.709Z'
        fill='black'
      />
      <path
        d='M216.282 31.8282C215.494 32.3081 214.669 32.8101 213.749 32.8544C212.828 32.8986 211.804 32.3228 211.701 31.4001C211.649 30.9867 211.774 30.5216 211.524 30.1894C211.222 29.7982 210.625 29.8867 210.132 29.9163C208.423 30.0122 206.736 28.7204 206.39 27.0373C205.926 27.6647 204.909 27.5909 204.276 27.1332C203.642 26.6755 203.252 25.9595 202.766 25.3468C202.28 24.734 201.587 24.1656 200.807 24.2394C200.372 24.2764 199.952 24.52 199.518 24.4535C198.751 24.3502 198.42 23.4274 198.346 22.6523C198.265 21.8846 198.221 20.9839 197.595 20.541C197.013 20.1276 196.218 20.3122 195.503 20.3786C194.789 20.4451 193.883 20.2384 193.735 19.5371C193.662 19.168 193.831 18.7915 194.052 18.4888C195.032 17.0936 197.05 16.5547 198.597 17.2708C199.267 15.3588 201.145 13.9414 203.164 13.8307C205.182 13.72 207.208 14.9159 208.084 16.7392C209.381 15.6762 211.2 15.2924 212.806 15.75C214.419 16.2004 215.774 17.4775 216.319 19.0646C217.763 18.7619 219.354 19.3894 220.201 20.6001C221.048 21.8107 221.085 23.5308 220.304 24.7857C221.328 25.4427 222.175 26.5353 222.138 27.7459C222.102 28.964 220.857 30.1082 219.708 29.7317C220.135 30.6988 219.686 31.9611 218.743 32.4483C217.8 32.9282 216.518 32.5591 215.98 31.6437'
        fill='#E0E0E0'
      />
      <path
        d='M220.135 29.7392C220.135 29.7392 220.216 30.2411 219.715 30.8465C219.464 31.1344 219.052 31.4297 218.507 31.4887C217.976 31.5478 217.336 31.3927 216.864 30.9203L217.115 30.8981C216.798 31.3854 216.393 31.8873 215.877 32.3007C215.546 32.5665 215.177 32.788 214.78 32.9135C214.389 33.0463 213.947 33.039 213.594 32.9651C212.857 32.847 212.231 32.4115 211.951 31.9095C211.664 31.4149 211.583 30.9646 211.539 30.6619C211.494 30.3593 211.494 30.1895 211.516 30.1821C211.539 30.1821 211.583 30.3445 211.664 30.6324C211.752 30.9203 211.863 31.3485 212.15 31.7766C212.666 32.6477 214.448 33.1644 215.664 32.035C216.142 31.6437 216.54 31.1787 216.842 30.721L216.953 30.5512L217.093 30.6914C217.476 31.0901 218.021 31.2451 218.485 31.2156C218.949 31.186 219.324 30.9498 219.575 30.7062C220.068 30.1968 220.09 29.717 220.142 29.7318L220.135 29.7392Z'
        fill='white'
      />
      <path
        d='M209.742 15.7944C209.742 15.7944 209.587 15.8313 209.322 15.9273C209.064 16.0233 208.674 16.23 208.372 16.6581L208.298 16.7689L208.224 16.6581C207.716 15.8978 206.847 14.9012 205.506 14.222C204.843 13.8898 204.062 13.6389 203.23 13.6019C202.398 13.5576 201.514 13.757 200.718 14.1851C200.1 14.5247 199.569 14.9824 199.171 15.5139C198.781 16.0454 198.531 16.6507 198.442 17.2487L198.413 17.4332L198.236 17.3742C197.367 17.0863 196.461 17.2118 195.791 17.5514C195.105 17.8836 194.656 18.4224 194.391 18.917C193.868 19.9284 194.001 20.6445 193.942 20.6371C193.927 20.6371 193.89 20.4747 193.897 20.1572C193.905 19.8398 193.971 19.3674 194.229 18.8358C194.479 18.3117 194.943 17.7064 195.68 17.3299C196.402 16.946 197.374 16.791 198.339 17.0937L198.14 17.2192C198.229 16.5622 198.494 15.9052 198.914 15.3294C199.341 14.7535 199.901 14.2589 200.564 13.8972C201.411 13.4322 202.361 13.2255 203.252 13.2845C204.144 13.3362 204.961 13.6093 205.646 13.971C207.031 14.7093 207.893 15.7649 208.379 16.5695H208.232C208.585 16.1119 209.012 15.9273 209.3 15.8535C209.587 15.7797 209.749 15.8018 209.749 15.8166L209.742 15.7944Z'
        fill='white'
      />
      <path
        opacity='0.3'
        d='M203.436 47.626C203.34 47.626 203.332 46.9985 202.861 46.467C202.404 45.9133 201.8 45.81 201.808 45.714C201.808 45.6697 201.977 45.6328 202.242 45.6919C202.507 45.7509 202.854 45.9281 203.119 46.2455C203.391 46.563 203.509 46.9321 203.524 47.1978C203.539 47.471 203.472 47.626 203.436 47.6186V47.626Z'
        fill='black'
      />
      <path
        opacity='0.3'
        d='M204.04 46.0314C203.959 46.0757 203.753 45.8616 203.451 45.7066C203.149 45.5442 202.861 45.4999 202.847 45.4039C202.825 45.3153 203.2 45.1972 203.605 45.4039C204.01 45.6106 204.121 45.9945 204.04 46.024V46.0314Z'
        fill='black'
      />
      <path
        opacity='0.3'
        d='M210.345 60.1017C210.345 60.1017 210.434 60.0648 210.507 59.9541C210.581 59.8507 210.647 59.6514 210.544 59.4373C210.463 59.2085 210.205 59.0018 210.043 58.6105C209.962 58.426 209.948 58.1381 210.087 57.924C210.213 57.7173 210.382 57.5844 210.485 57.4442C210.964 56.8831 209.653 56.418 209.859 55.6798C209.903 55.3698 210.073 55.1631 210.205 55.0671C210.345 54.9712 210.441 54.9638 210.448 54.9785C210.485 55.0155 210.124 55.1926 210.102 55.6946C210.087 55.9456 210.227 56.1597 210.5 56.4033C210.625 56.5288 210.787 56.6838 210.876 56.9053C210.986 57.1267 210.927 57.4589 210.758 57.6361C210.61 57.828 210.456 57.9461 210.375 58.079C210.294 58.2045 210.294 58.3374 210.345 58.485C210.441 58.7803 210.691 59.0313 210.78 59.334C210.883 59.644 210.75 59.9245 210.61 60.0279C210.463 60.1386 210.345 60.0943 210.353 60.0796L210.345 60.1017Z'
        fill='black'
      />
      <path
        opacity='0.3'
        d='M212.172 59.9098C212.172 59.9098 212.15 59.8212 212.105 59.6957C212.061 59.5628 211.958 59.4004 211.803 59.1863C211.73 59.0756 211.641 58.9575 211.568 58.8025C211.494 58.6474 211.45 58.4334 211.509 58.2267C211.627 57.8059 211.98 57.5623 212.157 57.2596C212.356 56.9643 212.201 56.7059 211.965 56.4402C211.855 56.3073 211.737 56.1671 211.671 55.9973C211.605 55.8275 211.59 55.6651 211.605 55.5174C211.686 54.9047 212.098 54.7497 212.098 54.7866C212.142 54.8235 211.84 55.0376 211.84 55.5248C211.84 55.7684 211.951 55.9899 212.201 56.2335C212.312 56.359 212.459 56.5066 212.54 56.7281C212.628 56.9569 212.569 57.2375 212.444 57.4294C212.201 57.8133 211.884 58.0347 211.803 58.3005C211.715 58.5515 211.877 58.8246 212.002 59.0535C212.135 59.2823 212.223 59.4964 212.231 59.6662C212.231 59.8286 212.186 59.9098 212.172 59.9024V59.9098Z'
        fill='black'
      />
      <path
        d='M90.3834 201.661L78.9662 228L46.5928 213.037C48.2059 210.025 67.8141 211.545 67.8141 211.545L74.9075 194.559L90.3761 201.661H90.3834Z'
        fill='#7B4EFA'
      />
      <path
        d='M78.4722 214.764C79.0173 215.222 79.1278 216.13 78.6932 216.698C78.2586 217.266 77.3379 217.385 76.7854 216.934C76.233 216.484 76.0636 215.399 76.5645 214.882C77.0653 214.365 78.1481 214.291 78.5827 214.86'
        fill='#E0E0E0'
      />
      <path
        d='M78.9659 228L80.2549 225.276L48.5813 212.084C48.5813 212.084 46.8503 212.055 46.5557 213.155L78.9733 228.007L78.9659 228Z'
        fill='#E0E0E0'
      />
      <path
        d='M67.3716 211.139C67.3053 211.302 68.064 211.737 68.6017 212.608C69.1689 213.465 69.2499 214.336 69.4267 214.336C69.5888 214.373 69.7877 213.317 69.11 212.276C68.4397 211.228 67.4011 210.977 67.3643 211.139H67.3716Z'
        fill='#E0E0E0'
      />
      <path
        d='M63.328 210.977C63.2249 211.117 63.7847 211.7 64.013 212.616C64.2708 213.524 64.0719 214.314 64.234 214.38C64.3739 214.476 64.9043 213.598 64.6023 212.461C64.3076 211.324 63.409 210.829 63.3353 210.977H63.328Z'
        fill='#E0E0E0'
      />
      <path
        d='M59.5276 214.159C59.6528 214.262 60.2421 213.568 60.2347 212.512C60.2347 211.457 59.6454 210.77 59.5202 210.874C59.3729 210.962 59.6381 211.649 59.6307 212.52C59.6454 213.383 59.3803 214.077 59.5276 214.166V214.159Z'
        fill='#E0E0E0'
      />
      <path
        d='M69.4121 207.448C69.4121 207.625 70.2371 207.744 71.1063 208.216C71.9828 208.674 72.5353 209.294 72.6826 209.198C72.8299 209.124 72.4174 208.223 71.3936 207.684C70.3697 207.131 69.3974 207.293 69.4195 207.456L69.4121 207.448Z'
        fill='#E0E0E0'
      />
      <path
        d='M71.954 203.957C71.9245 204.023 72.499 204.407 73.5818 204.695C74.1196 204.835 74.7751 204.946 75.5191 204.953C75.8874 204.953 76.2778 204.938 76.6755 204.887C76.8671 204.857 77.088 204.842 77.3385 204.732C77.5889 204.65 77.8836 204.274 77.7952 203.92C77.7436 203.595 77.5005 203.351 77.2722 203.24C77.0365 203.122 76.8229 203.078 76.624 203.041C76.2262 202.96 75.8285 202.923 75.4602 202.923C74.7088 202.923 74.0459 203.041 73.5155 203.218C72.4401 203.565 71.9171 204.038 71.954 204.097C71.9982 204.185 72.5948 203.853 73.626 203.632C74.1417 203.521 74.7604 203.447 75.4454 203.484C75.7916 203.499 76.1452 203.55 76.5061 203.624C76.8892 203.691 77.1838 203.824 77.1985 204.016C77.1985 204.089 77.1985 204.112 77.0807 204.178C76.9628 204.237 76.786 204.267 76.5945 204.289C76.2189 204.34 75.8579 204.37 75.5117 204.385C74.8193 204.407 74.1932 204.348 73.6702 204.259C72.6243 204.089 71.9908 203.853 71.954 203.942V203.957Z'
        fill='#E0E0E0'
      />
      <path
        d='M72.0567 204.274C72.1009 204.326 72.6755 203.905 73.2058 202.901C73.4636 202.406 73.6993 201.764 73.8024 201.018C73.854 200.649 73.8687 200.251 73.8466 199.845C73.8024 199.439 73.773 198.9 73.1911 198.545C72.8743 198.383 72.4913 198.516 72.3072 198.686C72.1009 198.856 71.9904 199.033 71.8652 199.202C71.6295 199.557 71.4674 199.926 71.3643 200.295C71.1507 201.033 71.1654 201.735 71.2833 202.296C71.5264 203.425 72.1009 203.92 72.1377 203.875C72.2261 203.824 71.8136 203.263 71.7031 202.229C71.6442 201.72 71.681 201.1 71.8947 200.465C71.9978 200.147 72.1525 199.83 72.3587 199.542C72.565 199.217 72.8228 199.003 72.9406 199.099C73.1027 199.173 73.2574 199.557 73.2647 199.896C73.2942 200.265 73.2942 200.62 73.2647 200.959C73.1984 201.639 73.029 202.244 72.8301 202.724C72.4397 203.698 71.9978 204.215 72.0714 204.274H72.0567Z'
        fill='#E0E0E0'
      />
      <path
        d='M172.057 135.466C170.687 136.115 102.876 134.122 101.167 136.499C98.7072 139.91 67.748 205.898 67.748 205.898L86.1998 215.583L115.863 162.986L190.141 162.824C190.141 162.824 198.545 162.381 199.569 151.588L172.065 135.466H172.057Z'
        fill='black'
      />
      <path
        d='M83.585 178.931L76.5578 200.886L44.0371 188.506C45.4072 185.464 64.6029 185.619 64.6029 185.619L68.61 173.786L83.585 178.924V178.931Z'
        fill='#7B4EFA'
      />
      <path
        d='M75.1952 188.033C75.7623 188.447 75.9244 189.318 75.534 189.901C75.1436 190.484 74.2597 190.661 73.6925 190.255C73.1253 189.857 72.8896 188.808 73.3389 188.277C73.7883 187.745 74.8416 187.59 75.2983 188.122'
        fill='#E0E0E0'
      />
      <path
        d='M76.5576 200.886L77.633 198.147L45.9004 187.435C45.9004 187.435 44.2136 187.524 44 188.609L76.5576 200.886Z'
        fill='#E0E0E0'
      />
      <path
        d='M64.1382 185.25C64.0867 185.413 64.8527 185.789 65.4346 186.594C66.046 187.391 66.186 188.233 66.3554 188.225C66.5101 188.247 66.6353 187.207 65.9061 186.239C65.1842 185.265 64.153 185.088 64.1309 185.25H64.1382Z'
        fill='#E0E0E0'
      />
      <path
        d='M60.1901 185.368C60.0943 185.509 60.6836 186.04 60.9635 186.919C61.2729 187.79 61.1329 188.565 61.295 188.624C61.4349 188.705 61.899 187.812 61.5233 186.727C61.1624 185.642 60.249 185.213 60.1901 185.368Z'
        fill='#E0E0E0'
      />
      <path
        d='M56.6986 188.72C56.8312 188.809 57.3542 188.1 57.2732 187.074C57.1995 186.048 56.5881 185.42 56.4703 185.523C56.3303 185.619 56.6323 186.269 56.6839 187.118C56.7575 187.96 56.5439 188.646 56.6986 188.727V188.72Z'
        fill='#E0E0E0'
      />
      <path
        d='M65.8772 181.522C65.8919 181.692 66.7022 181.751 67.5714 182.15C68.4553 182.534 69.0372 183.102 69.1698 182.999C69.3024 182.918 68.8457 182.069 67.8071 181.611C66.7758 181.146 65.8404 181.367 65.8698 181.522H65.8772Z'
        fill='#E0E0E0'
      />
      <path
        d='M68.1163 177.949C68.0868 178.016 68.6761 178.355 69.7515 178.562C70.2819 178.665 70.9301 178.725 71.652 178.688C72.0129 178.665 72.3885 178.621 72.7789 178.547C72.9631 178.51 73.1767 178.481 73.4198 178.348C73.6555 178.252 73.9207 177.868 73.8102 177.529C73.7365 177.211 73.4861 176.997 73.2504 176.901C73.0147 176.805 72.801 176.776 72.6095 176.754C72.2118 176.702 71.8287 176.694 71.4678 176.717C70.7386 176.761 70.0977 176.931 69.5895 177.13C68.573 177.543 68.0868 178.038 68.131 178.09C68.1826 178.171 68.7424 177.809 69.7294 177.529C70.223 177.388 70.8196 177.278 71.4899 177.263C71.8214 177.263 72.1749 177.278 72.5359 177.329C72.9115 177.366 73.2062 177.477 73.2356 177.669C73.243 177.743 73.2356 177.765 73.1325 177.839C73.022 177.905 72.8452 177.942 72.6685 177.979C72.3075 178.06 71.9613 178.112 71.6225 178.141C70.9448 178.208 70.3334 178.193 69.8178 178.141C68.7866 178.045 68.1605 177.861 68.131 177.942L68.1163 177.949Z'
        fill='#E0E0E0'
      />
      <path
        d='M68.2413 178.259C68.2855 178.304 68.8158 177.853 69.2652 176.849C69.4861 176.348 69.6703 175.705 69.7219 174.974C69.744 174.613 69.7366 174.221 69.685 173.823C69.6187 173.432 69.5451 172.907 68.9558 172.605C68.6391 172.472 68.2781 172.62 68.1013 172.804C67.9098 172.981 67.8141 173.166 67.711 173.336C67.5047 173.69 67.3721 174.066 67.2911 174.436C67.129 175.166 67.1953 175.853 67.35 176.384C67.6668 177.47 68.256 177.913 68.2929 177.868C68.3739 177.809 67.9393 177.292 67.7625 176.296C67.6741 175.801 67.6594 175.196 67.8288 174.568C67.9098 174.251 68.0351 173.934 68.2192 173.638C68.4034 173.313 68.6391 173.085 68.7496 173.166C68.9116 173.225 69.0884 173.594 69.1178 173.919C69.1768 174.273 69.1915 174.627 69.1841 174.96C69.162 175.624 69.0368 176.222 68.8821 176.709C68.5654 177.684 68.1676 178.215 68.2487 178.274L68.2413 178.259Z'
        fill='#E0E0E0'
      />
      <path
        d='M84.7927 188.225L63.8145 180.651L83.2385 120.044C85.8976 113.504 92.8879 109.85 99.7677 111.407L162.813 125.662L163.94 135.436L102.891 136.492L84.8001 188.225H84.7927Z'
        fill='black'
      />
      <path
        d='M63.8072 180.659C63.8072 180.659 63.9398 180.688 64.1828 180.769C64.448 180.858 64.8089 180.983 65.2804 181.146C66.26 181.485 67.6669 181.987 69.4716 182.622C73.1251 183.922 78.3697 185.797 84.8296 188.1L84.6455 188.188C88.5347 177.411 93.713 163.038 99.3921 147.299C100.136 145.254 100.865 143.224 101.558 141.32V141.305L101.565 141.297C102.059 140.352 102.699 139.496 103.458 138.765C104.224 138.049 105.101 137.466 106.044 137.045C106.986 136.632 107.996 136.374 109.005 136.285C110.021 136.218 110.994 136.248 111.973 136.226C115.877 136.182 119.626 136.13 123.192 136.086C130.314 136.004 136.686 135.938 142.026 135.879C147.345 135.835 151.632 135.798 154.607 135.768C156.073 135.768 157.215 135.761 158.01 135.753C158.386 135.753 158.681 135.753 158.894 135.753C159.093 135.753 159.196 135.761 159.196 135.768C159.196 135.776 159.093 135.783 158.894 135.79C158.681 135.79 158.386 135.805 158.01 135.82C157.215 135.842 156.073 135.864 154.615 135.901C151.639 135.953 147.352 136.034 142.034 136.13C136.693 136.211 130.322 136.314 123.199 136.425C119.634 136.477 115.885 136.529 111.981 136.588C111.008 136.61 110.006 136.588 109.042 136.654C108.069 136.743 107.112 136.994 106.198 137.392C104.386 138.197 102.832 139.651 101.904 141.475V141.452C101.226 143.357 100.49 145.38 99.7531 147.432C94.0371 163.163 88.822 177.521 84.918 188.292L84.8665 188.425L84.7339 188.373C78.2887 186.018 73.0588 184.106 69.4127 182.777C67.6154 182.113 66.2158 181.589 65.2435 181.234C64.7795 181.057 64.4185 180.924 64.1607 180.821C63.9177 180.725 63.7998 180.666 63.7998 180.666L63.8072 180.659Z'
        fill='#455A64'
      />
      <g opacity='0.3'>
        <path
          d='M84.7926 188.225C83.4815 187.768 82.3545 187.332 81.0434 186.874C80.7414 187.509 80.6751 188.262 80.3657 188.897L79.9385 190.27C80.9771 190.728 82.3177 191.156 83.4005 191.518C84.4833 191.879 85.927 191.791 86.8625 191.141C87.8569 190.462 88.3136 189.082 88.6966 187.937C90.1993 183.412 91.6946 178.887 93.1972 174.362C95.7679 166.618 98.044 157.752 98.8837 149.639C93.8749 162.063 89.0944 175.543 84.7853 188.225H84.7926Z'
          fill='black'
        />
      </g>
      <path
        d='M215.031 62.7297L194.089 66.5241C193.522 67.1738 184.388 81.1333 182.458 85.3854C180.573 89.5415 173.951 112.655 173.951 112.655L159.204 126.718L166.349 137.532C168.677 141.061 171.918 143.903 175.726 145.749C182.481 149.034 191.158 152.946 194.141 152.946C196.859 152.946 199.68 151.809 199.71 151.802L199.754 151.787L199.776 151.743C200.012 151.285 221.513 105.302 222.846 91.2763C224.43 74.6075 215.141 62.8995 215.023 62.7297H215.031Z'
        fill='#06AEC4'
      />
      <path
        d='M190.473 105.738C190.473 105.782 189.7 105.871 188.447 105.871C187.195 105.871 185.457 105.767 183.564 105.465C181.671 105.155 179.991 104.697 178.805 104.298C177.619 103.9 176.912 103.575 176.927 103.531C176.949 103.479 177.686 103.708 178.886 104.04C180.087 104.372 181.759 104.786 183.63 105.088C185.501 105.391 187.217 105.539 188.455 105.605C189.7 105.671 190.473 105.686 190.473 105.745V105.738Z'
        fill='white'
      />
      <path
        d='M197.978 77.5529C197.963 77.6489 195.953 77.2134 193.824 75.892C191.688 74.5853 190.391 72.9834 190.465 72.9317C190.546 72.8505 191.938 74.2974 194.023 75.5671C196.1 76.8516 198.015 77.4422 197.978 77.5529Z'
        fill='white'
      />
      <path
        d='M200.866 146.686C200.866 146.686 200.505 146.672 199.864 146.568C199.223 146.472 198.303 146.28 197.183 145.985C196.056 145.697 194.745 145.276 193.309 144.73C191.88 144.169 190.347 143.468 188.793 142.619C187.246 141.755 185.832 140.825 184.602 139.917C183.379 138.994 182.319 138.101 181.479 137.304C180.632 136.506 179.984 135.835 179.564 135.34C179.136 134.853 178.908 134.572 178.93 134.558C178.952 134.543 179.21 134.786 179.674 135.237C180.123 135.694 180.809 136.337 181.663 137.104C182.517 137.872 183.585 138.736 184.816 139.636C186.053 140.522 187.453 141.438 188.985 142.287C190.524 143.128 192.042 143.829 193.448 144.405C194.863 144.966 196.159 145.409 197.264 145.727C198.369 146.051 199.275 146.28 199.901 146.421C200.527 146.568 200.873 146.657 200.873 146.686H200.866Z'
        fill='white'
      />
      <path
        d='M215.303 116.9C215.303 116.995 213.977 116.892 212.629 116.043C211.266 115.209 210.581 114.072 210.67 114.028C210.758 113.954 211.546 114.928 212.835 115.718C214.11 116.523 215.332 116.789 215.31 116.9H215.303Z'
        fill='white'
      />
      <g opacity='0.3'>
        <path
          d='M198.236 132.675C195.076 133.797 191.681 133.989 188.337 134.181C180.558 134.616 173.943 134.779 166.165 135.214C175.1 137.961 185.147 137.798 194.237 135.591C196.785 134.971 199.356 134.196 201.514 132.697C203.673 131.199 206.199 128.659 206.376 126.031C205.146 129.154 201.396 131.553 198.236 132.675Z'
          fill='black'
        />
      </g>
      <path
        d='M117.203 77.627C117.512 85.1936 120.496 117.254 120.496 117.254L152.147 135.458L154.482 133.162L123.405 114.825L120.356 77.4351C120.289 76.6009 119.575 75.9586 118.743 75.9808C117.866 76.0029 117.174 76.7411 117.21 77.627H117.203Z'
        fill='#E0E0E0'
      />
      <path
        d='M117.203 77.6268C117.203 77.6268 117.188 77.5603 117.195 77.4274C117.195 77.2946 117.232 77.0952 117.335 76.8516C117.446 76.6154 117.652 76.3423 117.991 76.143C118.322 75.951 118.809 75.8477 119.302 76.0175C119.788 76.1725 120.274 76.6228 120.392 77.265C120.466 77.8851 120.51 78.5495 120.576 79.273C120.702 80.7198 120.842 82.3734 121.004 84.2263C121.63 91.6527 122.514 102.216 123.567 114.818L123.486 114.685C132.664 120.096 143.293 126.356 154.57 133.007L154.784 133.133L154.607 133.31C154.489 133.428 154.357 133.553 154.231 133.679C153.576 134.329 152.92 134.971 152.272 135.606L152.169 135.709L152.044 135.635C140.487 128.977 129.673 122.746 120.407 117.409L120.333 117.365V117.276C119.243 105.398 118.403 95.4914 117.873 88.5522C117.608 85.0827 117.416 82.3513 117.313 80.491C117.254 79.5609 117.225 78.8448 117.21 78.365C117.21 78.1361 117.195 77.9516 117.195 77.8187C117.195 77.6932 117.195 77.6341 117.195 77.6341C117.195 77.6341 117.21 77.6932 117.218 77.8187C117.225 77.9516 117.24 78.1361 117.262 78.365C117.299 78.8596 117.35 79.5682 117.409 80.491C117.564 82.3661 117.777 85.09 118.057 88.5449C118.647 95.484 119.538 105.383 120.65 117.254L120.569 117.128C129.843 122.451 140.663 128.667 152.235 135.31L152.007 135.34C152.648 134.705 153.303 134.063 153.966 133.413C154.092 133.288 154.224 133.162 154.342 133.044L154.379 133.347C143.109 126.688 132.487 120.413 123.317 114.988L123.243 114.943V114.855C122.234 102.253 121.401 91.6896 120.805 84.2559C120.665 82.403 120.532 80.7494 120.422 79.3025C120.363 78.5864 120.326 77.9073 120.26 77.3093C120.157 76.7188 119.722 76.2906 119.265 76.143C118.809 75.9805 118.345 76.0617 118.028 76.2389C117.696 76.4161 117.497 76.6745 117.38 76.9033C117.159 77.3684 117.218 77.6563 117.195 77.6489L117.203 77.6268Z'
        fill='black'
      />
      <path
        d='M123.449 114.84C123.508 114.928 122.83 115.46 122.005 116.11C121.18 116.759 120.51 117.298 120.436 117.217C120.363 117.143 120.922 116.471 121.769 115.807C122.609 115.142 123.397 114.751 123.449 114.84Z'
        fill='black'
      />
      <path
        d='M139.905 170.686H204.21C206.456 170.686 208.276 168.862 208.276 166.611C208.276 164.359 206.456 162.536 204.21 162.536H139.905C137.658 162.536 135.839 164.359 135.839 166.611C135.839 168.862 137.658 170.686 139.905 170.686Z'
        fill='#3ED4A7'
      />
      <path
        d='M199.834 162.536L207.841 168.456L226.838 97.6693C227.508 95.1741 226.027 92.6051 223.538 91.9334C221.048 91.2616 218.485 92.7454 217.822 95.2405L199.834 162.536Z'
        fill='#3ED4A7'
      />
      <path
        d='M206.81 169.748C206.692 169.807 205.867 167.607 203.878 165.607C201.919 163.584 199.768 162.654 199.835 162.543C199.849 162.499 200.409 162.676 201.234 163.126C202.059 163.569 203.149 164.307 204.158 165.341C205.167 166.374 205.882 167.482 206.294 168.323C206.707 169.172 206.862 169.733 206.817 169.748H206.81Z'
        fill='black'
      />
      <path d='M142.071 228H137.843L143.22 169.659L147.411 170.021L142.071 228Z' fill='#3ED4A7' />
      <path d='M205.315 228H209.535L204.129 169.239L199.938 169.6L205.315 228Z' fill='#3ED4A7' />
      <path d='M204.703 193.112H143.382V196.013H204.703V193.112Z' fill='#3ED4A7' />
      <path d='M204.703 200.406H143.382V203.307H204.703V200.406Z' fill='#3ED4A7' />
      <path
        d='M204.026 170.604C204.026 170.708 189.39 170.796 171.343 170.796C153.296 170.796 138.66 170.708 138.66 170.604C138.66 170.501 153.289 170.412 171.343 170.412C189.397 170.412 204.026 170.501 204.026 170.604Z'
        fill='black'
      />
      <path
        d='M142.313 225.298C142.21 225.291 143.064 215 144.228 202.318C145.392 189.635 146.423 179.359 146.526 179.374C146.63 179.382 145.775 189.672 144.611 202.355C143.447 215.037 142.416 225.306 142.313 225.298Z'
        fill='black'
      />
      <path
        d='M157.465 124.592L149.289 118.309L136.281 121.447C136.281 121.447 136.465 122.591 138.137 123.152L141.665 122.591L135.161 127.057C135.161 127.057 135.529 128.91 137.666 129.567C139.802 130.224 140.921 131.9 140.921 131.9L144.265 135.436H153.186L159.233 134.823L157.458 124.584L157.465 124.592Z'
        fill='#EDBE9E'
      />
      <path
        d='M158.982 135.746L156.824 123.315L156.957 123.292L188.719 113.482C188.719 113.482 196.593 81.8715 198.147 78.0623C200.062 73.3599 207.009 68.0891 209.572 68.4361C214.028 69.034 223.309 67.2475 217.807 90.3387C215.678 99.2711 210.176 114.803 206.5 124.85C204.637 129.944 199.93 133.435 194.523 133.738L158.982 135.746Z'
        fill='#06AEC4'
      />
      <path
        d='M217.52 77.0879C217.431 77.1322 217.291 75.796 215.884 74.3787C214.529 72.9834 211.973 71.5882 208.968 71.4627C207.48 71.448 206.051 71.7728 204.85 72.26C203.635 72.7398 202.64 73.3895 201.86 73.9948C200.313 75.2424 199.672 76.3349 199.606 76.2832C199.591 76.2685 199.731 75.9953 200.062 75.5377C200.386 75.08 200.924 74.4525 201.69 73.7881C203.207 72.4888 205.822 71.0567 208.975 71.0789C212.106 71.2117 214.72 72.6955 216.083 74.1941C216.768 74.9471 217.166 75.6927 217.335 76.2316C217.439 76.4973 217.461 76.7188 217.497 76.8664C217.527 77.0141 217.534 77.0879 217.52 77.0953V77.0879Z'
        fill='black'
      />
      <path
        d='M198.368 78.018C198.368 78.018 198.309 78.2395 198.169 78.6529C198.022 79.0663 197.853 79.6937 197.61 80.5058C197.146 82.1372 196.483 84.5438 195.665 87.6295C194.037 93.8009 191.776 102.682 189.087 113.482L189.065 113.57L188.977 113.6C179.769 116.449 168.941 119.808 157.222 123.44H157.215H157.207C157.163 123.447 157.119 123.455 157.075 123.462L157.229 123.241C157.922 127.227 158.651 131.413 159.388 135.672L159.189 135.51C159.992 135.465 160.853 135.414 161.723 135.369C173.457 134.712 184.631 134.085 194.796 133.509C197.308 133.362 199.694 132.483 201.646 131.117C203.598 129.752 205.138 127.914 206.08 125.883C206.552 124.872 206.905 123.809 207.288 122.783C207.664 121.757 208.025 120.746 208.386 119.756C209.093 117.778 209.778 115.873 210.426 114.057C212.99 106.786 214.949 100.858 216.187 96.7242C216.813 94.6646 217.262 93.0479 217.564 91.9554C217.704 91.4165 217.814 91.0031 217.895 90.7078C217.969 90.4273 218.021 90.287 218.021 90.287C218.021 90.287 217.999 90.4347 217.932 90.7152C217.866 91.0105 217.763 91.4313 217.638 91.9702C217.358 93.0701 216.938 94.6941 216.326 96.7611C215.126 100.91 213.211 106.852 210.677 114.139C210.036 115.955 209.366 117.867 208.659 119.852C208.305 120.842 207.937 121.853 207.568 122.886C207.185 123.912 206.846 124.968 206.36 126.016C205.395 128.098 203.826 129.981 201.83 131.391C199.834 132.793 197.389 133.701 194.811 133.856C184.645 134.439 173.471 135.074 161.737 135.746C160.868 135.798 159.999 135.842 159.203 135.886H159.034L159.005 135.724C158.268 131.457 157.539 127.279 156.854 123.292L156.824 123.108L157.008 123.071C157.053 123.064 157.097 123.056 157.141 123.049H157.119C168.845 119.446 179.681 116.11 188.888 113.275L188.785 113.386C191.503 102.593 193.816 93.7197 195.496 87.563C196.335 84.4847 197.028 82.0855 197.521 80.4615C197.779 79.6494 197.963 79.0294 198.133 78.6233C198.287 78.2099 198.376 78.0106 198.376 78.0106L198.368 78.018Z'
        fill='black'
      />
      <path
        d='M215.016 98.0826C214.986 98.1859 210.441 97.0491 204.858 95.5431C199.275 94.0372 194.767 92.7379 194.796 92.6346C194.826 92.5312 199.37 93.6681 204.961 95.174C210.544 96.68 215.052 97.9792 215.023 98.0826H215.016Z'
        fill='white'
      />
      <path
        d='M179.99 133.701C179.887 133.716 179.401 130.032 178.907 125.477C178.414 120.923 178.097 117.224 178.208 117.21C178.311 117.195 178.797 120.878 179.29 125.433C179.784 129.988 180.101 133.686 179.99 133.694V133.701Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_6799_37185'>
        <rect width='183' height='226' fill='white' transform='translate(44 11)' />
      </clipPath>
    </defs>
  </svg>
);
