import styled from 'styled-components';

export const FAQStyles = styled.div`
  padding: 64px 20px;
  max-width: 560px;
  margin: 0 auto;
  > div {
    padding-top: 16px;
  }
  li {
    margin: 0 24px 0 24px;
    border-bottom: ${({ theme }) => `1px solid ${theme.palette.common.white}`};
  }
`;
