import i18next from 'i18next';

import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import {
  common,
  foodSettings,
  homePage,
  sso,
  footer,
  logout,
  reportDetails,
  profileSettings,
  routes,
  transactionDetails,
  enums,
  chart,
  autoLogout,
  poc,
  landingPage,
  cardPage,
  feedback,
  faqs,
  registerComplete,
  passwordRecovery,
  passwordReset,
  accountConfirmation,
  pageNotFound,
  staticPages,
  helpPage,
} from 'impactApp/translations';
import { setGlobalLocale } from 'App/utils';

const resources = {
  en: {
    common,
    foodSettings,
    homePage,
    sso,
    footer,
    logout,
    reportDetails,
    profileSettings,
    routes,
    transactionDetails,
    enums,
    chart,
    autoLogout,
    poc,
    landingPage,
    cardPage,
    feedback,
    faqs,
    registerComplete,
    accountConfirmation,
    passwordRecovery,
    passwordReset,
    pageNotFound,
    staticPages,
    helpPage,
  },
};

export const i18n = i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en'],
    resources,
    debug: false,
    fallbackLng: 'en',
    saveMissing: true,
  })
  .then(function () {
    setGlobalLocale(i18next.language);
  });
