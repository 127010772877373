import { SVGProps } from 'react';

export const CautionIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg {...props} width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_2980_20817)'>
      <path
        d='M12.8125 16.9219C12.6183 16.9219 12.4609 16.7645 12.4609 16.5703C12.4609 16.3762 12.6183 16.2188 12.8125 16.2188'
        stroke='#3ED4A7'
        strokeWidth='1.5'
      />
      <path
        d='M12.8125 16.9219C13.0067 16.9219 13.1641 16.7645 13.1641 16.5703C13.1641 16.3762 13.0067 16.2188 12.8125 16.2188'
        stroke='#3ED4A7'
        strokeWidth='1.5'
      />
      <path
        d='M12.8125 13.4062V5.67188'
        stroke='#3ED4A7'
        strokeWidth='1.5'
        stroke-miterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M12.8125 23C18.8876 23 23.8125 18.0751 23.8125 12C23.8125 5.92487 18.8876 1 12.8125 1C6.73737 1 1.8125 5.92487 1.8125 12C1.8125 18.0751 6.73737 23 12.8125 23Z'
        stroke='#3ED4A7'
        strokeWidth='1.5'
        stroke-miterlimit='10'
      />
    </g>
    <defs>
      <clipPath id='clip0_2980_20817'>
        <rect width='24' height='24' fill='white' transform='translate(0.8125)' />
      </clipPath>
    </defs>
  </svg>
);
