import styled from 'styled-components';
import { Typography } from '@mui/material';

import { HighlightedColor } from 'App/types';

const { BLUE } = HighlightedColor;

export const FootprintListHeaderStyles = styled(Typography)`
  color: ${({
    theme: {
      palette: {
        custom: {
          list: { highlightedData },
        },
      },
    },
  }) => highlightedData[BLUE]};
  margin-left: 8px;
  white-space: nowrap;
`;
