import styled from 'styled-components';
import { common } from 'impactApp/translations';
import { appConfig } from 'App/appConfig/appConfig';

interface LogoProps {
  $infooter?: boolean;
}

const StyledLogo = styled.div<{
  $infooter?: boolean;
}>`
  img {
    display: flex;
    width: ${({ $infooter }) => ($infooter ? '150px' : '92px')};

    ${(props) => props.theme.breakpoints.up('md')} {
      width: ${({ $infooter }) => ($infooter ? '200px' : '92px')};
    }
  }
`;

export const Logo = ({ $infooter }: LogoProps): JSX.Element => (
  <StyledLogo $infooter={$infooter}>
    <img alt={common.logoAlt} src={$infooter ? appConfig.footerLogo : appConfig.blackLogo} />
  </StyledLogo>
);
