import { useContext } from 'react';

import { LogoutIcon } from 'components/Icons';

import { FeedbackContext } from 'App/context';

import { IconButton } from '../IconButton';

export const LogoutButton = () => {
  const { setIsFeedbackModalVisible } = useContext(FeedbackContext);

  return (
    <IconButton.Content
      aria-label='Logout'
      color='inherit'
      edge='end'
      disableRipple
      onClick={() => setIsFeedbackModalVisible && setIsFeedbackModalVisible(true)}
    >
      <IconButton.IconWrapper>
        <LogoutIcon />
      </IconButton.IconWrapper>
    </IconButton.Content>
  );
};
