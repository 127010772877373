import { compose } from 'ramda';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Route, Routes } from 'react-router-dom';
import { requests, useQueryErrorResetBoundary } from '@ecolytiq/js-data-provider';

import { useDeps } from 'App/context';
import { HttpErrorFallback } from 'App/components';
import { PrivateRoute } from 'App/routes';
import { ROUTES } from 'impactApp/routes';

const routes = Object.values(ROUTES);

const MockedApi = (children: JSX.Element) => {
  const { workerService } = useDeps();

  useEffect(() => {
    workerService.start();
    workerService.addHandlers([...requests]);
  }, [workerService]);

  return children;
};

type AppErrorBoundariesProps = {
  children: JSX.Element | Array<JSX.Element>;
};

export const AppErrorBoundaries = ({ children }: AppErrorBoundariesProps) => {
  const { reset } = useQueryErrorResetBoundary();

  return (
    <ErrorBoundary FallbackComponent={HttpErrorFallback} onReset={reset}>
      {children}
    </ErrorBoundary>
  );
};

export const App = () => (
  <AppErrorBoundaries>
    <Routes>
      {routes.map(({ private: isPrivate, id, component: Component, url }) =>
        isPrivate ? (
          <Route key={`route-climate-hero-${id}`} element={<PrivateRoute element={<Component />} />} path={url} />
        ) : (
          <Route key={`route-climate-hero-${id}`} element={<Component />} path={url} />
        ),
      )}
    </Routes>
  </AppErrorBoundaries>
);

export const AppWithMockedApi = () => compose(MockedApi)(<App />);
