import { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { setSessionLogoutAction, useUserContext } from 'App/context';

import { useCookies } from 'react-cookie';

import { RoutePaths } from 'impactApp/routes/routePaths';

import { Dialog } from 'components/Dialog';

import { Grid } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { Button, ButtonAppearance } from 'components';

import { appConfig } from 'App/appConfig/appConfig';

import { handleUserLogout } from '../../utils';

import { CharacterTime } from './assets';

import { AutoLogoutStyles } from './AutoLogout.styles';

export const AutoLogout = () => {
  const {
    REACT_APP_AUTO_LOGOUT_TIMER,
    REACT_APP_AUTO_LOGOUT_MODAL_TIMER,
    REACT_APP_ACCESS_TOKEN_COOKIE_NAME,
    REACT_APP_REFRESH_TOKEN_COOKIE_NAME,
  } = appConfig;
  const { t } = useTranslation();
  const [, dispatch] = useUserContext();
  const [isVisible, setVisible] = useState(false);
  const [seconds, setSeconds] = useState(REACT_APP_AUTO_LOGOUT_MODAL_TIMER);
  const [isAwake, setAwake] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const { LOGOUT_PAGE } = RoutePaths;

  const [cookies, , removeCookie] = useCookies([REACT_APP_ACCESS_TOKEN_COOKIE_NAME]);

  const stayAwake = () => {
    setVisible(false);
    setAwake(true);
    setTimeout(() => setSeconds(REACT_APP_AUTO_LOGOUT_MODAL_TIMER), 1000);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;

    const goBackToHome = () => {
      setVisible(true);
      setAwake(false);
    };

    const restartAutoReset = () => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => {
        goBackToHome();
      }, REACT_APP_AUTO_LOGOUT_TIMER);
    };

    const onMouseMove = () => {
      restartAutoReset();
    };

    if (location.pathname === LOGOUT_PAGE) return undefined;

    restartAutoReset();

    window.addEventListener('mousemove', onMouseMove);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
        window.removeEventListener('mousemove', onMouseMove);
      }
    };
  }, [LOGOUT_PAGE, REACT_APP_AUTO_LOGOUT_TIMER, location]);

  useEffect(() => {
    if (seconds > 0 && !isAwake) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else if (!isAwake) {
      setVisible(false);
      setAwake(true);
      setTimeout(() => setSeconds(REACT_APP_AUTO_LOGOUT_MODAL_TIMER), 1000);
      dispatch(setSessionLogoutAction(true));
      handleUserLogout();
    }
  }, [
    seconds,
    isAwake,
    dispatch,
    REACT_APP_AUTO_LOGOUT_MODAL_TIMER,
    LOGOUT_PAGE,
    navigate,
    cookies,
    REACT_APP_ACCESS_TOKEN_COOKIE_NAME,
    REACT_APP_REFRESH_TOKEN_COOKIE_NAME,
    removeCookie,
  ]);

  return (
    <Dialog
      isOpen={isVisible}
      onClose={() => stayAwake()}
      isCenteredText
      imgComponent={<CharacterTime />}
      hasExtraPadding
      hasWhiteBackground
      contentHasDarkBackground
      removeCloseIcon
      slug={t('autoLogout:slug')}
    >
      <AutoLogoutStyles.Title variant='h6'>{t('autoLogout:title')}</AutoLogoutStyles.Title>
      <AutoLogoutStyles.Subtitle variant='body2'>{t('autoLogout:content')}</AutoLogoutStyles.Subtitle>
      <AutoLogoutStyles.Text variant='subtitle2'>
        {t('autoLogout:counterMessage:prefix')} {seconds} {t('autoLogout:counterMessage:postfix')}
      </AutoLogoutStyles.Text>
      <Grid display='flex' width='100%' justifyContent='center'>
        <Button appearance={ButtonAppearance.SECONDARY} onClick={() => stayAwake()} text={t('autoLogout:button')} />
      </Grid>
    </Dialog>
  );
};
