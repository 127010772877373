export const FeedbackIllutstration = (): JSX.Element => (
  <svg width='240' height='240' viewBox='0 0 240 240' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      opacity='0.2'
      d='M106.832 204.866C149.814 204.866 184.658 170.022 184.658 127.04C184.658 84.0573 149.814 49.2133 106.832 49.2133C63.8499 49.2133 29.0059 84.0573 29.0059 127.04C29.0059 170.022 63.8499 204.866 106.832 204.866Z'
      fill='#7B4EFA'
    />
    <path
      d='M122.277 48.5575C121.232 45.5729 119.084 42.6929 115.776 41.5143C111.337 39.9365 105.853 42.1036 103.486 45.763C101.119 49.4224 101.566 54.1178 103.771 57.8247C105.986 61.5317 109.759 64.3641 113.846 66.5503L117.686 66.1321C122.562 61.4841 124.378 54.5551 122.287 48.5575H122.277Z'
      fill='black'
    />
    <path
      d='M159.899 78.1273L104.428 65.5333C92.6514 78.6786 85.057 95.5118 83.0039 113.039C103.972 124.331 133.941 132.876 157.627 135.29C162.722 117.116 158.777 96.966 159.899 78.1273Z'
      fill='black'
    />
    <path
      d='M107.811 62.9099C115.481 55.8572 124.91 52.502 135.252 53.7946C141.088 54.5265 143.331 56.0758 147.361 57.8057C151.391 59.5356 154.86 62.3206 156.856 66.2176C159.233 70.856 161.058 75.9316 160.896 81.1308C160.725 86.4726 160.554 91.8619 159.413 97.0801C158.273 102.308 156.077 107.421 152.361 111.261L138.588 111.689C131.127 110.891 123.561 110.064 116.565 107.345C109.569 104.627 103.097 99.7414 100.112 92.8504C95.9774 83.2884 100.141 69.9625 107.811 62.9004V62.9099Z'
      fill='black'
    />
    <path
      d='M142.457 60.6762L137.847 59.4691L118.913 64.041C116.309 63.8034 113.952 65.5998 113.496 68.1756L107.745 125.338L131.964 136.307L134.616 124.245C134.616 124.245 146.772 124.777 151.791 111.166C154.291 104.399 156.201 92.8694 157.465 83.3835C158.606 74.8481 152.979 62.2065 142.448 60.6667L142.457 60.6762Z'
      fill='#D5A479'
    />
    <path
      d='M131.136 105.036C131.336 104.313 131.839 103.715 132.429 103.334C133.607 102.583 135.157 102.717 136.164 103.648C137.181 104.579 137.599 106.262 137.172 107.707C136.421 108.486 135.366 108.933 134.292 108.876C134.121 108.876 133.959 108.847 133.788 108.819C132.98 108.686 132.191 108.296 131.659 107.612C131.127 106.927 130.879 105.929 131.127 105.036H131.136Z'
      fill='black'
      fill-opacity='0.2'
    />
    <path
      d='M150.964 94.2381C150.812 95.1981 149.871 95.8539 148.863 95.7114C147.865 95.5688 147.172 94.6753 147.324 93.7153C147.476 92.7553 148.417 92.0995 149.424 92.2421C150.432 92.3846 151.116 93.2781 150.964 94.2381Z'
      fill='black'
    />
    <path
      d='M153.54 87.8128C153.274 88.0029 152.105 86.7198 150.166 86.3396C148.227 85.9309 146.611 86.6437 146.449 86.3681C146.364 86.2445 146.677 85.8643 147.39 85.5316C148.094 85.199 149.215 84.9709 150.422 85.218C151.629 85.4651 152.561 86.1114 153.055 86.6817C153.559 87.2615 153.683 87.7273 153.549 87.8033L153.54 87.8128Z'
      fill='black'
    />
    <path
      d='M138.798 101.357C138.807 101.243 140.062 101.272 142.068 101.414C142.571 101.462 143.066 101.452 143.208 101.139C143.398 100.806 143.294 100.226 143.17 99.6084C142.942 98.3157 142.704 96.966 142.448 95.5498C141.478 89.7803 140.879 85.0658 141.108 85.0278C141.345 84.9898 142.315 89.6377 143.294 95.4167C143.522 96.8424 143.74 98.1921 143.95 99.4848C144.026 100.084 144.225 100.787 143.826 101.481C143.617 101.823 143.199 102.023 142.866 102.042C142.533 102.08 142.248 102.023 142.001 101.994C140.014 101.709 138.788 101.481 138.807 101.367L138.798 101.357Z'
      fill='black'
    />
    <path
      d='M134.597 124.378C134.597 124.378 125.016 123.114 116.756 115.624C116.756 115.624 119.218 126.983 133.551 128.456L134.597 124.378Z'
      fill='black'
      fill-opacity='0.2'
    />
    <path
      d='M132.819 101.566C133.142 101.614 132.724 103.715 134.235 105.568C135.737 107.421 138.027 107.631 137.989 107.935C137.989 108.077 137.41 108.248 136.459 108.087C135.528 107.935 134.225 107.364 133.303 106.233C132.382 105.102 132.153 103.781 132.248 102.907C132.343 102.013 132.676 101.538 132.819 101.576V101.566Z'
      fill='black'
    />
    <path
      d='M153.844 80.8742C153.426 81.2829 152 80.6746 150.232 80.475C148.474 80.2184 146.934 80.4465 146.639 79.9427C146.506 79.7051 146.791 79.2774 147.485 78.9162C148.17 78.555 149.282 78.3269 150.498 78.479C151.705 78.6406 152.722 79.1443 153.293 79.6671C153.872 80.1899 154.034 80.6746 153.844 80.8742Z'
      fill='black'
    />
    <path
      d='M130.253 90.6928C130.062 91.6433 130.718 92.5938 131.716 92.8029C132.705 93.012 133.665 92.4227 133.845 91.4627C134.035 90.5122 133.38 89.5617 132.391 89.3526C131.403 89.134 130.443 89.7328 130.253 90.6928Z'
      fill='black'
    />
    <path
      d='M127.173 83.298C127.364 83.5736 128.903 82.7752 130.852 83.0889C132.81 83.374 134.084 84.6097 134.331 84.4101C134.454 84.3245 134.293 83.8588 133.741 83.3075C133.2 82.7562 132.221 82.1479 131.004 81.9578C129.787 81.7677 128.694 82.0529 128.029 82.414C127.354 82.7752 127.078 83.1744 127.173 83.298Z'
      fill='black'
    />
    <path
      d='M129.236 77.2053C129.549 77.7091 131.127 77.4905 132.942 77.7566C134.767 77.9657 136.241 78.593 136.668 78.1843C136.858 77.9847 136.687 77.5 136.089 76.9772C135.49 76.4544 134.444 75.9317 133.19 75.7701C131.935 75.6085 130.794 75.8366 130.091 76.1883C129.378 76.54 129.093 76.9677 129.226 77.2053H129.236Z'
      fill='black'
    />
    <path
      d='M110.597 88.3261L112.688 91.2156C124.721 82.5851 149.035 60.1345 147.666 61.4842C147.666 61.4842 140.822 57.4731 132.753 57.6917C124.683 57.9103 109.171 59.7353 108.087 69.0596C107.004 78.3839 110.597 88.3261 110.597 88.3261Z'
      fill='black'
    />
    <path
      d='M112.516 92.4702C112.725 91.4247 112.003 88.7157 110.995 88.3451C108.315 87.366 103.296 86.6057 101.861 93.6013C99.9031 103.173 109.921 103.173 109.997 102.897C110.054 102.688 111.813 96.0821 112.516 92.4607V92.4702Z'
      fill='#D5A479'
    />
    <path
      d='M108.068 98.867C108.068 98.867 107.878 98.9525 107.574 99.0191C107.27 99.0856 106.785 99.0761 106.319 98.791C105.388 98.2112 104.865 96.4337 105.169 94.6943C105.321 93.8199 105.673 93.031 106.129 92.4037C106.566 91.7668 107.118 91.3676 107.65 91.3866C108.182 91.3771 108.477 91.7478 108.543 92.0425C108.619 92.3371 108.515 92.5177 108.562 92.5462C108.581 92.5747 108.8 92.4322 108.8 92.014C108.8 91.8144 108.734 91.5672 108.543 91.3391C108.353 91.1015 108.03 90.9399 107.669 90.9019C106.918 90.7973 106.12 91.3486 105.625 92.033C105.093 92.7078 104.675 93.6013 104.504 94.5803C104.171 96.5193 104.789 98.5248 106.091 99.2092C106.728 99.5228 107.336 99.4373 107.669 99.2662C108.011 99.0951 108.106 98.886 108.078 98.867H108.068Z'
      fill='black'
      fill-opacity='0.2'
    />
    <path
      d='M96.8142 63.2806C98.0689 65.3147 100.559 66.5218 102.945 66.2366C100.236 68.0426 96.8142 68.7554 93.6016 68.1851C96.9948 71.4073 102.489 72.1677 106.623 69.9911L110.615 60.3531C107.175 63.6703 101.282 64.9725 96.8142 63.2806Z'
      fill='black'
    />
    <path
      d='M133.845 132.087L108.695 122.962H101.946L118.694 141.468L125.794 147.675L134.434 147.209L138.151 143.036L133.845 132.087Z'
      fill='#D5A479'
    />
    <path
      d='M160.992 151.334C158.919 144.462 149.785 139.263 149.785 139.263L134.653 131.621C134.653 131.621 133.532 137.353 123.761 135.528C111.338 133.199 107.964 122.268 107.964 122.268C107.964 122.268 84.8761 122.126 80.6179 122.8L71.7783 205.921L137.011 206.881L142.371 199.543C145.479 195.295 147.922 190.58 149.462 185.542C150.479 182.225 151.002 179.659 151.002 179.659C151.002 179.659 163.311 178.68 167.398 173.148C167.398 173.148 162.208 155.374 160.992 151.334Z'
      fill='#3ED4A7'
    />
    <path
      d='M149.566 151.477C149.471 151.505 149.899 153.207 150.412 155.982C150.925 158.758 151.458 162.636 151.6 166.941C151.733 171.247 151.448 175.144 151.106 177.948C150.773 180.752 150.44 182.482 150.545 182.501C150.583 182.501 150.707 182.092 150.897 181.313C151.087 180.543 151.315 179.412 151.534 178.005C151.99 175.201 152.341 171.266 152.208 166.913C152.066 162.569 151.458 158.662 150.83 155.887C150.517 154.499 150.222 153.387 149.984 152.627C149.747 151.866 149.595 151.458 149.557 151.467L149.566 151.477Z'
      fill='black'
    />
    <path
      d='M108.848 193.65C108.848 193.65 109.237 193.65 109.931 193.527C110.273 193.47 110.701 193.393 111.176 193.241C111.652 193.099 112.212 192.975 112.783 192.728C113.971 192.31 115.311 191.645 116.727 190.761C118.115 189.848 119.55 188.708 120.881 187.32C122.192 185.913 123.257 184.411 124.084 182.976C124.882 181.522 125.472 180.134 125.833 178.936C126.042 178.347 126.147 177.786 126.261 177.302C126.384 176.817 126.441 176.399 126.47 176.047C126.546 175.353 126.574 174.963 126.527 174.963C126.403 174.944 126.232 176.484 125.415 178.803C125.015 179.963 124.398 181.294 123.59 182.7C122.753 184.088 121.708 185.533 120.425 186.902C119.132 188.251 117.744 189.373 116.404 190.295C115.045 191.179 113.752 191.873 112.612 192.329C110.34 193.279 108.819 193.536 108.838 193.65H108.848Z'
      fill='black'
    />
    <path
      d='M90.1703 122.335L79.4107 122.61C77.5667 122.658 75.7323 122.99 73.9834 123.599L47.4932 132.781L62.5585 147.979L82.8515 152.731L90.1608 122.335H90.1703Z'
      fill='#3ED4A7'
    />
    <path
      d='M78.6597 135.49C78.7643 135.49 78.9829 139.634 79.1635 144.747C79.3441 149.87 79.4011 154.015 79.3061 154.024C79.2015 154.024 78.9829 149.88 78.8023 144.766C78.6217 139.653 78.5647 135.499 78.6597 135.499V135.49Z'
      fill='black'
    />
    <path
      d='M178.024 162.369H41.9614C29.956 162.369 20.2236 172.102 20.2236 184.107V185.647C20.2236 197.652 29.956 207.385 41.9614 207.385H178.024C190.03 207.385 199.762 197.652 199.762 185.647V184.107C199.762 172.102 190.03 162.369 178.024 162.369Z'
      fill='#F0F0F0'
    />
    <path
      d='M49.575 172.749L52.1888 178.053C52.3979 178.471 52.7876 178.756 53.2439 178.822L59.0894 179.668C60.2395 179.839 60.7052 181.256 59.8688 182.064L55.6391 186.189C55.3064 186.512 55.1543 186.978 55.2304 187.434L56.2284 193.26C56.428 194.41 55.2209 195.285 54.1848 194.743L48.9571 191.996C48.5484 191.778 48.0542 191.778 47.6455 191.996L42.4178 194.743C41.3817 195.285 40.1841 194.41 40.3742 193.26L41.3722 187.434C41.4483 186.978 41.2962 186.512 40.9635 186.189L36.7338 182.064C35.8974 181.246 36.3631 179.83 37.5132 179.668L43.3587 178.822C43.815 178.756 44.2142 178.471 44.4138 178.053L47.0276 172.749C47.5409 171.703 49.0332 171.703 49.5464 172.749H49.575Z'
      fill='#7B4EFA'
    />
    <path
      d='M81.0173 172.749L83.6312 178.053C83.8403 178.471 84.23 178.756 84.6862 178.822L90.5318 179.668C91.6819 179.839 92.1476 181.256 91.3112 182.064L87.0815 186.189C86.7488 186.512 86.5967 186.978 86.6728 187.434L87.6708 193.26C87.8704 194.41 86.6633 195.285 85.6272 194.743L80.3995 191.996C79.9908 191.778 79.4966 191.778 79.0878 191.996L73.8601 194.743C72.8241 195.285 71.6265 194.41 71.8166 193.26L72.8146 187.434C72.8906 186.978 72.7386 186.512 72.4059 186.189L68.1762 182.064C67.3398 181.246 67.8055 179.83 68.9556 179.668L74.8011 178.822C75.2574 178.756 75.6566 178.471 75.8562 178.053L78.47 172.749C78.9833 171.703 80.4756 171.703 80.9888 172.749H81.0173Z'
      fill='#7B4EFA'
    />
    <path
      d='M112.678 172.749L115.291 178.053C115.5 178.471 115.89 178.756 116.346 178.822L122.192 179.668C123.342 179.839 123.808 181.256 122.971 182.064L118.742 186.189C118.409 186.512 118.257 186.978 118.333 187.434L119.331 193.26C119.531 194.41 118.323 195.285 117.287 194.743L112.06 191.996C111.651 191.778 111.157 191.778 110.748 191.996L105.52 194.743C104.484 195.285 103.287 194.41 103.477 193.26L104.475 187.434C104.551 186.978 104.399 186.512 104.066 186.189L99.8364 182.064C98.9999 181.246 99.4657 179.83 100.616 179.668L106.461 178.822C106.918 178.756 107.317 178.471 107.516 178.053L110.13 172.749C110.643 171.703 112.136 171.703 112.649 172.749H112.678Z'
      fill='#7B4EFA'
    />
    <path
      d='M141.573 172.749L144.187 178.053C144.396 178.471 144.786 178.756 145.242 178.822L151.087 179.668C152.238 179.839 152.703 181.256 151.867 182.064L147.637 186.189C147.304 186.512 147.152 186.978 147.228 187.434L148.226 193.26C148.426 194.41 147.219 195.285 146.183 194.743L140.955 191.996C140.546 191.778 140.052 191.778 139.644 191.996L134.416 194.743C133.38 195.285 132.182 194.41 132.372 193.26L133.37 187.434C133.446 186.978 133.294 186.512 132.962 186.189L128.732 182.064C127.895 181.246 128.361 179.83 129.511 179.668L135.357 178.822C135.813 178.756 136.212 178.471 136.412 178.053L139.026 172.749C139.539 171.703 141.031 171.703 141.544 172.749H141.573Z'
      fill='#7B4EFA'
    />
    <path
      d='M172.664 173.623L175.278 178.927C175.487 179.345 175.876 179.63 176.333 179.697L182.178 180.543C183.328 180.714 183.794 182.13 182.958 182.938L178.728 187.063C178.395 187.386 178.243 187.852 178.319 188.308L179.317 194.135C179.517 195.285 178.31 196.159 177.274 195.618L172.046 192.871C171.637 192.652 171.143 192.652 170.734 192.871L165.507 195.618C164.471 196.159 163.273 195.285 163.463 194.135L164.461 188.308C164.537 187.852 164.385 187.386 164.052 187.063L159.823 182.938C158.986 182.121 159.452 180.704 160.602 180.543L166.448 179.697C166.904 179.63 167.303 179.345 167.503 178.927L170.117 173.623C170.63 172.578 172.122 172.578 172.635 173.623H172.664Z'
      fill='white'
    />
    <path
      d='M103.525 168.5L106.833 154.366L61.6749 146.544L48.311 133.807C48.311 133.807 37.2948 137.866 32.2382 143.588C24.8909 151.895 31.1546 161.638 36.2778 163.006C41.4104 164.375 103.516 168.5 103.516 168.5H103.525Z'
      fill='#D5A479'
    />
    <path
      d='M44.0146 144.386C44.0146 144.386 44.2713 144.329 44.737 144.31C45.2028 144.282 45.8776 144.263 46.7045 144.263C48.3679 144.263 50.6681 144.339 53.1869 144.633C55.7152 144.928 57.9679 145.384 59.5837 145.802C60.3916 146.011 61.038 146.202 61.4752 146.354C61.9124 146.506 62.1595 146.601 62.15 146.62C62.131 146.677 61.1425 146.401 59.5267 146.049C57.9108 145.688 55.6582 145.28 53.1489 144.994C50.6396 144.709 48.3584 144.576 46.695 144.519C45.0412 144.462 44.0146 144.453 44.0146 144.396V144.386Z'
      fill='black'
      fill-opacity='0.2'
    />
    <path
      d='M99.4855 153.416C101.748 153.188 109.855 154.48 113.268 155.801C118.8 157.95 121.138 160.345 125.31 160.991C129.483 161.637 134.416 160.915 134.302 163.206C134.188 165.487 125.082 166.666 125.082 166.666C125.082 166.666 136.156 179.497 134.777 181.341C134.312 181.959 132.496 182.415 131.165 181.047C128.209 177.995 124.179 173.785 124.179 173.785C124.179 173.785 124.74 183.955 122.716 185.98C120.691 188.004 118.857 186.445 118.857 186.445C118.857 186.445 117.735 188.242 115.568 187.633C114.209 187.253 113.743 185.362 113.743 185.362C113.743 185.362 111.405 185.904 110.606 183.062C109.855 180.381 108.62 171.998 108.62 171.998C108.62 171.998 101.881 168.281 101.605 168.586L93.1838 167.806L92.1572 155.326L99.495 153.435L99.4855 153.416Z'
      fill='#D5A479'
    />
    <path
      d='M119.55 172.388C119.55 172.388 119.721 173.167 119.816 174.441C119.911 175.714 119.949 177.492 119.864 179.44C119.769 181.389 119.56 183.147 119.341 184.411C119.122 185.675 118.913 186.436 118.866 186.426C118.809 186.417 118.932 185.637 119.084 184.373C119.236 183.109 119.408 181.36 119.503 179.421C119.598 177.482 119.598 175.724 119.569 174.45C119.541 173.176 119.503 172.388 119.56 172.388H119.55Z'
      fill='black'
      fill-opacity='0.2'
    />
    <path
      d='M113.552 172.568C113.552 172.568 113.809 173.262 114.028 174.431C114.246 175.6 114.436 177.235 114.455 179.041C114.474 180.856 114.303 182.491 114.113 183.66C113.923 184.829 113.723 185.542 113.676 185.533C113.619 185.523 113.733 184.801 113.856 183.632C113.98 182.463 114.104 180.837 114.094 179.05C114.075 177.254 113.923 175.638 113.771 174.469C113.619 173.3 113.495 172.578 113.552 172.568Z'
      fill='black'
      fill-opacity='0.2'
    />
    <path
      d='M122.06 162.921C122.126 162.854 123.067 163.691 124.046 164.898C125.025 166.105 125.643 167.198 125.567 167.255C125.481 167.312 124.73 166.314 123.77 165.126C122.81 163.938 121.993 162.997 122.069 162.921H122.06Z'
      fill='black'
      fill-opacity='0.2'
    />
    <path
      d='M181.095 171.352C181.56 170.515 181.693 169.536 181.76 168.576C181.817 167.616 181.95 165.82 182.15 164.879L182.416 158.748C181.133 155.897 177.987 156.828 177.369 158.453C177.036 157.598 177.122 157.085 176.38 156.533C175.639 155.982 174.65 155.735 173.776 156.02C172.901 156.305 172.217 157.199 172.322 158.121C171.514 156.952 169.926 156.391 168.567 156.781C167.75 157.018 167.037 157.579 166.59 158.301C166.362 158.663 166.21 159.062 166.124 159.48C166.096 159.632 166.039 159.803 166.067 159.955C166.077 160.031 166.115 160.098 166.153 160.164C166.333 160.459 166.552 160.716 166.714 161.029C166.771 161.143 166.828 161.267 166.885 161.39C167.122 161.942 167.255 162.531 167.331 163.12C167.37 163.396 167.331 163.7 167.417 163.966C167.465 164.147 167.598 164.289 167.674 164.451C168.177 165.506 168.139 166.78 168.168 167.92C168.196 169.203 168.282 170.563 169.004 171.627C169.727 172.682 171.324 173.253 172.341 172.464C172.227 173.272 172.892 174.07 173.69 174.232C174.489 174.393 175.335 174.022 175.886 173.424C176.437 172.825 176.732 172.026 176.893 171.218C176.893 172.178 177.872 172.948 178.832 172.91C179.792 172.872 180.638 172.188 181.104 171.352H181.095Z'
      fill='#D5A479'
    />
    <path
      d='M162.74 158.91C161.952 158.919 161.096 159.128 160.602 159.746C160.013 160.478 159.955 161.875 160.079 162.807C161.182 162.959 161.752 162.968 162.864 163.12C163.102 163.149 163.292 163.082 163.482 163.206C164.005 163.538 163.986 164.755 164.223 165.325C164.632 166.323 165.953 166.751 166.942 166.323C167.93 165.896 168.519 164.793 168.519 163.71C168.519 162.636 167.987 161.609 167.255 160.82C166.105 159.594 164.423 158.881 162.74 158.9V158.91Z'
      fill='#D5A479'
    />
    <path
      d='M172.303 157.807C172.512 157.807 172.683 161.086 172.683 165.135C172.683 169.184 172.521 172.464 172.322 172.464C172.112 172.464 171.941 169.184 171.941 165.135C171.941 161.086 172.103 157.807 172.303 157.807Z'
      fill='black'
      fill-opacity='0.2'
    />
    <path
      d='M177.378 158.235C177.578 158.216 177.996 161.191 177.872 164.879C177.758 168.567 177.131 171.504 176.941 171.475C176.732 171.447 177.017 168.5 177.131 164.86C177.254 161.219 177.169 158.263 177.388 158.244L177.378 158.235Z'
      fill='black'
      fill-opacity='0.2'
    />
    <path
      d='M164.346 159.337C164.337 159.252 164.86 159.1 165.639 159.413C166.409 159.699 167.312 160.525 167.778 161.666C168.243 162.816 168.424 163.805 168.434 164.66C168.396 165.573 167.749 165.867 167.721 165.753C167.654 165.677 167.996 165.344 167.901 164.67C167.778 164.023 167.493 162.949 167.084 161.97C166.666 160.963 165.991 160.25 165.392 159.889C164.803 159.518 164.337 159.461 164.337 159.347L164.346 159.337Z'
      fill='black'
      fill-opacity='0.2'
    />
    <path
      d='M177.948 75.6084L178.413 69.5823C175.714 65.8944 174.079 61.3606 173.984 56.38C173.775 44.6414 182.7 34.4806 194.372 33.15C207.85 31.6197 219.76 41.9895 219.997 55.6576C220.197 67.2631 211.481 77.3383 199.98 78.8306C194.268 79.572 188.821 78.1653 184.411 75.2663L177.948 75.6084Z'
      fill='#F0F0F0'
    />
    <path
      d='M203.198 55.8878C203.198 57.5037 204.51 58.8154 206.126 58.8154C207.742 58.8154 209.053 57.5037 209.053 55.8878C209.053 54.272 207.742 52.9603 206.126 52.9603C204.51 52.9603 203.198 54.272 203.198 55.8878Z'
      fill='#6F7888'
    />
    <path
      d='M193.342 55.8878C193.342 57.5037 194.653 58.8154 196.269 58.8154C197.885 58.8154 199.197 57.5037 199.197 55.8878C199.197 54.272 197.885 52.9603 196.269 52.9603C194.653 52.9603 193.342 54.272 193.342 55.8878Z'
      fill='#6F7888'
    />
    <path
      d='M183.485 55.8878C183.485 57.5037 184.797 58.8154 186.413 58.8154C188.029 58.8154 189.34 57.5037 189.34 55.8878C189.34 54.272 188.029 52.9603 186.413 52.9603C184.797 52.9603 183.485 54.272 183.485 55.8878Z'
      fill='#6F7888'
    />
  </svg>
);
