import styled from 'styled-components';

import { Categories } from 'App/types';

const ContentWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const ContentWithBackground = styled.div<{
  category: Categories;
}>`
  position: relative;
  white-space: pre-wrap;
  padding: 24px 0 68px;
  min-height: 370px;
  overflow: hidden;
  z-index: 2;
  ${(props) => props.theme.breakpoints.up('md')} {
    overflow: visible;
    padding-top: 64px;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    padding-top: 70px;
  }
`;

const CircleWrapper = styled.div`
  align-self: end;
  margin-bottom: 16px;
  margin-right: -80px;
  margin-top: 40px;

  ${(props) => props.theme.breakpoints.up('md')} {
    margin-bottom: -40px;
    margin-right: -104px;
    margin-top: 0;
  }

  ${(props) => props.theme.breakpoints.up('xl')} {
    margin-bottom: 12px;
    margin-right: -70px;
  }
`;

const ImageWrapper = styled.div`
  margin-bottom: -70px;
  margin-right: 0;
  margin-top: 40px;

  svg {
    width: 240px;
    height: 240px;
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    margin-bottom: -120px;
    margin-right: -20px;
    margin-top: 0;

    svg {
      width: 360px;
      height: 360px;
    }
  }
`;

export const ReportDetailsHeaderStyles = {
  ContentWithBackground,
  ContentWrapper,
  CircleWrapper,
  ImageWrapper,
};
