import { Typography } from '@mui/material';
import styled from 'styled-components';

import { ExpandLessIcon } from 'components/Icons';
import { Grid } from 'components';

const Content = styled(Grid)`
  margin: 20px auto;
  height: 15px;
  cursor: pointer;
`;

const ButtonText = styled(Typography)`
  margin: 0;
  margin-right: 8px;
`;

const StyledExpandLessIcon = styled(ExpandLessIcon)`
  margin-top: 4px;
`;

export const PaginationExpanderStyles = {
  ButtonText,
  Content,
  StyledExpandLessIcon,
};
