import { color } from 'App/style';

import { ExpandMoreIcon, ENFlag, ARFlag } from 'components/Icons';

import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import { setGlobalLocale } from 'App/utils';

import { useState } from 'react';

import { LanguageSwitcherStyles } from './LanguageSwitcher.styles';

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const currentLanguge = i18n.resolvedLanguage;
  const [isOpen, setIsOpen] = useState(false);

  const changeLanguage = (langauge: string) => {
    i18n.changeLanguage(langauge);
    setGlobalLocale(langauge);
    setIsOpen(false);
  };

  return (
    <LanguageSwitcherStyles.Wrapper>
      <LanguageSwitcherStyles.LanguageSwitcherFlag onClick={() => setIsOpen(!isOpen)}>
        {currentLanguge === 'en' ? <ENFlag /> : <ARFlag />}
        <ExpandMoreIcon color={color.black} />
      </LanguageSwitcherStyles.LanguageSwitcherFlag>
      <LanguageSwitcherStyles.Selector isOpen={isOpen}>
        <LanguageSwitcherStyles.TextFlag onClick={() => changeLanguage('en')}>
          <ENFlag />
          <Typography variant='overline'>EN</Typography>
        </LanguageSwitcherStyles.TextFlag>

        <LanguageSwitcherStyles.TextFlag onClick={() => changeLanguage('ar')}>
          <ARFlag />
          <Typography variant='overline'>AR</Typography>
        </LanguageSwitcherStyles.TextFlag>
      </LanguageSwitcherStyles.Selector>
    </LanguageSwitcherStyles.Wrapper>
  );
};
