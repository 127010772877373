import styled from 'styled-components';
import { footerHeight, footerMobileHeight } from 'App/style/theme';
import { Typography } from '@mui/material';
import { CautionIcon } from 'components/Icons';
import { Button } from 'components';

const Icon = styled(CautionIcon)`
  margin-bottom: 40px;
`;

const LogoWrapper = styled.div`
  padding: 56px 0 64px;
  width: 216px;
  margin: 0 auto;
  img {
    width: 100%;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 280px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 50%;
    margin: 0 auto;
  }
`;

const Title = styled(Typography)`
  color: ${({ theme }) => `${theme.palette.common.white}`};
  text-align: center;
  margin-bottom: 56px;
  padding: 0 32px;
`;

const Text = styled(Typography)`
  color: ${({ theme }) => `${theme.palette.common.white}`};
  text-align: center;
  margin-bottom: 50px;
  padding: 0 32px;
  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-bottom: 52px;
    padding: 0 128px;
  }
`;

const ButtonWrapper = styled(Button)`
  margin: 20px auto 0;
`;

export const SSOStyles = {
  Container,
  Icon,
  Title,
  Text,
  ButtonWrapper,
  LogoWrapper,
};
