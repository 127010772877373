import styled from 'styled-components';
import { color } from 'App/style/theme';
import { Typography } from '@mui/material';
import { CloseIcon as Icon } from 'components/Icons';

const TextWrapper = styled(Typography)`
  color: ${color.white};
  text-align: center;
  margin-top: 8px;
  a {
    color: ${color.white};
  }
`;

const LinkWrapper = styled(Typography)`
  color: ${color.white};
  margin-top: 56px;

  text-align: center;
  a {
    color: ${color.white};
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 36px;
  margin-bottom: 80px;
  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-top: 40px;
  }
`;

const CurveWrapper = styled.div`
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;

const ImgWrapper = styled.div`
  svg {
    height: 320px;
    max-width: 320px;
  }
  margin-bottom: 20px;
  ${(props) => props.theme.breakpoints.up('lg')} {
    svg {
      height: 360px;
      max-width: 360px;
    }
  }
`;

const Img = styled.div`
  max-width: 100%;
`;

const Wrapper = styled.div`
  height: 100vh;
`;

const CloseIcon = styled(Icon)`
  position: absolute;
  z-index: 3;
  top: 36px;
  right: 24px;
  cursor: pointer;
  line {
    stroke: ${({ theme }) => theme.palette.common.white};
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    top: 56px;
  }
`;

const IconWrapper = styled.div`
  svg * {
    fill: ${({ theme }) => theme.palette.common.white};
  }
`;

export const ProfileSettingsStyles = {
  TextWrapper,
  ButtonWrapper,
  CurveWrapper,
  ImgWrapper,
  Img,
  Wrapper,
  IconWrapper,
  CloseIcon,
  LinkWrapper,
};
