import styled from 'styled-components';

import { Typography } from '@mui/material';

const Text = styled(Typography)`
  margin-bottom: 30px;
  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-bottom: 40px;
  }
`;

const Title = styled(Typography)`
  margin-bottom: 16px;
`;

const Subtitle = styled(Typography)`
  margin-bottom: 32px;
  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-bottom: 40px;
  }
`;

export const AutoLogoutStyles = {
  Title,
  Subtitle,
  Text,
};
