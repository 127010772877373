import { PropsWithChildren } from 'react';

import { useTheme } from 'styled-components';

import { ListItem as MuiListItem, ListItemAvatar } from '@mui/material';

import { CloudIcon as BasicCloudIcon } from 'components/Icons';

import { Avatar, AvatarSize, Grid } from 'components';

import { ListItemProps } from 'components/List/ListItem';

import { BasicListItemStyles } from './BasicListItem.styles';

export const BasicListItem = ({
  appearance,
  avatarSize = AvatarSize.MEDIUM,
  date: itemDate,
  detailsIcon = <BasicCloudIcon />,
  icon,
  id,
  information: itemInformation,
  title,
  value,
  weightValue,
  flipValues,
}: PropsWithChildren<ListItemProps>) => {
  const {
    palette: {
      custom: {
        list: { iconItemColor },
      },
    },
  } = useTheme();

  const date = itemDate ? (
    <BasicListItemStyles.Information variant='caption'>{itemDate}</BasicListItemStyles.Information>
  ) : null;

  const information = itemInformation ? (
    <BasicListItemStyles.Information appearance={appearance} id='list-item-information' variant='caption'>
      {itemInformation}
    </BasicListItemStyles.Information>
  ) : null;
  return (
    <MuiListItem id={id} alignItems='flex-start'>
      <ListItemAvatar>
        <Avatar color={iconItemColor} icon={icon} size={avatarSize} />
      </ListItemAvatar>
      <Grid justifyContent='space-between' width='100%'>
        <Grid flexDirection='column' width='100%'>
          <BasicListItemStyles.Title
            appearance={appearance}
            id='list-item-title'
            sx={{ typography: { sm: 'subtitle2', lg: 'subtitle2' } }}
          >
            {title}
          </BasicListItemStyles.Title>
          {information}
          {date}
        </Grid>
        <Grid justifyContent='flex-end' alignItems='center' width='100%' columnGap={{ sm: 0.5 }}>
          <Grid alignItems='flex-end' flexDirection={flipValues ? 'column-reverse' : 'column'}>
            <Grid alignItems='center'>
              <BasicListItemStyles.DetailsIcon flipValues={flipValues} $color={appearance}>
                {detailsIcon}
              </BasicListItemStyles.DetailsIcon>
              <BasicListItemStyles.Value
                variant='numbers'
                sx={{
                  typography: { sm: 'numbers', lg: 'body1' },
                  fontWeight: { lg: 700 },
                }}
                $colorText={!flipValues}
                appearance={appearance}
              >
                {weightValue}
              </BasicListItemStyles.Value>
            </Grid>
            <BasicListItemStyles.Value
              variant='caption'
              sx={{
                typography: { sm: 'numbers', lg: 'body1' },
                fontWeight: { lg: 700 },
              }}
              appearance={appearance}
              flipValues={flipValues}
            >
              {value}
            </BasicListItemStyles.Value>
          </Grid>
        </Grid>
      </Grid>
    </MuiListItem>
  );
};
