export const feedback = {
  title: 'Feedback',
  text: 'Give us feedback to help understand how you perceive the application and gives us insights on how we can improve',
  inputHelper: 'Please give us as much detail as possible',
  submitButton: 'Submit',
  modal: {
    title: 'Give us your feedback',
    text: 'This helps us understand how you perceive the application and gives us insights on how we can improve',
    submitButton: 'Give feedback',
    laterButton: 'Maybe later',
  },
  questions: [
    {
      question: 'Are you enjoying using this application?',
      questionId: 'ENJOY_APPLICATION',
      type: 'selectable',
      answers: [
        {
          text: 'Yes',
          id: 'ENJOY_APPLICATION_YES',
        },
        {
          text: 'No',
          id: 'ENJOY_APPLICATION_NO',
        },
      ],
    },
    {
      question: 'I value learning about my personal footprint estimate.',
      questionId: 'VALUE_PERSONAL_FOOTPRINT',
      type: 'selectable',
      answers: [
        {
          text: 'Strongly disagree',
          id: 'VALUE_PERSONAL_FOOTPRINT_SD',
        },
        {
          text: 'Disagree',
          id: 'VALUE_PERSONAL_FOOTPRINT_D',
        },
        {
          text: 'Neutral',
          id: 'VALUE_PERSONAL_FOOTPRINT_N',
        },
        {
          text: 'Agree',
          id: 'VALUE_PERSONAL_FOOTPRINT_A',
        },
        {
          text: 'Strongly agree',
          id: 'VALUE_PERSONAL_FOOTPRINT_SA',
        },
      ],
    },
    {
      question: 'I value the tips I receive to reduce my footprint estimate.',
      questionId: 'VALUE_TIPS_REDUCTION',
      type: 'selectable',
      answers: [
        {
          text: 'Strongly disagree',
          id: 'VALUE_TIPS_REDUCTION_SD',
        },
        {
          text: 'Disagree',
          id: 'VALUE_TIPS_REDUCTION_D',
        },
        {
          text: 'Neutral',
          id: 'VALUE_TIPS_REDUCTION_N',
        },
        {
          text: 'Agree',
          id: 'VALUE_TIPS_REDUCTION_A',
        },
        {
          text: 'Strongly agree',
          id: 'VALUE_TIPS_REDUCTION_SA',
        },
      ],
    },
    {
      question:
        'I find it easy to understand the footprint estimate calculations based on the explanations provided in the application.',
      questionId: 'UNDERSTAND_FOOTPRINT_CALC',
      type: 'selectable',
      answers: [
        {
          text: 'Strongly disagree',
          id: 'UNDERSTAND_FOOTPRINT_CALC_SD',
        },
        {
          text: 'Disagree',
          id: 'UNDERSTAND_FOOTPRINT_CALC_D',
        },
        {
          text: 'Neutral',
          id: 'UNDERSTAND_FOOTPRINT_CALC_N',
        },
        {
          text: 'Agree',
          id: 'UNDERSTAND_FOOTPRINT_CALC_A',
        },
        {
          text: 'Strongly agree',
          id: 'UNDERSTAND_FOOTPRINT_CALC_SA',
        },
      ],
    },
    {
      question: 'Overall, I value the new feature.',
      questionId: 'VALUE_FEATURE',
      type: 'selectable',
      answers: [
        {
          text: 'Strongly disagree',
          id: 'VALUE_FEATURE_SD',
        },
        {
          text: 'Disagree',
          id: 'VALUE_FEATURE_D',
        },
        {
          text: 'Neutral',
          id: 'VALUE_FEATURE_N',
        },
        {
          text: 'Agree',
          id: 'VALUE_FEATURE_A',
        },
        {
          text: 'Strongly agree',
          id: 'VALUE_FEATURE_SA',
        },
      ],
    },
    {
      question: 'Please tell us your additional thoughts / improvement ideas on this application.',
      questionId: 'IMPROVEMENT_IDEAS',
      type: 'free_text',
      answerId: 'IMPROVEMENT_IDEAS_TEXT',
    },
  ],
};
