import styled from 'styled-components';

const Wrapper = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    padding-bottom: 56px;
  }
`;

const InsightsWrapper = styled.div`
  padding: 30px 28px 26px 28px;
  background: linear-gradient(
    ${({ theme }) => `${theme.palette.common.white}`} 40%,
    ${({ theme }) => `${theme.palette.secondary.main}`} 0%
  );
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 0;
    background: none;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    max-width: 465px;
  }
`;

const ListWrapper = styled.div`
  width: 100%;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 350px;
    flex-grow: 1;
    > div {
      box-shadow: 0px 0px 12px ${({ theme }) => `${theme.palette.custom.boxShadow.color}`};
    }
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    max-width: 465px;
  }
`;

const FAQ = styled.div`
  padding-bottom: 32px;
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: center;
  background: ${({ theme }) => `${theme.palette.secondary.main}`};
  ${(props) => props.theme.breakpoints.up('md')} {
    padding-top: 32px;
    padding-bottom: 24px;
  }
`;

export const Report = {
  Wrapper,
  InsightsWrapper,
  ListWrapper,
  FAQ,
};
