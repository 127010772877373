import { RequestHandler } from 'msw';
import { setupWorker, SetupWorkerApi } from 'msw/browser';

import { Config } from 'App/config';

type WorkerService = {
  service: SetupWorkerApi | null;
  worker: SetupWorkerApi | null;
  isWorkerInit: () => boolean;
  shouldEnableWorker: () => boolean;
  start: () => void;
  addHandlers: (handlers: RequestHandler[]) => void;
};

export const workerService: WorkerService = {
  service: null,
  set worker(worker) {
    this.service = worker;
  },
  get worker() {
    return this.service;
  },
  isWorkerInit() {
    return this.worker !== null;
  },
  shouldEnableWorker() {
    return Config.getAll().REACT_APP_MOCK_API === 'true';
  },
  async start() {
    if (!this.shouldEnableWorker()) {
      return;
    }
    if (!this.isWorkerInit()) {
      this.worker = setupWorker() as SetupWorkerApi;
      this.worker.start();
    }
  },
  addHandlers(handlers: RequestHandler[]) {
    if (!this.shouldEnableWorker()) {
      return;
    }

    if (!this.isWorkerInit()) {
      throw new Error("can't add handlers");
    }
    (this.worker as SetupWorkerApi).use(...handlers);
  },
};
