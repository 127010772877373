import styled from 'styled-components';

const InfoButton = styled.button`
  cursor: pointer;
  padding: 0;
  background: transparent;
  border: 0;
  display: inline-flex;
`;

export const HowItWorksStyles = {
  InfoButton,
};
