// import { StylesProvider, MuiThemeProvider } from '@mui/material';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { ReactChild } from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';

import { useTranslation } from 'react-i18next';

import { GlobalStyles } from './GlobalStyles';

interface ThemeProviderProps {
  children: ReactChild;
  theme: any;
}

const StyledComponentsThemeProviderElement: any = StyledComponentsThemeProvider;
const GlobalStylesElement: any = GlobalStyles;

export const ThemeProvider = ({ children, theme }: ThemeProviderProps): JSX.Element => {
  const { i18n } = useTranslation();

  const direction = i18n.dir(i18n.language);
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={{ ...theme, direction }}>
        <StyledComponentsThemeProviderElement theme={theme}>
          <>
            <GlobalStylesElement />
            {children}
          </>
        </StyledComponentsThemeProviderElement>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};
