import { BackgroundWrapper, Button, ButtonAppearance, GoBackButton, Header, InnerContainer } from 'components';

import { Grid, Container, useMediaQuery } from '@mui/material';

import { useEffect, useState } from 'react';

import { Layout, BASIC_GRADIENT_BACKGROUND_HEIGHT } from 'App/components';

import { useTranslation } from 'react-i18next';

import { theme } from 'App/style';

import { useDeps, useUserContext, setSessionLogoutAction } from 'App/context';

import { submitStructuredFeedback } from '@ecolytiq/js-data-provider';

import { RoutePaths } from 'impactApp/routes';

import { handleUserLogout } from '../../../App/utils';

import { FeedbackPageStyles } from './FeedbackPage.styles';

import { Question, QuestionType, FeedbackAnswer } from './types';

import { FeedbackQuestion } from './FeedbackQuestion';

export const FeedbackPage = () => {
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const { t }: any = useTranslation();
  const { httpClientService } = useDeps();
  const [, dispatch] = useUserContext();

  const { HOME } = RoutePaths;
  const questions = t('feedback:questions', { returnObjects: true });
  const defaultAnswers = questions.map((question: Question) => ({
    answer_id:
      question.type === QuestionType.FREE_TEXT
        ? question.answerId
        : question.type === QuestionType.SELECTABLE && question.answers.length > 2
          ? question.answers[Math.floor(question.answers.length / 2)].id
          : '',
    ...(question.type === QuestionType.FREE_TEXT && { free_text: '' }),
  }));

  const [{ userId }] = useUserContext();

  const [answers, setAnswers] = useState(defaultAnswers);

  useEffect(() => {
    document.title = 'CO2 Feedback';
  });

  const onAnswerChange = (index: number, answer: String) => {
    const answersTemp = [...answers];
    if (questions[index].type === QuestionType.FREE_TEXT)
      answersTemp[index] = { ...answersTemp[index], free_text: answer };
    else answersTemp[index] = { answer_id: answer };

    setAnswers([...answersTemp]);
  };

  const onHandleSubmit = async () => {
    await Promise.all(
      answers.map(async (answer: FeedbackAnswer) => {
        try {
          if (answer.answer_id !== '') {
            const submitFeedback = submitStructuredFeedback(httpClientService, { account_id: userId, answer });
            await submitFeedback.post();
          }
        } catch (error) {
          console.error('Error submitting feedback:', error);
        }
      }),
    );
    dispatch(setSessionLogoutAction(true));
    handleUserLogout();
  };
  return (
    <FeedbackPageStyles.Container>
      <Layout
        bgHeight={BASIC_GRADIENT_BACKGROUND_HEIGHT}
        hideContainer
        header={
          <Header
            title={t('feedback:title')}
            transparent
            isWhite
            showDesktopNavigation
            button={<GoBackButton url={HOME} />}
          />
        }
      >
        <FeedbackPageStyles.Text
          variant='body2'
          sx={{ typography: { lg: 'subtitle2' }, color: { sm: 'white', lg: 'grey.600' } }}
        >
          {t('feedback:text')}
        </FeedbackPageStyles.Text>
        <BackgroundWrapper>
          <FeedbackPageStyles.Wrapper>
            <Container maxWidth='xl' disableGutters={!isMd}>
              <InnerContainer>
                <Grid
                  container
                  justifyContent='center'
                  alignItems='flex-start'
                  flexDirection={{ sm: 'column', md: 'row' }}
                >
                  <Grid item lg={8} px={0}>
                    {questions.map((question: Question, index: number) => (
                      <FeedbackQuestion index={index} question={question} onAnswerChange={onAnswerChange} />
                    ))}
                    <FeedbackPageStyles.ButtonWrapper>
                      <Button
                        text={t('feedback:submitButton')}
                        onClick={() => onHandleSubmit()}
                        appearance={ButtonAppearance.PRIMARY}
                      />
                    </FeedbackPageStyles.ButtonWrapper>
                  </Grid>
                </Grid>
              </InnerContainer>
            </Container>
          </FeedbackPageStyles.Wrapper>
        </BackgroundWrapper>
      </Layout>
    </FeedbackPageStyles.Container>
  );
};
