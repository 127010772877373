import { SVGProps } from 'react';
import styled, { css } from 'styled-components';

interface CloseIconProps extends SVGProps<SVGSVGElement> {
  hoverColor?: string;
}

const SVGElementWrapper = styled.div<{ $hoverColor?: string }>`
  svg {
    ${({ $hoverColor }) =>
      $hoverColor &&
      css`
        :hover {
          line {
            stroke: ${$hoverColor};
          }
        }
      `}
  }
`;

export const CloseIcon = (props: CloseIconProps): JSX.Element => {
  const { hoverColor, ...restOfProps } = props;
  return (
    <SVGElementWrapper $hoverColor={hoverColor}>
      <svg
        {...restOfProps}
        id='close-modal-icon'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <line
          x1='1.15'
          y1='-1.15'
          x2='18.2081'
          y2='-1.15'
          transform='matrix(0.708314 0.705897 -0.708315 0.705897 4 6)'
          stroke='#ffffff'
          strokeWidth='2.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <line
          x1='1.15'
          y1='-1.15'
          x2='18.2081'
          y2='-1.15'
          transform='matrix(-0.708314 0.705897 -0.708315 -0.705897 17.7119 4)'
          stroke='#ffffff'
          strokeWidth='2.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </SVGElementWrapper>
  );
};
