import { Grid } from '@mui/material';
import { handleOpenedOverlay } from 'App/utils';
import { ButtonAppearance, Button, Dialog } from 'components';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

type CloseModalProps = {
  onClose: () => void;
  onCancel?: () => void;
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  content: string;
  successButtonText: string;
  cancelButtonText?: string;
  slug: string;
};

export const Modal = ({
  isOpen,
  onClose,
  onCancel,
  setOpen,
  content,
  successButtonText,
  cancelButtonText,
  slug,
}: CloseModalProps) => {
  const location = useLocation();
  useEffect(() => {
    if (isOpen) handleOpenedOverlay(slug, location.pathname);
  }, [isOpen, slug, location.pathname]);
  return (
    <Dialog slug={slug} content={content} isOpen={isOpen} onClose={onClose}>
      <Grid container justifyContent='center' spacing={2}>
        <Grid item justifyContent='flex-end'>
          <Button
            appearance={ButtonAppearance.PRIMARY}
            isSmall
            onClick={() => setOpen(false)}
            text={successButtonText}
          />
        </Grid>
        {cancelButtonText && (
          <Grid item justifyContent='flex-start'>
            <Button isSmall appearance={ButtonAppearance.LIGHT} onClick={onCancel} text={cancelButtonText} />
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
};
