import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useUserContext } from 'App/context';

import { Grid } from '@mui/material';

import dompurify from 'dompurify';

import { useTranslation } from 'react-i18next';

import { Layout, BASIC_GRADIENT_BACKGROUND_HEIGHT, Modal } from 'App/components';

import { Button } from 'components';

import { color } from 'App/style';

import { Config } from 'App/config';

import { appConfig } from 'App/appConfig/appConfig';

import { RoutePaths } from 'impactApp/routes/routePaths';

import { CardIllustration } from './assets';

import { CardPageStyles } from './CardPage.styles';

export const CardPage = () => {
  const { t }: any = useTranslation();

  const [isModalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();

  const [{ userId }] = useUserContext();

  const { REACT_APP_MOCK_API, REACT_APP_ECOLYTIQ_IFRAME_BASEURL, REACT_APP_VISA_COMMUNITY_CODE } = Config.getAll();

  const { PROFILE_SETTINGS } = RoutePaths;

  const [errMsg, setErrMsg] = useState('');

  const visaURL = `${REACT_APP_ECOLYTIQ_IFRAME_BASEURL}${REACT_APP_VISA_COMMUNITY_CODE}?info={EUID:${REACT_APP_MOCK_API === 'true' ? `TEST2024021301` : userId}}`;

  const sanitizer = dompurify.sanitize;

  function parseData(input: any): any {
    const keyValuePairs = input.split(',');

    const data: any = {};
    for (const pair of keyValuePairs) {
      const [key, value] = pair.split(':');
      data[key.trim()] = value.trim();
    }
    return data;
  }

  useEffect(() => {
    document.title = 'CO2 Card';
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let infoParam = urlParams.get('info');
    if (infoParam) {
      infoParam = infoParam.substring(1, infoParam.length - 1);

      const data = parseData(infoParam);
      if (data?.SUCCESS === 'true') {
        navigate(PROFILE_SETTINGS);
      } else {
        const ERRMSG = data.ERRMSG;
        setErrMsg(decodeURIComponent(ERRMSG));
      }
    }
  }, []);

  return (
    <Layout bgHeight={BASIC_GRADIENT_BACKGROUND_HEIGHT} bgColor hideContainer isFullHeight>
      <CardPageStyles.CurveWrapper>
        <Grid
          container
          columns={{ sm: 5, md: 8, lg: 12 }}
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          flexWrap='nowrap'
        >
          <CardPageStyles.LogoWrapper>
            <img src={appConfig.logo} />
          </CardPageStyles.LogoWrapper>
          <CardPageStyles.CardSVGWrapper>
            <CardIllustration />
          </CardPageStyles.CardSVGWrapper>
          <CardPageStyles.Wrapper>
            <Grid item md={4} lg={3} minWidth={{ md: '460px' }} margin='0 auto'>
              <CardPageStyles.Text color='grey.600' variant='subtitle1'>
                {t('cardPage:text')}
              </CardPageStyles.Text>
              <CardPageStyles.CheckboxLabel color='grey.600' variant='overline'>
                <div dangerouslySetInnerHTML={{ __html: sanitizer(t('cardPage:ios:text')) }} />
              </CardPageStyles.CheckboxLabel>
              {errMsg && errMsg.length > 0 && (
                <CardPageStyles.Error color={color.highlightRed} variant='body1'>
                  {errMsg}
                </CardPageStyles.Error>
              )}
              <CardPageStyles.ButtonWrapperIOS>
                <Button onClick={() => setModalOpen(true)} text={t('cardPage:ios:buttonText')} />
              </CardPageStyles.ButtonWrapperIOS>
              <Modal
                slug='ios-visa-modal'
                setOpen={() => window.open(visaURL, '_self')}
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                content={t('cardPage:ios:modalText')}
                successButtonText={t('common:ok')}
              />
            </Grid>
          </CardPageStyles.Wrapper>
        </Grid>
      </CardPageStyles.CurveWrapper>
    </Layout>
  );
};
