import { useEffect, useState } from 'react';

import {
  useGetStaticPages,
  useQueryData,
  GetStaticPagesResponse,
  DEFAULT_OVERRIDE_CACHE_TIME,
} from '@ecolytiq/js-cms-provider';

import { StaticPagesData } from 'impactApp/modules/HomePage';

import { useDeps } from 'App/context';

export const useStaticPagesData = () => {
  const { strapiHttpClientService } = useDeps();

  const [response, setResponse] = useState<StaticPagesData>({
    staticPages: [],
  });

  const { staticPages } = response;

  const { data } = useQueryData<{ data: GetStaticPagesResponse }>(useGetStaticPages(strapiHttpClientService), {
    staleTime: DEFAULT_OVERRIDE_CACHE_TIME,
    cacheTime: DEFAULT_OVERRIDE_CACHE_TIME,
  });

  useEffect(() => {
    if (data?.data) {
      setResponse({
        staticPages: data.data.staticPages.data,
      });
    }
  }, [data, setResponse]);

  return {
    staticPages,
  };
};
