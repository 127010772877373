import { useState, useEffect, Fragment } from 'react';

import { Container } from '@mui/material';

import { ArrowLeftIcon, Button, ButtonAppearance } from 'components';

import { postAnswerOnboardingQuestions } from '@ecolytiq/js-data-provider';

import { handleFootPrintRefinement } from 'App/utils';

import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { RoutePaths } from 'impactApp/routes/routePaths';

import { answerType } from 'impactApp/modules';

import { useDeps } from 'App/context';

import { Question } from 'impactApp/modules/TransactionDetailsEdit/submodules/Questions/Question';

import { useCurrentAccountData } from '../../../impactApp/modules/FoodSettings/useCurrentAccountData';

import { ProfileSettingsSliderStyles } from './ProfileSettingsSlider.styles';

type ProfileSettingsSlideProps = {
  setStep: (step: number) => void;
};

export const ProfileSettingsSlider = ({ setStep }: ProfileSettingsSlideProps) => {
  const [content, setContent] = useState([[]]);
  const [questionStep, setQuestionStep] = useState(0);

  const [currentAnswers, setCurrentAnswers] = useState<answerType[]>([]);

  const navigate = useNavigate();

  const { httpClientService } = useDeps();

  const { HOME, PROFILE_SETTINGS } = RoutePaths;

  const { onboardingAnswers } = useCurrentAccountData();

  const { t }: any = useTranslation(['common']);

  useEffect(() => {
    setContent(t(`transactionDetails:feedbackLoop1`, { returnObjects: true }));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (onboardingAnswers && onboardingAnswers.length > 0) {
        setCurrentAnswers(onboardingAnswers);
      } else {
        const emptyAnswers: answerType[] = [];

        content?.forEach((element: any) => {
          emptyAnswers.push({ questionId: element?.questionId, answerId: '', input: null });
        });
        setCurrentAnswers(emptyAnswers);
      }
    };

    fetchData();
  }, [content, onboardingAnswers]);

  const handleSubmit = () => {
    navigate(HOME);
    if (currentAnswers.length > 0) {
      const postAnswers = postAnswerOnboardingQuestions(
        httpClientService,
        currentAnswers
          .map(({ questionId, ...rest }) => {
            return rest;
          })
          .filter((answerObject) => Object.keys(answerObject).length > 0),
      );

      postAnswers.post();

      handleFootPrintRefinement(PROFILE_SETTINGS, 'PROFILE');
    }
    setStep(3);
  };

  return (
    <Fragment>
      <ProfileSettingsSliderStyles.ArrowWrapper>
        <ArrowLeftIcon onClick={() => questionStep > 0 && setQuestionStep(questionStep - 1)} />
      </ProfileSettingsSliderStyles.ArrowWrapper>
      <ProfileSettingsSliderStyles.Container>
        <Container>
          <ProfileSettingsSliderStyles.Text variant='subtitle3'>
            {t('profileSettings:slider')}
          </ProfileSettingsSliderStyles.Text>
          <ProfileSettingsSliderStyles.Wrapper>
            {content[questionStep]?.map((element: any, i, arr) => (
              <Fragment key={element.title}>
                <Question
                  question={element?.title}
                  id={element?.questionId}
                  questionType={element?.type}
                  answers={element?.answers}
                  oldAnswers={currentAnswers}
                  setAnswers={setCurrentAnswers}
                  content={content}
                  info={element?.info}
                />
                {arr.length - 1 !== i && <ProfileSettingsSliderStyles.Divider />}
              </Fragment>
            ))}

            <ProfileSettingsSliderStyles.ButtonWrapper>
              <Button
                appearance={ButtonAppearance.PRIMARY}
                text={questionStep === 2 ? t('profileSettings:submitButton') : t('profileSettings:nextButton')}
                onClick={() => (questionStep === 2 ? handleSubmit() : setQuestionStep(questionStep + 1))}
              />
            </ProfileSettingsSliderStyles.ButtonWrapper>
          </ProfileSettingsSliderStyles.Wrapper>
        </Container>
      </ProfileSettingsSliderStyles.Container>
    </Fragment>
  );
};
