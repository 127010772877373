import { PropsWithChildren } from 'react';

import { Typography, List as MuiList } from '@mui/material';

import { CircularLoading, Grid, Paper } from 'components';

import { HighlightedColor } from 'App/types';

import { useTranslation } from 'react-i18next';

import { PaperWithListStyles } from './PaperWithList.styles';

type PaperWithListProps = {
  appearance: HighlightedColor;
  isLoading: boolean;
  title?: string;
  subtitle?: string;
  list: Array<JSX.Element>;
};

export const PaperWithList = ({
  appearance,
  list,
  subtitle,
  title,
  isLoading,
}: PropsWithChildren<PaperWithListProps>) => {
  const isEmptyList = list.length === 0;
  const { t } = useTranslation();
  return (
    <PaperWithListStyles.Wrapper as={Paper.RoundedLightBackground}>
      <Grid flexDirection='column' spacingInside={{ left: 6, right: 6 }}>
        {(title || subtitle) && (
          <PaperWithListStyles.TitleWrapper>
            {title && (
              <PaperWithListStyles.Title appearance={appearance} variant='subtitle2'>
                {title}
              </PaperWithListStyles.Title>
            )}
            {subtitle && (
              <PaperWithListStyles.Subtitle
                id='card-list-subtitle'
                variant='caption'
                sx={{ typography: { sm: 'caption', lg: 'subtitle3' } }}
              >
                {subtitle}
              </PaperWithListStyles.Subtitle>
            )}
          </PaperWithListStyles.TitleWrapper>
        )}
      </Grid>
      <PaperWithListStyles.ListWrapper>
        {!isLoading && isEmptyList ? (
          <PaperWithListStyles.Content alignItems='center' flexDirection='column' justifyContent='center'>
            <Typography align='center' color='white' variant='subtitle1'>
              {t('common:noData')}
            </Typography>
          </PaperWithListStyles.Content>
        ) : (
          <MuiList id='card-list'>
            {isLoading ? (
              <Grid alignItems='center' minHeight='80px' width='100%'>
                <CircularLoading />
              </Grid>
            ) : (
              list
            )}
          </MuiList>
        )}
      </PaperWithListStyles.ListWrapper>
    </PaperWithListStyles.Wrapper>
  );
};
