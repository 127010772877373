import { useEffect, useState } from 'react';

import {
  useQueryData,
  DEFAULT_OVERRIDE_CACHE_TIME,
  useGetStaticPage,
  GetStaticPageResponse,
} from '@ecolytiq/js-cms-provider';

import { StaticPageData } from 'impactApp/modules/HomePage';

import { useDeps } from 'App/context';

export const useStaticPageDetailsData = (id: string) => {
  const { strapiHttpClientService } = useDeps();

  const [response, setResponse] = useState<StaticPageData>({
    staticPage: {},
  });
  const { staticPage } = response;
  const { data } = useQueryData<{ data: GetStaticPageResponse }>(useGetStaticPage(strapiHttpClientService, id), {
    staleTime: DEFAULT_OVERRIDE_CACHE_TIME,
    cacheTime: DEFAULT_OVERRIDE_CACHE_TIME,
  });

  useEffect(() => {
    if (data?.data) {
      setResponse({
        staticPage: data.data.staticPage.data,
      });
    }
  }, [data, setResponse]);

  return {
    staticPage,
  };
};
