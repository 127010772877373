import { Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  background: ${({ theme }) => `${theme.palette.common.white}`};
  height: 65px;
  display: flex;
  align-items: center;
  padding: 0 50px;
  justify-content: space-between;
  box-shadow: 0px 4px 8px 0px ${({ theme }) => theme.palette.custom.boxShadow.color};
`;

const Item = css`
  margin-left: 32px;
  color: ${({ theme }) => `${theme.palette.grey[500]}`};
  font-weight: 700;
  text-decoration: none;

  &:hover,
  &.active {
    color: ${({ theme }) => `${theme.palette.primary.main}`};
  }
`;

const PlainLink = styled.span`
  ${Item}
  cursor: pointer;
`;

const LinkItem = styled(NavLink)`
  ${Item}
`;

const Title = styled(Typography)`
  color: ${({ theme }) => `${theme.palette.primary.main}`};
  margin-left: 40px;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LinksWrapper = styled.div`
  display: flex;
`;

export const NavbarStyles = {
  Wrapper,
  LinkItem,
  Item,
  PlainLink,
  Title,
  LogoWrapper,
  LinksWrapper,
};
