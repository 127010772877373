import { Layout } from 'App/components';

import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { ButtonAppearance } from 'components';

import dompurify from 'dompurify';

import { RoutePaths } from 'impactApp/routes';

import { AccountConfirmationStyles } from './AccountConfirmation.styles';

export const AccountConfirmation = () => {
  const { t }: any = useTranslation();
  const sanitizer = dompurify.sanitize;
  const navigate = useNavigate();
  const { HOME } = RoutePaths;
  return (
    <Layout bgColor isFullHeight>
      <AccountConfirmationStyles.Wrapper>
        <AccountConfirmationStyles.Icon />
        <AccountConfirmationStyles.Title
          variant='h6'
          dangerouslySetInnerHTML={{
            __html: sanitizer(t('accountConfirmation:title')),
          }}
        />

        <AccountConfirmationStyles.TextWrapper>{t('accountConfirmation:text')}</AccountConfirmationStyles.TextWrapper>
        <AccountConfirmationStyles.ButtonWrapper
          appearance={ButtonAppearance.SECONDARY}
          onClick={() => navigate(HOME)}
          text={t('accountConfirmation:button')}
        />
      </AccountConfirmationStyles.Wrapper>
    </Layout>
  );
};
