import { PaperWithList } from 'components';

import { AccordionAppearance, useAccordion } from 'components/Accordion';

import { HighlightedColor } from 'App/types';

import { FAQStyles } from './FAQ.styles';

import { useFaqsData } from './useFaqsData';

export const FAQ = () => {
  const { BLUE } = HighlightedColor;

  const data = useFaqsData();

  const list = data.faqs.map((item) => ({
    title: item.attributes.title,
    body: item.attributes.description,
  }));

  const accordionList = useAccordion(list, AccordionAppearance.LIGHT, true);

  return (
    <FAQStyles>
      <PaperWithList appearance={BLUE} isLoading={false} list={accordionList} />
    </FAQStyles>
  );
};
