export const CheckIcon = () => (
  <svg width='33' height='32' viewBox='0 0 33 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.16699 16L14.043 21.0014L24.5003 11.3334'
      stroke='#5FCB89'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.8335 16C1.8335 19.8899 3.37873 23.6204 6.12926 26.3709C8.8798 29.1215 12.6103 30.6667 16.5002 30.6667C20.39 30.6667 24.1205 29.1215 26.8711 26.3709C29.6216 23.6204 31.1668 19.8899 31.1668 16C31.1668 12.1102 29.6216 8.37967 26.8711 5.62914C24.1205 2.87861 20.39 1.33337 16.5002 1.33337C12.6103 1.33337 8.8798 2.87861 6.12926 5.62914C3.37873 8.37967 1.8335 12.1102 1.8335 16V16Z'
      stroke='#5FCB89'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
