import styled from 'styled-components';
import { Typography } from '@mui/material';

const Title = styled(Typography)`
  margin-bottom: 20px;
  text-align: left;
`;

const Text = styled(Typography)`
  margin-bottom: 16px;
`;

const Undertext = styled(Typography)`
  margin-top: 16px;
  text-transform: none;
`;

const Error = styled(Typography)`
  margin-top: 12px;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 24px;
`;

const CurveWrapper = styled.div`
  height: 100%;
  min-height: calc(100vh - 68px);
  display: flex;
  flex-grow: 1;
`;

const ImgWrapper = styled.div`
  max-width: 320px;
  position: relative;
  img {
    width: 100%;
    margin-bottom: -6px;
  }
`;

const Item = styled(Typography)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  &:before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_4276_30092)"><path d="M6.5 12L10.157 15.751L18 8.5" stroke="%230414A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M1 12C1 14.9174 2.15893 17.7153 4.22183 19.7782C6.28473 21.8411 9.08262 23 12 23C14.9174 23 17.7153 21.8411 19.7782 19.7782C21.8411 17.7153 23 14.9174 23 12C23 9.08262 21.8411 6.28473 19.7782 4.22183C17.7153 2.15893 14.9174 1 12 1C9.08262 1 6.28473 2.15893 4.22183 4.22183C2.15893 6.28473 1 9.08262 1 12V12Z" stroke="%230414A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_4276_30092"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>');
    flex-shrink: 0;
  }
`;

const Wrapper = styled.div`
  background: ${({
    theme: {
      palette: {
        common: { white },
      },
    },
  }) => white};
  color: ${({
    theme: {
      palette: { grey },
    },
  }) => grey[600]};
  padding: 20px 24px 36px;
  height: 100%;
  width: 100%;
  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 52px 0 56px;
  }
`;

const LogoWrapper = styled.div`
  padding: 42px 0 46px;
  width: 216px;
  img {
    width: 100%;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 64px 0 60px;
    width: 280px;
  }
`;

const ListWrapper = styled.div`
  margin-bottom: 48px;
  width: 100%;
`;

const ButtonWrapperIOS = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: center;
`;

const CardSVGWrapper = styled.div`
  width: 278px;
  height: 175px;
  margin-bottom: 20px;
`;

const CheckboxLabel = styled(Typography)`
  display: flex;
  gap: 8px;
  text-transform: none;
  &:before {
    content: '';
    flex-shrink: 0;
    background: url('data:image/svg+xml,<svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.75 6.25H1.875C1.39175 6.25 1 6.64177 1 7.125V13.25C1 13.7332 1.39175 14.125 1.875 14.125H9.75C10.2332 14.125 10.625 13.7332 10.625 13.25V7.125C10.625 6.64177 10.2332 6.25 9.75 6.25Z" stroke="%231F242F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M2.75 6.25V4.0625C2.75 3.25027 3.07265 2.47132 3.64699 1.89699C4.22132 1.32265 5.00027 1 5.8125 1C6.62473 1 7.40366 1.32265 7.97801 1.89699C8.55236 2.47132 8.875 3.25027 8.875 4.0625V6.25" stroke="%231F242F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.8125 10.1875C5.69169 10.1875 5.59375 10.0896 5.59375 9.96875C5.59375 9.84794 5.69169 9.75 5.8125 9.75" stroke="%231F242F" stroke-width="1.5"/><path d="M5.8125 10.1875C5.93331 10.1875 6.03125 10.0896 6.03125 9.96875C6.03125 9.84794 5.93331 9.75 5.8125 9.75" stroke="%231F242F" stroke-width="1.5"/></svg>');
    background-repeat: no-repeat;
    width: 12px;
    height: 16px;
  }
`;

const IFrame = styled.iframe`
  margin-top: 16px;
  border: 0;
  height: 300px;
`;

export const CardPageStyles = {
  Title,
  ButtonWrapper,
  CurveWrapper,
  ImgWrapper,
  Item,
  Wrapper,
  LogoWrapper,
  ListWrapper,
  CardSVGWrapper,
  Text,
  CheckboxLabel,
  IFrame,
  Error,
  Undertext,
  ButtonWrapperIOS,
};
