import styled from 'styled-components';
import { Typography } from '@mui/material';
import { CheckOutlineIcon } from 'components/Icons/CheckOutlineIcon';
import { Button } from 'components';

const Icon = styled(CheckOutlineIcon)`
  margin: 0 auto 20px;
  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-bottom: 40px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 32px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    max-width: 444px;
    align-items: center;
    margin: 0 auto;
    text-align: center;
  }
`;

const LogoWrapper = styled.div`
  padding: 56px 0 64px;
  width: 216px;
  margin: 0 auto;
  img {
    width: 100%;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 60px 0 64px;
    width: 280px;
  }
`;

const Title = styled(Typography)`
  color: ${({ theme }) => `${theme.palette.common.white}`};
  margin-bottom: 20px;
  text-align: center;
  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-bottom: 30px;
  }
`;

const TextWrapper = styled(Typography)`
  color: ${({ theme }) => `${theme.palette.common.white}`};
  margin-bottom: 50px;
  text-align: center;
`;

const ButtonWrapper = styled(Button)`
  margin: 20px auto 0;
`;

export const LogoutStyles = {
  Wrapper,
  Icon,
  Title,
  TextWrapper,
  ButtonWrapper,
  LogoWrapper,
};
