import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';

import { Grid } from 'components';
import { HighlightedColor } from 'App/types';

const Ellipsis = css`
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Wrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.palette.grey[600]};

  ${(props) => props.theme.breakpoints.up('lg')} {
    box-shadow: 0px 4px 8px ${({ theme }) => theme.palette.custom.boxShadow.color};
    background: ${({ theme }) => theme.palette.primary.main};
  }
`;

const TitleWrapper = styled.div`
  padding: 24px 24px 8px;
  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 36px 52px 28px;
  }
`;

const Title = styled(Typography)<{ appearance: HighlightedColor }>`
  margin-bottom: 4px;

  ${Ellipsis};
  color: ${({
    theme: {
      palette: {
        common: { white },
      },
    },
  }) => white};
`;

const Subtitle = styled(Typography)`
  color: ${({
    theme: {
      palette: {
        common: { white },
      },
    },
  }) => white};
`;

const ListWrapper = styled.div`
  padding: 0 4px 24px;
  .MuiList-padding {
    padding: 0;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 0 28px 44px 32px;
  }
`;

const Content = styled(Grid)`
  height: 150px;
  margin: 20px 0px;
  color: ${({
    theme: {
      palette: {
        common: { white },
      },
    },
  }) => white};
  white-space: pre-wrap;
`;

export const PaperWithListStyles = {
  Content,
  ListWrapper,
  Subtitle,
  Title,
  Wrapper,
  TitleWrapper,
};
