import styled from 'styled-components';

export const BackgroundWrapperStyles = styled.div`
  flex: 1;
  box-shadow: 0px -12px 24px 0px ${({ theme }) => theme.palette.custom.boxShadow.color};
  border-top-left-radius: ${({
    theme: {
      palette: {
        custom: {
          radius: { radiusSmall },
        },
      },
    },
  }) => radiusSmall};
  border-top-right-radius: ${({
    theme: {
      palette: {
        custom: {
          radius: { radiusSmall },
        },
      },
    },
  }) => radiusSmall};
  overflow: hidden;
  ${(props) => props.theme.breakpoints.up('md')} {
    background: ${({
      theme: {
        palette: {
          secondary: { main },
        },
      },
    }) => main};

    box-shadow: 0px 0px 24px 0px
      ${({
        theme: {
          palette: {
            custom: {
              boxShadow: { color },
            },
          },
        },
      }) => color};
  }
`;
