import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Question, QuestionType } from '../types';

import { FeedbackPageStyles } from './FeedbackQuestion.styles';

type FeedbackQuestionProps = {
  index: number;
  question: Question;
  onAnswerChange: (index: number, answer: string) => void;
};

export const FeedbackQuestion = ({ index, question, onAnswerChange }: FeedbackQuestionProps) => {
  const [answer, setAnswer] = useState('');
  const { t } = useTranslation();
  const handleAnswer = (value: string) => {
    setAnswer(value);
    onAnswerChange(index, value);
  };

  const sliderHandler = (event: Event, sliderIndex: any) => {
    setAnswer(sliderIndex);
    if (question.type === QuestionType.SELECTABLE) onAnswerChange(index, question.answers[sliderIndex].id);
  };
  const number = index + 1;

  return (
    <>
      <FeedbackPageStyles.Question
        variant='body2'
        color='grey.600'
      >{`${number}. ${question.question}`}</FeedbackPageStyles.Question>
      {question.type === QuestionType.FREE_TEXT ? (
        <>
          <FeedbackPageStyles.Input
            value={answer}
            placeholder={t('feedback:title') as string}
            onChange={(e) => handleAnswer(e.target.value)}
          />
          <FeedbackPageStyles.InputLabel variant='caption' color='grey.500'>
            {t('feedback:inputHelper')}
          </FeedbackPageStyles.InputLabel>
        </>
      ) : question.answers.length === 2 ? (
        <div>
          <FeedbackPageStyles.RadioButtonWrapper variant='subtitle3' color='grey.600'>
            <FeedbackPageStyles.RadioButton
              checked={answer === question.answers[0].id}
              type='radio'
              onClick={() => handleAnswer(question.answers[0].id)}
            />
            {question.answers[0].text}
          </FeedbackPageStyles.RadioButtonWrapper>
          <FeedbackPageStyles.RadioButtonWrapper variant='subtitle3' color='grey.600'>
            <FeedbackPageStyles.RadioButton
              checked={answer === question.answers[1].id}
              type='radio'
              onClick={() => handleAnswer(question.answers[1].id)}
            />
            {question.answers[1].text}
          </FeedbackPageStyles.RadioButtonWrapper>
        </div>
      ) : (
        <FeedbackPageStyles.Slider
          onChange={sliderHandler}
          valueLabelDisplay='on'
          valueLabelFormat={(value) => question.answers[value].text}
          defaultValue={2}
          marks={[
            {
              value: 0,
              label: question.answers[0].text,
            },
            {
              value: 4,
              label: question.answers[question.answers.length - 1].text,
            },
          ]}
          min={0}
          max={4}
        />
      )}
    </>
  );
};
