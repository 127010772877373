import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface EconomyClassProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const EconomyClass = ({ props, isActive }: EconomyClassProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} width='50' height='51' viewBox='0 0 50 51' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_7253_17301)'>
        <path
          d='M23.9262 18.5602C25.4944 18.5602 26.7655 17.289 26.7655 15.7209C26.7655 14.1528 25.4944 12.8816 23.9262 12.8816C22.3581 12.8816 21.0869 14.1528 21.0869 15.7209C21.0869 17.289 22.3581 18.5602 23.9262 18.5602Z'
          stroke={isActiveState ? '#ffffff' : main}
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M24.8706 29.8436H29.6073C31.2253 29.8436 32.537 31.1553 32.537 32.7733V36.8654C32.537 37.7716 31.8024 38.5062 30.8962 38.5062C29.9899 38.5062 29.2553 37.7716 29.2553 36.8654V33.2486H22.7923C22.3404 33.2486 21.9071 33.0691 21.5876 32.7496C21.2681 32.4302 21.0887 31.9968 21.0887 31.5451C21.0778 28.5917 21.1216 27.1168 21.1216 24.1596C21.1216 22.3907 22.3734 21.3874 23.4823 21.3875C24.6924 21.3875 25.9174 22.4489 25.9174 24.1596V26.4947H29.6405C31.9704 26.4947 31.8983 29.8436 29.4964 29.8436'
          stroke={isActiveState ? '#ffffff' : main}
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M17.4634 20.9403V35.2902C17.4634 36.5847 18.5127 37.634 19.8071 37.634H26.1583'
          stroke={isActiveState ? '#ffffff' : main}
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_7253_17301'>
          <rect width='30' height='30' fill='white' transform='translate(10 10.6939)' />
        </clipPath>
      </defs>
    </svg>
  );
};
