import { useEffect, useState } from 'react';
import {
  useGetReportingsFootprints,
  useQueryData,
  useGetNationalAverage,
  GetNationalAverageResponse,
  GetFootprintsResponse,
  DEFAULT_OVERRIDE_CACHE_TIME,
} from '@ecolytiq/js-data-provider';
import { INIT_MONTH_DELTA } from 'App/context/user/User.reducer';

import { useDeps } from 'App/context';
import { subMonths } from 'date-fns';
import { Months } from 'App/types';
import { getCurrentMonth } from 'App/utils';
import { FootprintChartData } from 'impactApp/modules/HomePage';

import { CURRENT_DATE, MONTHS_TO_DISPLAY } from 'App/components';
import { RoutePaths } from 'impactApp/routes';
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';

const parseData = ({
  totalCo2ByRange,
  totalCo2ByYear,
}: GetFootprintsResponse): Omit<FootprintChartData, 'isLoading'> => {
  let calculatedAnnualFootprint = 0;

  let parsedFootprints = Object.values(totalCo2ByRange).map((footprintsByMonth, index: number) => {
    const {
      co2Footprint: { value },
    } = footprintsByMonth;
    if (index !== 0) calculatedAnnualFootprint += value;
    return {
      date: i18next.t(`${Months[getCurrentMonth(13 - index, CURRENT_DATE)]}`),
      value,
      monthDelta: 12 - index + INIT_MONTH_DELTA,
    };
  });

  parsedFootprints = parsedFootprints.reverse();

  return {
    annualFootprint: calculatedAnnualFootprint,
    averageFootprint: 0,
    footprints: [parsedFootprints.slice(0, MONTHS_TO_DISPLAY), parsedFootprints.slice(MONTHS_TO_DISPLAY)],
  };
};

export const useFootprintChartData = (): FootprintChartData => {
  const { httpClientService } = useDeps();
  const [nationalAverage, setNationalAverage] = useState(0);
  const [chartData, setChartData] = useState<Omit<FootprintChartData, 'isLoading'>>({
    annualFootprint: 0,
    averageFootprint: 0,
    footprints: [[], []],
  });
  const navigate = useNavigate();
  const { SSO_PAGE, PROFILE_SETTINGS } = RoutePaths;
  const { annualFootprint, averageFootprint, footprints } = chartData;
  const date = subMonths(CURRENT_DATE, INIT_MONTH_DELTA);
  const getNationalAverage = useGetNationalAverage(httpClientService, 'GB');
  const { isLoading, data } = useQueryData<{ data: GetFootprintsResponse }>(
    useGetReportingsFootprints(
      httpClientService,

      {
        month: date.getMonth() + 1,
        year: date.getFullYear() - 1,
      },
      {
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      },
    ),
    {
      staleTime: DEFAULT_OVERRIDE_CACHE_TIME,
      cacheTime: 0,
    },
  );
  useEffect(() => {
    const fetchNationalAverage = async () => {
      try {
        const nationalAverageData = (await getNationalAverage.get()) as GetNationalAverageResponse;
        setNationalAverage(nationalAverageData.national_average_co2_footprint_in_g);
      } catch (error) {
        console.error('Error fetching national average:', error);
      }
    };

    if (data?.data) {
      if (data.data.totalCo2ByYear.value === 0) {
        navigate(`${PROFILE_SETTINGS}?step=3`);
      } else {
        fetchNationalAverage();
        setChartData((prev) => ({
          ...parseData(data.data),
          averageFootprint: nationalAverage || prev.averageFootprint,
        }));
      }
    } else if (!isLoading && !data) {
      navigate(SSO_PAGE);
    }
  }, [data, isLoading, nationalAverage, navigate]);

  return {
    annualFootprint,
    averageFootprint,
    footprints,
    isLoading,
  };
};
