import { Grid, Typography } from '@mui/material';

import { ButtonAppearance, Button, Checkbox, InfoIcon } from 'components';

import { useState } from 'react';

import { useLocation } from 'react-router-dom';

import { handleInfoIcon } from 'App/utils';

import dompurify from 'dompurify';

import { appConfig } from 'App/appConfig/appConfig';

import { useCookies } from 'react-cookie';

import { ModalStyles } from 'components/Dialog/Dialog.styles';

import { useTranslation } from 'react-i18next';

import { Modal } from '../Modal';

type CookiesModalProps = {
  isOpen: boolean;
  setIsOpen: (e: boolean) => void;
};

export const CookiesModal = ({ isOpen, setIsOpen }: CookiesModalProps) => {
  const { REACT_APP_NECESSARY_COOKIE_NAME, REACT_APP_ANALYTIC_COOKIE_NAME } = appConfig;

  const cookies = [REACT_APP_NECESSARY_COOKIE_NAME, REACT_APP_ANALYTIC_COOKIE_NAME];

  const { t }: any = useTranslation();

  const cookiesObjectTemp: {
    [type: string]: {
      title: string;
      modalText: string;
    };
  } = t('common:cookies:cookiesObject', { returnObjects: true });
  const sanitizer = dompurify.sanitize;

  const [cookiesState, setCookiesState] = useState({
    [REACT_APP_NECESSARY_COOKIE_NAME]: true,
    [REACT_APP_ANALYTIC_COOKIE_NAME]: false,
  });

  const [, setCookie] = useCookies();
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const location = useLocation();

  const modalHandler = (content: string) => {
    setModalOpen(true);
    setModalContent(content);
  };

  const stateHandler = (cookieName: string) => {
    const temp = { ...cookiesState };
    temp[cookieName] = !cookiesState[cookieName];
    setCookiesState({ ...temp });
  };

  const generateCheckboxes = cookies.map((cookie) => (
    <ModalStyles.CheckboxWrapper key={cookiesObjectTemp[cookie].title}>
      <Checkbox
        disabled={cookie === REACT_APP_NECESSARY_COOKIE_NAME}
        checked={cookiesState[cookie]}
        onClick={() => stateHandler(cookie)}
      />
      <ModalStyles.CheckboxLabel>{cookiesObjectTemp[cookie].title}</ModalStyles.CheckboxLabel>
      <ModalStyles.InfoWrapper
        onClick={() => {
          modalHandler(cookiesObjectTemp[cookie].modalText);
          handleInfoIcon(location.pathname, cookiesObjectTemp[cookie].title);
        }}
      >
        <InfoIcon color={appConfig.theme.palette.primary.main} />
      </ModalStyles.InfoWrapper>
    </ModalStyles.CheckboxWrapper>
  ));

  const onAccept = () => {
    setCookie(REACT_APP_NECESSARY_COOKIE_NAME, 'true');
    setCookie(REACT_APP_ANALYTIC_COOKIE_NAME, cookiesState[REACT_APP_ANALYTIC_COOKIE_NAME]);
    setIsOpen(false);
  };

  const onAcceptAll = () => {
    setCookie(REACT_APP_NECESSARY_COOKIE_NAME, 'true');
    setCookie(REACT_APP_ANALYTIC_COOKIE_NAME, 'true');
    setIsOpen(false);
  };

  /* eslint-disable react/no-danger */
  return (
    <>
      <ModalStyles.Dialog open={isOpen}>
        <ModalStyles.Content $hasLightPadding id='cookies-dialog-content'>
          <ModalStyles.Title variant='subtitle1'>{t('common:cookies:title')}</ModalStyles.Title>
          <Typography color='primary.dark' variant='body1'>
            <span
              dangerouslySetInnerHTML={{
                __html: sanitizer(t('common:cookies:text'), { ADD_ATTR: ['target'] }),
              }}
            />
          </Typography>
          <div>{generateCheckboxes}</div>
          <ModalStyles.Bottom>
            <Grid container justifyContent='center' spacing={2}>
              <Grid item justifyContent='flex-end'>
                <Button
                  isSmall
                  appearance={ButtonAppearance.LIGHT}
                  onClick={onAcceptAll}
                  text={t('common:cookies:acceptAll')}
                  id='cookies-dialog-accept'
                />
              </Grid>
              <Grid item justifyContent='flex-start'>
                <Button
                  isSmall
                  onClick={onAccept}
                  appearance={ButtonAppearance.LIGHT}
                  text={t('common:cookies:acceptSelection')}
                />
              </Grid>
            </Grid>
          </ModalStyles.Bottom>
        </ModalStyles.Content>
      </ModalStyles.Dialog>
      <Modal
        setOpen={setModalOpen}
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        content={modalContent}
        successButtonText={t('common:ok')}
        slug='cookies-modal'
      />
    </>
  );
};
