import { createContext, Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';

interface FeedbackI {
  isFeedbackModalVisible: boolean;
  setIsFeedbackModalVisible?: Dispatch<SetStateAction<boolean>>;
}

const defaultState = {
  isFeedbackModalVisible: false,
};

export const FeedbackContext = createContext<FeedbackI>(defaultState);

export const FeedbackProvider = ({ children }: PropsWithChildren<Record<string, any>>) => {
  const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(defaultState.isFeedbackModalVisible);

  return (
    <FeedbackContext.Provider value={{ isFeedbackModalVisible, setIsFeedbackModalVisible }}>
      {children}
    </FeedbackContext.Provider>
  );
};
