import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface LPGProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const LPG = ({ props, isActive }: LPGProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M23.9253 22.3633C24.3856 22.9132 24.666 23.5911 24.7289 24.3055C24.7918 25.0199 24.634 25.7363 24.2769 26.3582C23.9613 26.3826 23.6482 26.2861 23.401 26.0883C23.1538 25.8906 22.9911 25.6062 22.9456 25.293C22.4738 25.5519 22.0857 25.94 21.8269 26.4119C21.568 26.8837 21.4492 27.4196 21.4843 27.9566C21.4843 28.8042 21.821 29.617 22.4203 30.2163C23.0196 30.8157 23.8325 31.1523 24.68 31.1523C25.5241 31.1408 26.3304 30.8005 26.9274 30.2037C27.5244 29.6069 27.8651 28.8007 27.8769 27.9566C27.9836 26.708 27.6456 25.4619 26.9225 24.4384C26.1994 23.4149 25.1378 22.6799 23.9253 22.3633Z'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M19.7266 18.8477H30.2734C30.2734 18.8477 33.7891 18.8477 33.7891 22.3633V31.1523C33.7891 31.1523 33.7891 34.668 30.2734 34.668H19.7266C19.7266 34.668 16.2109 34.668 16.2109 31.1523V22.3633C16.2109 22.3633 16.2109 18.8477 19.7266 18.8477Z'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M33.7891 38.1836H16.2109C16.3924 37.2198 16.8967 36.3465 17.6407 35.7075C18.3847 35.0685 19.3241 34.7019 20.3043 34.668H29.6957C30.6759 34.7019 31.6153 35.0685 32.3593 35.7075C33.1033 36.3465 33.6076 37.2198 33.7891 38.1836Z'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M19.7266 11.8164H30.2734V18.8477H19.7266V11.8164Z'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M17.9688 11.8164H32.0312'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M25 18.8477V15.332'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
