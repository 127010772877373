import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface NoIconProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const NoIcon = ({ props, isActive }: NoIconProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} width='41' height='40' viewBox='0 0 41 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <line
        x1='1.15'
        y1='-1.15'
        x2='18.2081'
        y2='-1.15'
        transform='matrix(0.708314 0.705897 -0.708315 0.705897 13.5 14)'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='2.3'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <line
        x1='1.15'
        y1='-1.15'
        x2='18.2081'
        y2='-1.15'
        transform='matrix(-0.708314 0.705897 -0.708315 -0.705897 27.2119 12)'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='2.3'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
