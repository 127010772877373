import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import styled from 'styled-components';

import { Grid } from 'components';
import { HighlightedColor } from 'App/types';

const DetailsIcon = styled(Grid)<{ $color: string; flipValues?: boolean }>`
  svg {
    color: ${({ theme, $color, flipValues }) =>
      flipValues && $color === HighlightedColor.BLACK
        ? theme.palette.custom.list.highlightedData[HighlightedColor.BLUE]
        : $color};
    width: 18px;
    height: 18px;
  }
`;

const Value = styled(Typography)<{
  $colorText?: boolean;
  flipValues?: boolean;
  appearance: HighlightedColor;
}>`
  margin-left: 4px;
  color: ${({
    theme: {
      palette: {
        custom: {
          list: { highlightedData, darkText },
        },
      },
    },
    $colorText,
    appearance,
    flipValues,
  }) => {
    if ($colorText) return highlightedData[appearance];
    if (appearance === HighlightedColor.BLACK && flipValues) return highlightedData[HighlightedColor.BLACK];
    if (appearance === HighlightedColor.BLACK) return highlightedData[HighlightedColor.BLUE];
    if (appearance === HighlightedColor.WHITE) return highlightedData[appearance];
    return darkText;
  }};
`;

const Information = styled(Typography)<{
  appearance?: HighlightedColor;
}>`
  margin-top: 4px;
  text-align: left;
  color: ${({
    theme: {
      palette: {
        custom: {
          list: { highlightedData },
        },
      },
    },
    appearance,
  }) => appearance && highlightedData[appearance]};

  white-space: nowrap;
`;

const Title = styled(Typography)<{
  appearance: HighlightedColor;
}>`
  text-transform: capitalize;
  text-align: left;
  color: ${({
    theme: {
      palette: {
        custom: {
          list: { highlightedData },
        },
      },
    },
    appearance,
  }) => highlightedData[appearance]};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({
    theme: {
      palette: {
        common: { white },
      },
    },
  }) => white};
`;

export const BasicListItemStyles = {
  DetailsIcon,
  Information,
  Link: StyledLink,
  Title,
  Value,
};
