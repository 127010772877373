import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface FoodVegetarianProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const FoodVegetarian = ({ props, isActive }: FoodVegetarianProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} width='51' height='50' viewBox='0 0 51 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_7253_22875)'>
        <path
          d='M35.0383 23.1037C34.3823 21.3662 33.3035 19.8195 31.8996 18.6037C30.6838 17.1996 29.1372 16.1208 27.3996 15.4649C26.5309 15.1647 25.5821 15.1948 24.7342 15.5493C23.8862 15.9039 23.1985 16.5582 22.8021 17.3874C22.8021 17.3874 14.6933 30.1837 11.6821 36.3112C11.515 36.6611 11.4605 37.0541 11.526 37.4363C11.5916 37.8185 11.774 38.1709 12.0482 38.4451C12.3224 38.7192 12.6748 38.9017 13.057 38.9672C13.4391 39.0328 13.8322 38.9783 14.1821 38.8112C20.3071 35.7987 33.1058 27.6862 33.1058 27.6862C33.9322 27.2903 34.585 26.6056 34.941 25.7614C35.297 24.9171 35.3317 23.9717 35.0383 23.1037Z'
          stroke={isActiveState ? '#ffffff' : main}
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M31.8999 18.6L35.9024 14.5975'
          stroke={isActiveState ? '#ffffff' : main}
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M33.6763 20.5538L39.5163 19.5375'
          stroke={isActiveState ? '#ffffff' : main}
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M29.9451 16.8238L30.9626 10.9825'
          stroke={isActiveState ? '#ffffff' : main}
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M22.7949 17.4001L27.2837 21.8888'
          stroke={isActiveState ? '#ffffff' : main}
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M15.7598 28.9262L19.3298 32.4962'
          stroke={isActiveState ? '#ffffff' : main}
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M23.9697 26.53L28.196 30.7562'
          stroke={isActiveState ? '#ffffff' : main}
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_7253_22875'>
          <rect width='30' height='30' fill='white' transform='translate(10.5 10)' />
        </clipPath>
      </defs>
    </svg>
  );
};
