import { Config } from 'App/config';

import { HighlightedColor } from 'App/types';

import { CmsEnvironment, cmsEnvironmentLinks } from './cmsEnvironmentLinks';

const { REACT_APP_ECOLYTIQ_CMS_ENV } = Config.getAll();

export const appConfig = {
  REACT_APP_AUTO_LOGOUT_TIMER: 1800000,
  REACT_APP_AUTO_LOGOUT_MODAL_TIMER: 60,
  REACT_APP_NECESSARY_COOKIE_NAME: 'allowNecessary',
  REACT_APP_ADVERTISING_COOKIE_NAME: 'allowAdvertising',
  REACT_APP_ANALYTIC_COOKIE_NAME: 'allowAnalytics',
  REACT_APP_FIRST_LOGIN_COOKIE_NAME: 'firstLogin',
  REACT_APP_ACCESS_TOKEN_COOKIE_NAME: 'accessToken',
  REACT_APP_REFRESH_TOKEN_COOKIE_NAME: 'refreshToken',
  theme: {
    typography: {
      fontFamily: 'Open Sans',
    },
    fonts: {
      regular: {
        fontFamily: 'Open Sans',
        fontWeight: 400,
        fontDisplay: 'swap',
        fontUrl: cmsEnvironmentLinks[(REACT_APP_ECOLYTIQ_CMS_ENV as CmsEnvironment) || 'development'].fontRegular,
      },
      semiBold: {
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontDisplay: 'swap',
        fontUrl: cmsEnvironmentLinks[(REACT_APP_ECOLYTIQ_CMS_ENV as CmsEnvironment) || 'development'].fontSemiBold,
      },
      bold: {
        fontFamily: 'Open Sans',
        fontWeight: 700,
        fontDisplay: 'swap',
        fontUrl: cmsEnvironmentLinks[(REACT_APP_ECOLYTIQ_CMS_ENV as CmsEnvironment) || 'development'].fontBold,
      },
    },
    palette: {
      common: {
        black: '#000000',
        white: '#ffffff',
      },
      primary: {
        main: '#7B4EFA',
        light: '#7B4EFA',
        dark: '#0F3061',
        contrastText: '#E7EFF5',
      },
      secondary: {
        main: '#1F242F',
        light: '#5ecbcf',
      },
      grey: {
        100: '#F7F7F7',
        200: '#F0F0F0',
        300: '#EAEAEA',
        400: '#EAEAEA',
        500: '#6F7888',
        600: '#1F242F',
      },
      buttonPrimary: {
        backgroundColor: '#7B4EFA',
        text: '#ffffff',
        hoverBackground: '#1F242F',
        activeBackground: '#1F242F',
        disabledBackground: '#EAEAEA',
        hoverColor: '#ffffff',
      },
      buttonSecondary: {
        backgroundColor: '#ffffff',
        text: '#7B4EFA',
        hoverBackground: '#7B4EFA',
        activeBackground: '#7B4EFA',
        disabledBackground: '#EAEAEA',
        hoverColor: '#ffffff',
      },
      buttonOutline: {
        backgroundColor: '#7B4EFA',
        hoverBackground: '#1F242F',
        activeBackground: '#1F242F',
        disabledBackground: '#EAEAEA',
        hoverColor: '#1F242F',
      },
    },
    list: {
      lightBackground: '#7B4EFA',
      lightText: '#7B4EFA',
      darkText: '#6F7888',
      lightGreyText: '#7B4EFA',
      iconItemColor: '#7B4EFA',
      highlightedData: {
        [HighlightedColor.WHITE]: '#FFFFFF',
        [HighlightedColor.BLACK]: '#1F242F',
        [HighlightedColor.BLUE]: '#1F242F',
      },
      progressBar: '#7B4EFA',
      whiteText: '#FFFFFF',
    },
    boxShadow: {
      color: 'rgba(38, 38, 38, 0.10)',
      flag: '0px 2px 4px rgba(0, 0, 0, 0.08)',
    },
    radius: {
      large: '30px',
      small: '8px',
    },
    comparable: {
      background: '#3ED4A7',
      color: '#FFFFFF',
    },
  },
  // direction: 'rtl',
  appName: '',
  blackLogo: cmsEnvironmentLinks[(REACT_APP_ECOLYTIQ_CMS_ENV as CmsEnvironment) || 'development'].blackLogo,
  logo: cmsEnvironmentLinks[(REACT_APP_ECOLYTIQ_CMS_ENV as CmsEnvironment) || 'development'].logo,
  loginImage: cmsEnvironmentLinks[(REACT_APP_ECOLYTIQ_CMS_ENV as CmsEnvironment) || 'development'].loginImage,
  footerLogo: cmsEnvironmentLinks[(REACT_APP_ECOLYTIQ_CMS_ENV as CmsEnvironment) || 'development'].footerLogo,
  metaDescription: 'MeinFußabdruck App - Production',
  constants: {
    nationalAverage: 6380000,
  },
  blog: true,
  currencyCode: 'USD',
  languageSwitcher: false,
};
