import styled from 'styled-components';
import { Typography } from '@mui/material';

const Content = styled.div`
  position: relative;
`;

const TextWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const TextElement = styled(Typography)`
  margin-bottom: 20px;
  text-align: left;
  font-style: italic;
  color: ${({ theme }) => theme.palette.grey[600]};

  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-bottom: 12px;
    color: ${({ theme }) => theme.palette.common.white};
  }
`;

export const DidYouKnowStyles = {
  Content,
  TextElement,
  TextWrapper,
};
