import styled from 'styled-components';
import { Slider as SliderMui, Typography } from '@mui/material';

const RadioButton = styled.input`
  width: 15px;
  height: 15px;
  border: 1px solid
    ${({
      theme: {
        palette: { grey },
      },
    }) => grey[500]};
  border-radius: 50%;
  margin: 0 28px 0 0;
`;

const Slider = styled(SliderMui)`
  margin-top: 36px;
  height: 6px;
  border-radius: 3px;
  .MuiSlider-valueLabelLabel,
  .MuiSlider-markLabel {
    white-space: normal;
    max-width: 55px;
    text-align: center;
  }
  [data-index='0'].MuiSlider-markLabel {
    transform: translateX(0);
  }
  [data-index='1'].MuiSlider-markLabel {
    transform: translateX(-100%);
  }
  &.MuiSlider-dragging {
    .MuiSlider-track {
      background: ${({
        theme: {
          palette: {
            primary: { main },
          },
        },
      }) => main};
      border-color: ${({
        theme: {
          palette: {
            primary: { main },
          },
        },
      }) => main};
    }
    .MuiSlider-thumb {
      box-shadow: 0px 0px 0px 8px
        ${({
          theme: {
            palette: {
              primary: { main },
            },
          },
        }) => main};
    }
  }

  .MuiSlider-mark {
    display: none;
  }

  .MuiSlider-markLabel {
    color: ${({
      theme: {
        palette: { grey },
      },
    }) => grey[400]};
    font-size: ${({ theme }) => `${theme.typography.numbers.fontSize}`};
    line-height: ${({ theme }) => `${theme.typography.numbers.lineHeight}`};
    font-weight: ${({ theme }) => `${theme.typography.numbers.fontWeight}`};
    font-family: ${({ theme }) => `${theme.typography.numbers.fontFamily}`};
  }

  .MuiSlider-valueLabel {
    font-size: ${({ theme }) => `${theme.typography.numbers.fontSize}`};
    line-height: ${({ theme }) => `${theme.typography.numbers.lineHeight}`};
    font-family: ${({ theme }) => `${theme.typography.numbers.fontFamily}`};
    font-weight: ${({ theme }) => `${theme.typography.numbers.fontWeight}`};

    padding: 8px;
    background: ${({
      theme: {
        palette: {
          common: { white },
        },
      },
    }) => white};
    color: ${({
      theme: {
        palette: {
          primary: { main },
        },
      },
    }) => main};
    &::before {
      display: none;
    }
  }

  .MuiSlider-rail {
    background-color: ${({
      theme: {
        palette: { grey },
      },
    }) => grey[300]};
  }
  .MuiSlider-track {
    background: ${({
      theme: {
        palette: {
          primary: { main },
        },
      },
    }) => main};
    border-color: ${({
      theme: {
        palette: {
          custom: {
            layout: { light },
          },
        },
      },
    }) => light};
  }
  .MuiSlider-thumb {
    width: 8px;
    height: 8px;
    color: ${({
      theme: {
        palette: {
          common: { white },
        },
      },
    }) => white};

    box-shadow: 0px 0px 0px 8px
      ${({
        theme: {
          palette: {
            primary: { main },
          },
        },
      }) => main};
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    .MuiSlider-valueLabelLabel,
    .MuiSlider-markLabel {
    }
  }
`;

const Question = styled(Typography)`
  margin-bottom: 28px;
  margin-top: 40px;
  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-bottom: 36px;
    margin-top: 60px;
  }
`;

const RadioButtonWrapper = styled(Typography)`
  height: 44px;
  display: flex;
  align-items: center;
`;

const Input = styled.textarea`
  width: 100%;
  height: 86px;
  border: 0;
  background: ${({
    theme: {
      palette: { grey },
    },
  }) => grey[200]};
  border-radius: ${({ theme }) => `${theme.palette.custom.radius.radiusSmall}`};
  padding: 16px;
  font-size: ${({ theme }) => `${theme.typography.body1.fontSize}`};
  line-height: ${({ theme }) => `${theme.typography.body1.lineHeight}`};
  font-weight: ${({ theme }) => `${theme.typography.body1.fontWeight}`};
  font-family: ${({ theme }) => `${theme.typography.body1.fontFamily}`};
  &:active,
  &:focus {
    border: 0;
    outline: 0;
  }
  ::placeholder {
    font-size: ${({ theme }) => `${theme.typography.body1.fontSize}`};
    line-height: ${({ theme }) => `${theme.typography.body1.lineHeight}`};
    font-weight: ${({ theme }) => `${theme.typography.body1.fontWeight}`};
    font-family: ${({ theme }) => `${theme.typography.body1.fontFamily}`};
    color: ${({
      theme: {
        palette: { grey },
      },
    }) => grey[500]};
  }
`;

const InputLabel = styled(Typography)`
  margin-left: 16px;
`;

export const FeedbackPageStyles = {
  RadioButton,
  Slider,
  RadioButtonWrapper,
  Question,
  Input,
  InputLabel,
};
