import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface OneWayProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const OneWay = ({ props, isActive }: OneWayProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} width='51' height='51' viewBox='0 0 51 51' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_7253_33048)'>
        <path
          d='M30.5016 16.7173L17.2864 16.7595L16.2224 14.6501L13.5974 14.6583C13.4221 14.6582 13.2495 14.7018 13.0953 14.7851C12.9411 14.8685 12.8101 14.9889 12.7141 15.1356C12.6181 15.2823 12.5602 15.4506 12.5457 15.6253C12.5311 15.7999 12.5602 15.9755 12.6306 16.1361L14.6356 20.772C14.8001 21.1516 15.0723 21.4747 15.4184 21.7013C15.7646 21.9279 16.1696 22.0481 16.5833 22.047L20.4751 22.0353L18.0845 26.8552C18.0045 27.0164 17.9671 27.1954 17.9758 27.3752C17.9846 27.555 18.0392 27.7295 18.1344 27.8822C18.2297 28.0349 18.3624 28.1607 18.52 28.2476C18.6777 28.3345 18.8549 28.3796 19.0349 28.3786L21.0575 28.3728C21.2125 28.3724 21.3656 28.3379 21.5057 28.2717C21.6459 28.2055 21.7697 28.1091 21.8684 27.9896L26.8184 22.013L30.5181 22.0013C31.2189 21.9991 31.8902 21.7186 32.3842 21.2215C32.8783 20.7244 33.1546 20.0514 33.1524 19.3505C33.1503 18.6497 32.8698 17.9784 32.3726 17.4843C31.8755 16.9903 31.2025 16.7151 30.5016 16.7173Z'
          stroke={isActiveState ? '#ffffff' : main}
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M26.8009 16.729L23.9345 12.9322C23.8358 12.8009 23.7078 12.6945 23.5607 12.6215C23.4136 12.5484 23.2515 12.5108 23.0873 12.5115L21.0951 12.5185C20.9149 12.5189 20.7378 12.5655 20.5808 12.6538C20.4237 12.7421 20.2919 12.8692 20.1979 13.0229C20.1039 13.1766 20.0508 13.3518 20.0438 13.5318C20.0367 13.7119 20.0759 13.8907 20.1576 14.0513L21.5158 16.7454'
          stroke={isActiveState ? '#ffffff' : main}
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M35.8209 38.8751C36.2578 38.8769 36.6883 38.77 37.0737 38.5642C37.459 38.3583 37.7872 38.06 38.0287 37.6959C38.2702 37.3318 38.4174 36.9134 38.4572 36.4784C38.4969 36.0433 38.428 35.6051 38.2565 35.2033C38.0849 34.8015 37.8163 34.4486 37.4746 34.1763C37.1329 33.9041 36.7289 33.721 36.299 33.6435C35.869 33.566 35.4265 33.5966 35.0113 33.7325C34.5961 33.8684 34.2211 34.1053 33.9202 34.422C33.7444 33.7922 33.3962 33.224 32.9148 32.7815C32.4334 32.3389 31.838 32.0397 31.1956 31.9175C30.5533 31.7952 29.8895 31.8549 29.2793 32.0897C28.669 32.3245 28.1364 32.7251 27.7417 33.2463C27.3469 33.7676 27.1056 34.3888 27.0449 35.0398C26.9843 35.6909 27.1067 36.346 27.3984 36.9312C27.6901 37.5164 28.1395 38.0085 28.6959 38.3519C29.2523 38.6954 29.8936 38.8766 30.5475 38.8751H35.8209Z'
          stroke={isActiveState ? '#ffffff' : main}
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_7253_33048'>
          <rect width='30' height='30' fill='white' transform='translate(10.5 10.6939)' />
        </clipPath>
      </defs>
    </svg>
  );
};
