import styled from 'styled-components';

const Title = styled.div`
  margin-bottom: 8px;
  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-bottom: 16px;
  }
`;

const IconWrapper = styled.div`
  cursor: pointer;
  margin-left: 12px;
`;

const Wrapper = styled.div`
  padding-bottom: 16px;
  ${(props) => props.theme.breakpoints.up('lg')} {
    padding-top: 80px;
  }
`;

const ArrowWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 4px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-bottom: 20px;
  }
`;

export const HeaderStyles = {
  IconWrapper,
  Title,
  Wrapper,
  ArrowWrapper,
};
