import styled from 'styled-components';
import { Grid } from '@mui/material';

const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 0;
  padding-top: 76px;
  ${(props) => props.theme.breakpoints.down('md')} {
    padding-top: 32px;
    padding-bottom: 56px;
  }
`;

const TransactionsListWrapper = styled(Grid)`
  padding-top: 24px;
  ${(props) => props.theme.breakpoints.up('md')} {
    background: transparent;
    padding-top: 0;
  }
`;

const Wrapper = styled.div`
  box-shadow: 0px -12px 24px 0px ${({ theme }) => theme.palette.custom.boxShadow.color};

  border-top-left-radius: ${({
    theme: {
      palette: {
        custom: {
          radius: { radiusSmall },
        },
      },
    },
  }) => radiusSmall};
  border-top-right-radius: ${({
    theme: {
      palette: {
        custom: {
          radius: { radiusSmall },
        },
      },
    },
  }) => radiusSmall};
  ${(props) => props.theme.breakpoints.up('md')} {
    background: ${({
      theme: {
        palette: {
          secondary: { main },
        },
      },
    }) => main};

    box-shadow: 0px 0px 24px 0px
      ${({
        theme: {
          palette: {
            custom: {
              boxShadow: { color },
            },
          },
        },
      }) => color};
  }
`;

export const HomePageStyles = {
  LinkWrapper,
  TransactionsListWrapper,
  Wrapper,
};
