import { Layout } from 'App/components';

import { useTranslation } from 'react-i18next';

import dompurify from 'dompurify';

import { Inbox } from './assets';

import { PasswordRecoveryStyles } from './PasswordRecovery.styles';

export const PasswordRecovery = () => {
  const { t }: any = useTranslation();
  const sanitizer = dompurify.sanitize;

  return (
    <Layout bgColor isFullHeight>
      <PasswordRecoveryStyles.Wrapper>
        <PasswordRecoveryStyles.SVGWrapper>
          <Inbox />
        </PasswordRecoveryStyles.SVGWrapper>
        <PasswordRecoveryStyles.Title
          variant='h6'
          dangerouslySetInnerHTML={{
            __html: sanitizer(t('passwordRecovery:title')),
          }}
        />
        <PasswordRecoveryStyles.TextWrapper>{t('passwordRecovery:text')}</PasswordRecoveryStyles.TextWrapper>
      </PasswordRecoveryStyles.Wrapper>
    </Layout>
  );
};
