import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface FoodMeatProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const FoodMeat = ({ props, isActive }: FoodMeatProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.6366 26.1971C21.4686 26.1971 21.3324 26.0608 21.3324 25.8929C21.3324 25.7248 21.4686 25.5887 21.6366 25.5887'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
      />
      <path
        d='M28.3593 26.1971C28.1912 26.1971 28.0551 26.0608 28.0551 25.8929C28.0551 25.7248 28.1912 25.5887 28.3593 25.5887'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
      />
      <path
        d='M21.6366 26.1971C21.8046 26.1971 21.9408 26.0608 21.9408 25.8929C21.9408 25.7248 21.8046 25.5887 21.6366 25.5887'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
      />
      <path
        d='M28.361 26.1971C28.5289 26.1971 28.6651 26.0608 28.6651 25.8929C28.6651 25.7248 28.5289 25.5887 28.361 25.5887'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
      />
      <path
        d='M19.7433 32.8704C21.9009 31.0992 27.9532 31.0513 30.2556 32.8577'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M23.8195 34.9762V34.3651'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M26.2637 34.9762L26.2371 34.3651'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M32.7085 23.2315C35.1016 23.6642 38.2385 25.3463 38.75 27.9036C37.5543 29.5598 34.5652 30.2949 31.576 27.9036C31.175 31.2288 30.0513 33.4057 28.6474 36.4245C28.4178 36.9164 28.0591 37.3315 27.6122 37.622C27.1655 37.9124 26.6487 38.0665 26.1212 38.0667H23.8787C23.3512 38.0665 22.8344 37.9124 22.3877 37.622C21.9409 37.3315 21.5821 36.9164 21.3525 36.4245C19.9486 33.4057 18.8249 31.2288 18.4239 27.9036C15.4347 30.2949 12.4456 29.5598 11.25 27.9036C11.7614 25.3463 14.8983 23.6642 17.2914 23.2315'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M25.1644 18.5372C24.6788 16.8239 25.6741 15.0414 27.3874 14.5559C29.4119 13.9821 31.2731 15.9352 33.3443 15.9623C35.3143 15.9882 36.9051 15.0045 35.3158 12.5C36.6272 12.7067 37.7643 13.6518 38.1502 15.0129C39.7794 20.762 26.5851 23.5502 25.1644 18.5372Z'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linejoin='round'
      />
      <path
        d='M24.9194 18.537C25.405 16.8238 24.4097 15.0413 22.6966 14.5558C20.672 13.982 18.8108 15.935 16.7396 15.9622C14.7697 15.9881 13.1788 15.0044 14.7681 12.4999C13.4567 12.7066 12.3195 13.6517 11.9337 15.0128C10.3044 20.7618 23.4987 23.5501 24.9194 18.537Z'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linejoin='round'
      />
    </svg>
  );
};
