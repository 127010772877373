import styled from 'styled-components';
import { Typography } from '@mui/material';

const Title = styled(Typography)`
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 24px;
`;

const CurveWrapper = styled.div`
  height: 100%;
  min-height: calc(100vh - 68px);
  display: flex;
  flex-grow: 1;
`;

const ImgWrapper = styled.div`
  max-width: 320px;
  position: relative;
  img {
    width: 100%;
    margin-bottom: -5.7px;
  }
`;

const Item = styled(Typography)`
  display: flex;
  margin-bottom: 20px;
  &:before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_6651_15590)"><path d="M6.5 12L10.157 15.751L18 8.5" stroke="%237B4EFA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M1 12C1 14.9174 2.15893 17.7153 4.22183 19.7782C6.28473 21.8411 9.08262 23 12 23C14.9174 23 17.7153 21.8411 19.7782 19.7782C21.8411 17.7153 23 14.9174 23 12C23 9.08262 21.8411 6.28473 19.7782 4.22183C17.7153 2.15893 14.9174 1 12 1C9.08262 1 6.28473 2.15893 4.22183 4.22183C2.15893 6.28473 1 9.08262 1 12V12Z" stroke="%237B4EFA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_6651_15590"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>');
    flex-shrink: 0;
  }
`;

const Wrapper = styled.div`
  background: ${({
    theme: {
      palette: {
        common: { white },
      },
    },
  }) => white};
  color: ${({
    theme: {
      palette: { grey },
    },
  }) => grey[600]};
  padding: 20px 24px 36px;
  height: 100%;
  width: 100%;
  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 52px 0 56px;
  }
`;

const LogoWrapper = styled.div`
  padding: 42px 0 46px;
  width: 130px;
  img {
    width: 100%;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 64px 0 60px;
    width: 180px;
  }
`;

const ListWrapper = styled.div`
  margin-bottom: 48px;
  width: 100%;
`;

export const LandingPageStyles = {
  Title,
  ButtonWrapper,
  CurveWrapper,
  ImgWrapper,
  Item,
  Wrapper,
  LogoWrapper,
  ListWrapper,
};
