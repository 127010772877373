import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface NoRedMeatProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const NoRedMeat = ({ props, isActive }: NoRedMeatProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} width='51' height='50' viewBox='0 0 51 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M24.4937 22.2626C24.3842 22.2626 24.2954 22.1737 24.2954 22.0642C24.2954 21.9545 24.3842 21.8657 24.4937 21.8657'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
      />
      <path
        d='M28.8756 22.2626C28.766 22.2626 28.6772 22.1737 28.6772 22.0642C28.6772 21.9545 28.766 21.8657 28.8756 21.8657'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
      />
      <path
        d='M24.4937 22.2626C24.6032 22.2626 24.6919 22.1737 24.6919 22.0642C24.6919 21.9545 24.6032 21.8657 24.4937 21.8657'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
      />
      <path
        d='M28.876 22.2626C28.9855 22.2626 29.0742 22.1737 29.0742 22.0642C29.0742 21.9545 28.9855 21.8657 28.876 21.8657'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
      />
      <path
        d='M23.259 26.6133C24.6656 25.4578 28.611 25.4265 30.112 26.605'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M25.916 27.9857V27.587'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M27.5093 27.9857L27.4919 27.587'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M31.7106 20.3291C33.2707 20.6112 35.3155 21.7077 35.649 23.3748C34.8695 24.4545 32.921 24.9336 30.9723 23.3748C30.7109 25.5424 29.9784 26.9616 29.0632 28.9295C28.9135 29.2502 28.6797 29.5207 28.3884 29.7101C28.0972 29.8994 27.7603 29.9999 27.4164 30H25.9545C25.6106 29.9999 25.2738 29.8994 24.9825 29.7101C24.6913 29.5207 24.4574 29.2502 24.3077 28.9295C23.3925 26.9616 22.66 25.5424 22.3986 23.3748C20.45 24.9336 18.5014 24.4545 17.7219 23.3748C18.0553 21.7077 20.1003 20.6112 21.6603 20.3291'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M26.7926 17.2689C26.476 16.152 27.1248 14.99 28.2417 14.6735C29.5615 14.2995 30.7748 15.5727 32.125 15.5904C33.4092 15.6072 34.4463 14.966 33.4102 13.3333C34.2651 13.4681 35.0064 14.0842 35.2579 14.9715C36.32 18.7192 27.7187 20.5368 26.7926 17.2689Z'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linejoin='round'
      />
      <path
        d='M26.6331 17.2689C26.9496 16.152 26.3008 14.99 25.184 14.6735C23.8642 14.2995 22.6509 15.5727 21.3007 15.5904C20.0165 15.6072 18.9795 14.966 20.0155 13.3333C19.1606 13.4681 18.4193 14.0842 18.1678 14.9715C17.1057 18.7192 25.7069 20.5368 26.6331 17.2689Z'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linejoin='round'
      />
      <line
        x1='36.0301'
        y1='10.5303'
        x2='16.0301'
        y2='30.5303'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
      />
      <path
        d='M22.8779 36.046L21.646 34.1741H22.83L23.5726 35.3924L24.3221 34.1741H25.5061L24.2605 36.046L25.5643 38H24.3768L23.5726 36.6893L22.765 38H21.581L22.8779 36.046ZM29.4894 36.9631H28.8871V38H27.8537V36.9631H25.7183V36.2274L27.9118 32.9969H28.8871V36.1418H29.4894V36.9631ZM27.8537 36.1418V35.2931C27.8537 35.1517 27.8594 34.9464 27.8708 34.6771C27.8822 34.4079 27.8913 34.2517 27.8982 34.2083H27.8708C27.7864 34.3954 27.6848 34.5779 27.5662 34.7559L26.6491 36.1418H27.8537Z'
        fill={isActiveState ? '#ffffff' : main}
      />
    </svg>
  );
};
