import { Layout } from 'App/components';

import { useTranslation } from 'react-i18next';

import { RegisterCompleteStyles } from './RegisterComplete.styles';

export const RegisterComplete = () => {
  const { t }: any = useTranslation();

  return (
    <Layout bgColor isFullHeight>
      <RegisterCompleteStyles.Container>
        <RegisterCompleteStyles.Icon />
        <RegisterCompleteStyles.Title variant='h6'>{t('registerComplete:title')}</RegisterCompleteStyles.Title>
        <RegisterCompleteStyles.Text>{t('registerComplete:text')}</RegisterCompleteStyles.Text>
      </RegisterCompleteStyles.Container>
    </Layout>
  );
};
