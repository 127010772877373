import styled from 'styled-components';
import { Paper } from 'components';

const Content = styled(Paper.RoundedTop)`
  padding: 32px 12px 52px;
  background: linear-gradient(
    ${({ theme }) => `${theme.palette.common.white}`} 362px,
    ${({ theme }) => `${theme.palette.primary.main}`} 0%
  );
  z-index: 1;
  position: relative;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 36px 8px;
    margin-top: 48px;
    box-shadow: 0px 0px 12px ${({ theme }) => `${theme.palette.custom.boxShadow.color}`};
    border-radius: ${({ theme }) => `${theme.palette.custom.radius.radiusSmall}`};
    background: white;
  }

  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 36px 28px;
  }
`;

const QuestionsWrapper = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    padding-bottom: 56px;
    padding-top: 100px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 36px;
`;

const Container = styled.div`
  ${(props) => props.theme.breakpoints.down('lg')} {
    background: ${({
      theme: {
        palette: {
          secondary: { main },
        },
      },
    }) => main};
  }
`;

export const FoodSettings = {
  ButtonWrapper,
  QuestionsWrapper,
  Content,
  Container,
};
