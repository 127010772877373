import { Grid } from '@mui/material';

import { ButtonAppearance, Button, Dialog } from 'components';

import { useTranslation } from 'react-i18next';

import { deleteData } from '@ecolytiq/js-data-provider';

import { setSessionLogoutAction, useDeps, useUserContext } from 'App/context';

import { useEffect, useState } from 'react';

import { handleUserLogout } from '../../utils';

type DeleteDataModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

export const DeleteDataModal = ({ isOpen, onClose }: DeleteDataModalProps) => {
  const { t } = useTranslation();
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const { httpClientService } = useDeps();
  const [, dispatch] = useUserContext();

  const deleteAndLogout = () => {
    deleteData(httpClientService).post();
    dispatch(setSessionLogoutAction(true));
    handleUserLogout();
  };

  const onClick = () => {
    setSuccessModalOpen(true);
  };
  const onClickSuccess = () => {
    deleteAndLogout();
  };

  useEffect(() => {
    if (successModalOpen) {
      setTimeout(() => {
        deleteAndLogout();
      }, 5000);
    }
  }, [successModalOpen]);

  return (
    <>
      {!successModalOpen ? (
        <Dialog
          slug='delete'
          content={t('poc:deleteData:title') as string}
          title={t('poc:deleteData:text') as string}
          isOpen={isOpen}
          onClose={onClose}
        >
          <Grid container justifyContent='center' spacing={2}>
            <Grid item justifyContent='flex-end'>
              <Button isSmall onClick={onClick} text={t('common:ok')} />
            </Grid>
            <Grid item justifyContent='flex-start'>
              <Button isSmall appearance={ButtonAppearance.SECONDARY} onClick={onClose} text={t('common:cancel')} />
            </Grid>
          </Grid>
        </Dialog>
      ) : (
        <Dialog
          slug='delete-success'
          content={t('poc:deleteSuccess:text') as string}
          isOpen={true}
          onClose={onClickSuccess}
        >
          <Grid container justifyContent='center' spacing={2}>
            <Grid item justifyContent='flex-end'>
              <Button isSmall onClick={onClickSuccess} text={t('poc:deleteSuccess:button')} />
            </Grid>
          </Grid>
        </Dialog>
      )}
    </>
  );
};
