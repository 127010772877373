export const landingPage = {
  title: 'Manage transactions, your carbon footprint estimate and more',
  list: [
    'View an estimate of your carbon footprint estimate based on your purchases',
    'Receive meaningful insights on how you may reduce your environmental impact',
    'Track your progress over time',
  ],
  button: 'Get started',
  signupButton: 'Create an account',
  iosWarning:
    'We are experiencing intermittent technical issues registering cards on Mac and iOS devices. For the best experience please visit the site using on Android device or PC.',
};
