import styled from 'styled-components';
import { Typography, Grid, Container } from '@mui/material';

const Title = styled(Typography)`
  margin-bottom: ${({ theme: { gap } }) => `${gap(9)}`};
  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-top: 50px;
    margin-bottom: 10px;
  }
`;

const FAQsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  > div {
    background: transparent;
  }
`;

const Wrapper = styled(Grid)`
  margin-top: 0;

  ${(props) => props.theme.breakpoints.between('md', 'lg')} {
    padding: 12px;
    margin-bottom: 40px;
  }

  ${(props) => props.theme.breakpoints.up('lg')} {
    background: transparent;
  }
`;

const SliderWrapper = styled(Container)`
  padding-top: 30px;
  padding-bottom: 30px;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 0;
  }
`;

export const FAQPageStyles = {
  Title,
  Wrapper,
  SliderWrapper,
  FAQsContainer,
};
