import { useEffect } from 'react';

import { Layout } from 'App/components';

import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { RoutePaths } from 'impactApp/routes';

import { Config } from 'App/config';

import { ButtonAppearance } from 'components';

import { appConfig } from 'App/appConfig/appConfig';

import { SSOStyles } from './SSOPage.styles';

export const SSOPage = () => {
  const { t }: any = useTranslation(['sso']);
  const { HOME } = RoutePaths;
  const navigate = useNavigate();
  const { REACT_APP_ECOLYTIQ_KEYCLOAK_URL, REACT_APP_ECOLYTIQ_KEYCLOAK_CLIENT_ID, REACT_APP_MOCK_API } =
    Config.getAll();

  useEffect(() => {
    document.title = 'CO2 SSO Page';
  });

  return (
    <Layout bgColor isFullHeight>
      <SSOStyles.LogoWrapper>
        <img src={appConfig.logo} />
      </SSOStyles.LogoWrapper>
      <SSOStyles.Container>
        <SSOStyles.Icon />
        <SSOStyles.Title variant='h6'>{t('sso:title')}</SSOStyles.Title>
        <SSOStyles.Text>{t('sso:text')}</SSOStyles.Text>
        <SSOStyles.ButtonWrapper
          appearance={ButtonAppearance.SECONDARY}
          onClick={() =>
            REACT_APP_MOCK_API === 'true'
              ? navigate(HOME)
              : window.open(
                  `${REACT_APP_ECOLYTIQ_KEYCLOAK_URL}/auth?client_id=${REACT_APP_ECOLYTIQ_KEYCLOAK_CLIENT_ID}&redirect_uri=${window.location.origin}&response_type=code&scope=openid&response_mode=fragment`,
                  '_self',
                )
          }
          text={t('sso:button')}
        />
      </SSOStyles.Container>
    </Layout>
  );
};
