import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';

const Wrapper = styled.div<{
  alignText?: boolean;
  hasSubtitle?: boolean;
  hasFixedHeader?: boolean;
}>`
  margin-bottom: 56px;

  ${({ hasFixedHeader }) =>
    hasFixedHeader &&
    css`
      padding-top: 24px;
    `};

  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-bottom: 52px;
    padding-top: 45px;
    ${({ hasFixedHeader }) =>
      hasFixedHeader &&
      css`
        padding-top: 95px;
      `};
  }

  ${({ alignText }) =>
    alignText &&
    css`
      text-align: center;
    `};

  ${({ hasSubtitle }) =>
    !hasSubtitle &&
    css`
      ${(props) => props.theme.breakpoints.down('md')} {
        margin-bottom: 16px;
      }
    `};
`;

const Title = styled(Typography)<{
  isWhite?: boolean;
}>`
  color: ${({ theme }) => theme.palette.grey[600]};
  margin-bottom: 20px;
  ${(props) => props.theme.breakpoints.down('lg')} {
    ${({ isWhite }) =>
      isWhite &&
      css`
        color: ${({ theme }) => `${theme.palette.common.white}`};
      `}
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    text-align: center;
  }
`;

const Subtitle = styled(Typography)<{
  isWhite?: boolean;
}>`
  color: ${({ theme }) => theme.palette.grey[600]};

  margin: 0 auto;
  max-width: 300px;

  ${(props) => props.theme.breakpoints.down('lg')} {
    ${({ isWhite }) =>
      isWhite &&
      css`
        color: ${({ theme }) => `${theme.palette.common.white}`};
      `}
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 364px;
  }
`;

export const TeaserStyles = {
  Wrapper,
  Title,
  Subtitle,
};
