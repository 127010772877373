export const foodSettings = {
  headerTitle: 'Food Settings',
  title: 'Your settings.',
  subtitle:
    'If the way you shop changes, you can adjust these settings. This helps us calculate the most accurate carbon footprint estimate for you.',
  submitButton: 'Save',
  feedbackLoopButton: 'Refine my foodprint',
  submittedTitle: 'You refined your settings',
  submittedSubtitle: `Your transaction's CO₂e footprint estimate changed`,
  categoryButton: 'Show all transactions',
};
