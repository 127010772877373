import { useEffect, useState } from 'react';

import { Button, Header, InnerContainer, LogoutButton, useModal } from 'components';

import { Grid, Container, useMediaQuery } from '@mui/material';

import { Layout, BASIC_GRADIENT_BACKGROUND_HEIGHT, Link as LinkComponent } from 'App/components';

import { useTranslation } from 'react-i18next';

import { color, theme } from 'App/style';

import { HomePageStyles } from './HomePage.styles';

import { FAQSlider, FootprintChart, FootprintList, HowItWorksModal, TransactionList } from './submodules';

export const HomePage = () => {
  const { Modal, openCallback: openModal } = useModal();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const { t }: any = useTranslation();
  const [faqIndex, setFaqIndex] = useState(-1);

  useEffect(() => {
    document.title = 'CO2 Home';
  });

  return (
    <Layout
      bgHeight={BASIC_GRADIENT_BACKGROUND_HEIGHT}
      hideContainer
      header={<Header title={t('homePage:appName')} transparent hideLeftButton rightButton={<LogoutButton />} />}
    >
      <>
        <HowItWorksModal Modal={Modal} faqIndex={faqIndex} />
        <FootprintChart />
        <HomePageStyles.Wrapper>
          <Container maxWidth='xl' disableGutters={!isMd}>
            <InnerContainer>
              <Grid
                container
                justifyContent='center'
                alignItems='flex-start'
                flexDirection={{ sm: 'column', md: 'row' }}
                columnSpacing={{ md: 3, lg: 7 }}
                py={{ md: 8, lg: 12 }}
              >
                <Grid item md={6} lg={6} sx={{ width: { sm: '100%', md: 'auto' } }}>
                  <FootprintList />
                </Grid>
                <HomePageStyles.TransactionsListWrapper
                  item
                  md={6}
                  lg={6}
                  sx={{ width: { sm: '100%', md: 'auto' } }}
                  px={{ xs: 3, md: 0 }}
                >
                  <Grid
                    flexDirection={{ sm: 'column', md: 'column-reverse' }}
                    container
                    justifyContent='flex-start'
                    alignItems='flex-start'
                  >
                    <Grid item width='100%'>
                      {isMd && (
                        <HomePageStyles.LinkWrapper>
                          <LinkComponent
                            text={t('common:link')}
                            onClick={() => {
                              setFaqIndex(-1);
                              openModal();
                            }}
                            textColor={color.white}
                          />
                        </HomePageStyles.LinkWrapper>
                      )}
                    </Grid>
                    <Grid item width='100%'>
                      <TransactionList />
                      {!isMd && (
                        <HomePageStyles.LinkWrapper>
                          <Button
                            text={t('common:link')}
                            onClick={() => {
                              setFaqIndex(-1);
                              openModal();
                            }}
                          />
                        </HomePageStyles.LinkWrapper>
                      )}
                    </Grid>
                  </Grid>
                </HomePageStyles.TransactionsListWrapper>
                <Grid item width='100%'>
                  <FAQSlider onClick={() => openModal()} setFaqIndex={setFaqIndex} />
                </Grid>
              </Grid>
            </InnerContainer>
          </Container>
        </HomePageStyles.Wrapper>
      </>
    </Layout>
  );
};
