import { createContext, Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';

type TCValues = {
  isTCOpen: boolean;
  title: string;
  slug: string;
  collapsedText: string;
  text: string;
};

interface TCContextI {
  tcValues: TCValues;
  setTCValues?: Dispatch<SetStateAction<TCValues>>;
}

const defaultState = {
  tcValues: {
    isTCOpen: false,
    title: '',
    slug: '',
    collapsedText: '',
    text: '',
  },
};

export const TCContext = createContext<TCContextI>(defaultState);

export const TCProvider = ({ children }: PropsWithChildren<Record<string, any>>) => {
  const [tcValues, setTCValues] = useState(defaultState.tcValues);

  return <TCContext.Provider value={{ tcValues, setTCValues }}>{children}</TCContext.Provider>;
};
