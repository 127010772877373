import styled, { css } from 'styled-components';

export const LayoutStyles = styled.div<{
  $src?: string;
  $bgHeight?: string;
  $bgcolor?: boolean;
  $fullheight?: boolean;
}>`
  ${({ $bgcolor }) =>
    $bgcolor &&
    css`
      background: ${({ theme }) => `${theme.palette.secondary.main}`};
    `};

  ${({ $src }) =>
    $src &&
    css`
      background-image: url(${$src});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: left top;
    `};

  ${({ $fullheight }) =>
    $fullheight &&
    css`
      min-height: 100vh;
      display: flex;
      flex-direction: column;
    `};
`;
