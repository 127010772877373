import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { Layout, Teaser } from 'App/components';

import { Header, GoBackButton, BackgroundWrapper, Button, ButtonAppearance } from 'components';

import { Grid } from '@mui/material';

import { HelpPageStyles } from './HelpPage.styles';

export const HelpPage = () => {
  const { t } = useTranslation(['helpPage']);

  useEffect(() => {
    document.title = 'CO2 Help';
  });

  return (
    <Layout
      isFullHeight
      hideContainer
      header={<Header transparent showDesktopNavigation button={<GoBackButton />} hideRightButton />}
    >
      <Teaser hasFixedHeader isAlignCenter title={t('helpPage:title')} />
      <HelpPageStyles.StaticPageContainer>
        <BackgroundWrapper>
          <HelpPageStyles.Wrapper container justifyContent='center'>
            <Grid item md={6}>
              <HelpPageStyles.DeleteDataTitle variant='body1'>{t('helpPage:text')}</HelpPageStyles.DeleteDataTitle>
              <HelpPageStyles.ButtonWrapper>
                <Button
                  appearance={ButtonAppearance.SECONDARY}
                  isTransparent
                  iconRight
                  text={t('helpPage:buttonText')}
                  onClick={() => (window.location.href = t('helpPage:url'))}
                />
              </HelpPageStyles.ButtonWrapper>
            </Grid>
          </HelpPageStyles.Wrapper>
        </BackgroundWrapper>
      </HelpPageStyles.StaticPageContainer>
    </Layout>
  );
};
