import { SVGProps } from 'react';

export const Inbox = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg {...props} width='360' height='360' viewBox='0 0 360 360' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M80.9865 153.249L275.268 151.841L280.679 283.926L79.5039 282.064L80.9865 153.249Z' fill='#3ED4A7' />
    <path
      d='M80.9766 153.25L172.932 78.8468C174.821 77.3199 177.179 76.4911 179.609 76.5001C182.038 76.5091 184.389 77.3553 186.267 78.8962L275.266 151.841'
      fill='#3ED4A7'
    />
    <g opacity='0.3'>
      <path
        d='M80.9766 153.25L172.932 78.8468C174.821 77.3199 177.179 76.4911 179.609 76.5001C182.038 76.5091 184.389 77.3553 186.267 78.8962L275.266 151.841'
        fill='black'
      />
    </g>
    <path
      d='M79.5027 282.065C79.2638 281.834 94.8149 264.85 114.245 244.135C133.676 223.419 149.614 206.805 149.861 207.028C150.108 207.25 134.549 224.234 115.119 244.958C95.688 265.682 79.7498 282.287 79.5027 282.065Z'
      fill='#263238'
    />
    <path
      d='M280.677 283.926C280.43 284.149 265.282 267.51 246.848 246.77C228.414 226.03 213.67 209.029 213.901 208.807C214.132 208.584 229.296 225.223 247.73 245.971C266.164 266.72 280.94 283.704 280.677 283.926Z'
      fill='#263238'
    />
    <g opacity='0.5'>
      <path d='M275.267 151.841L177.703 229.778L80.9863 153.25' fill='black' />
    </g>
    <path
      d='M90.7614 160.465L90.4648 99.7929L260.579 97.7749L262.498 161.618L177.692 228.756L90.7614 160.465Z'
      fill='#FAFAFA'
    />
    <path
      d='M90.7632 160.465C90.7632 160.465 91.3151 160.877 92.3612 161.692L96.9738 165.283L114.559 179.014L177.826 228.616H177.612L262.393 161.453L262.311 161.626C261.948 149.452 261.561 136.669 261.166 123.671C260.911 114.916 260.647 106.267 260.392 97.783L260.606 97.9889L90.4914 99.9246L90.6232 99.7928C90.6891 118.877 90.7468 134.041 90.7797 144.436C90.7797 149.634 90.7797 153.637 90.7797 156.347V159.419C90.7797 160.111 90.7797 160.465 90.7797 160.465C90.7797 160.465 90.7797 160.119 90.7797 159.435C90.7797 158.752 90.7797 157.722 90.7797 156.38C90.7797 153.67 90.7221 149.675 90.6809 144.486C90.615 134.074 90.5161 118.902 90.3926 99.7928V99.6528H90.5244L260.647 97.5688H260.853V97.783C261.108 106.259 261.364 114.907 261.627 123.663C262.023 136.669 262.401 149.444 262.772 161.626V161.733L262.69 161.799L177.851 228.904L177.744 228.987L177.637 228.904L114.411 179.138L96.9326 165.341L92.32 161.717C91.2821 160.893 90.7632 160.465 90.7632 160.465Z'
      fill='#263238'
    />
    <path
      d='M233.697 128.333C233.697 128.449 207.677 128.704 175.595 128.894C143.513 129.083 117.484 129.157 117.484 129.034C117.484 128.91 143.496 128.663 175.595 128.465C207.694 128.268 233.697 128.21 233.697 128.333Z'
      fill='#263238'
    />
    <path
      d='M233.697 145.466C233.697 145.589 207.677 145.845 175.595 146.034C143.513 146.224 117.484 146.29 117.484 146.174C117.484 146.059 143.496 145.804 175.595 145.606C207.694 145.408 233.697 145.351 233.697 145.466Z'
      fill='#263238'
    />
    <path
      d='M233.697 165.226C233.697 165.341 207.677 165.596 175.595 165.794C143.513 165.992 117.484 166.049 117.484 165.926C117.484 165.802 143.496 165.555 175.595 165.366C207.694 165.176 233.697 165.102 233.697 165.226Z'
      fill='#263238'
    />
    <path
      d='M233.696 184.978C233.696 185.101 208.878 185.34 178.262 185.53C147.646 185.719 122.829 185.768 122.82 185.653C122.812 185.538 147.638 185.291 178.262 185.101C208.887 184.912 233.696 184.862 233.696 184.978Z'
      fill='#263238'
    />
    <path
      d='M211.934 202.316C211.71 202.354 211.484 202.371 211.258 202.366L209.298 202.423L202.115 202.539L178.402 202.876C169.143 203.033 160.75 203.082 154.68 203.091H147.497H145.537C145.311 203.095 145.085 203.081 144.861 203.049C145.084 203.012 145.311 202.995 145.537 203L147.497 202.951L154.68 202.827L178.393 202.489C187.651 202.333 196.045 202.283 202.107 202.275H209.298H211.258C211.484 202.275 211.709 202.288 211.934 202.316Z'
      fill='#263238'
    />
    <path
      d='M190.033 218.988C190.033 219.103 184.489 219.202 177.677 219.202C170.866 219.202 165.322 219.103 165.322 218.988C165.322 218.872 170.874 218.773 177.677 218.773C184.481 218.773 190.033 218.864 190.033 218.988Z'
      fill='#263238'
    />
    <path
      d='M247.293 205.528C258.643 205.528 267.844 196.327 267.844 184.978C267.844 173.628 258.643 164.427 247.293 164.427C235.943 164.427 226.742 173.628 226.742 184.978C226.742 196.327 235.943 205.528 247.293 205.528Z'
      fill='#7B4EFA'
    />
    <path
      d='M241.947 205.528C253.297 205.528 262.498 196.327 262.498 184.978C262.498 173.628 253.297 164.427 241.947 164.427C230.597 164.427 221.396 173.628 221.396 184.978C221.396 196.327 230.597 205.528 241.947 205.528Z'
      fill='#1F242F'
    />
    <path d='M244.56 175.415V192.498H241.405V178.042H237.789V175.382L244.56 175.415Z' fill='#EBEBEB' />
  </svg>
);
