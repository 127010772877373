import { SVGProps } from 'react';

export const CardIllustration = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg {...props} width='278' height='176' viewBox='0 0 278 176' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M269.116 0H8.27754C3.70598 0 0 3.68659 0 8.23423V166.829C0 171.377 3.70598 175.063 8.27754 175.063H269.116C273.688 175.063 277.394 171.377 277.394 166.829V8.23423C277.394 3.68659 273.688 0 269.116 0Z'
      fill='#3ED4A7'
    />
    <g clip-path='url(#clip0_7089_20350)'>
      <path
        d='M229.191 145.618L220.839 164.636H215.563L211.796 149.458C211.567 148.524 211.363 148.182 210.634 147.787C209.444 147.145 207.471 146.543 205.732 146.168L205.858 145.616H214.351C215.433 145.616 216.396 146.326 216.625 147.555L218.573 158.56L223.949 145.616H229.191V145.618ZM249.684 158.426C249.776 153.407 242.717 153.13 242.797 150.887C242.822 150.206 243.49 149.481 244.936 149.295C245.651 149.202 247.624 149.131 249.845 150.145L250.778 146.113C249.583 145.683 248.044 145.27 246.121 145.27C241.189 145.27 237.68 147.855 237.6 151.557C237.529 154.297 240.018 155.822 241.897 156.733C243.827 157.665 244.471 158.263 244.452 159.096C244.42 160.372 242.875 160.935 241.438 160.957C238.927 160.996 237.482 160.287 236.328 159.755L235.367 163.922C236.525 164.449 238.67 164.909 240.902 164.932C246.146 164.932 249.611 162.381 249.682 158.425M262.622 164.634H267.238L263.476 145.616H259.216C258.258 145.616 257.442 146.165 257.071 147.011L249.336 164.636H254.575L255.655 161.795H262.058L262.622 164.636V164.634ZM257.147 157.896L259.874 150.754L261.286 157.896H257.146H257.147ZM236.323 145.618L231.93 164.636H226.938L231.333 145.618H236.323Z'
        fill='black'
      />
    </g>
    <g opacity='0.2'>
      <path
        d='M268.296 0H9.20442C6.76659 -5.32565e-06 4.42833 0.965458 2.70272 2.68451C0.977109 4.40356 0.00510007 6.73579 0 9.16945V55.5741C16.3677 66.9734 38.8589 69.0303 58.8082 64.8204C82.3199 59.9185 103.617 47.9041 125.511 38.0618C147.406 28.2196 171.495 20.3381 195.161 24.3942C217.517 28.2388 236.561 42.0987 256.78 52.3831C263.411 55.8037 270.348 58.5968 277.5 60.7259V9.16945C277.495 6.73579 276.523 4.40356 274.797 2.68451C273.072 0.965458 270.733 -5.32565e-06 268.296 0Z'
        fill='white'
      />
    </g>
    <path
      d='M85.3306 19.1502H80.1406V10.1541H85.3306V11.717H82.0514V13.6922H85.1025V15.2551H82.0514V17.5749H85.3306V19.1502Z'
      fill='black'
    />
    <path
      d='M72.0459 19.1502L69.8762 12.0923H69.8207C69.8988 13.5281 69.9379 14.486 69.9379 14.9659V19.1502H68.2305V10.1541H70.8316L72.9643 17.0334H73.0013L75.2634 10.1541H77.8646V19.1502H76.0832V14.8921C76.0832 14.6911 76.0853 14.4593 76.0894 14.1968C76.0976 13.9342 76.1264 13.2368 76.1757 12.1046H76.1202L73.7964 19.1502H72.0459Z'
      fill='black'
    />
    <path
      d='M65.0326 19.1501L64.3793 17.0087H61.0939L60.4406 19.1501H58.3818L61.5624 10.1171H63.8985L67.0913 19.1501H65.0326ZM63.9231 15.4089C63.3191 13.4686 62.978 12.3712 62.8999 12.1169C62.826 11.8625 62.7725 11.6615 62.7397 11.5139C62.6041 12.0389 62.2157 13.3373 61.5747 15.4089H63.9231Z'
      fill='black'
    />
    <path
      d='M57.2406 19.1502H54.812L50.8918 12.3446H50.8364C50.9144 13.5466 50.9535 14.4039 50.9535 14.9167V19.1502H49.2461V10.1541H51.6562L55.5702 16.8919H55.6133C55.5517 15.7228 55.5209 14.8962 55.5209 14.4121V10.1541H57.2406V19.1502Z'
      fill='black'
    />
    <path
      d='M44.8346 19.1502H42.6649L40.3042 15.3597L39.4967 15.9381V19.1502H37.5859V10.1541H39.4967V14.2706L40.2487 13.2122L42.6896 10.1541H44.81L41.6664 14.1352L44.8346 19.1502Z'
      fill='black'
    />
    <path
      d='M35.3109 19.1502H32.8824L28.9622 12.3446H28.9067C28.9848 13.5466 29.0238 14.4039 29.0238 14.9167V19.1502H27.3164V10.1541H29.7265L33.6405 16.8919H33.6837C33.622 15.7228 33.5912 14.8962 33.5912 14.4121V10.1541H35.3109V19.1502Z'
      fill='black'
    />
    <path
      d='M24.1156 19.1501L23.4623 17.0087H20.1769L19.5236 19.1501H17.4648L20.6454 10.1171H22.9815L26.1744 19.1501H24.1156ZM23.0061 15.4089C22.4021 13.4686 22.061 12.3712 21.9829 12.1169C21.909 11.8625 21.8556 11.6615 21.8227 11.5139C21.6871 12.0389 21.2988 13.3373 20.6577 15.4089H23.0061Z'
      fill='black'
    />
    <path
      d='M10.1182 10.1543H12.9227C14.2007 10.1543 15.1273 10.3368 15.7026 10.7019C16.282 11.0629 16.5717 11.6393 16.5717 12.431C16.5717 12.9684 16.4443 13.4094 16.1895 13.754C15.9389 14.0986 15.604 14.3057 15.1848 14.3754V14.437C15.756 14.5642 16.1669 14.8021 16.4176 15.1508C16.6724 15.4994 16.7998 15.963 16.7998 16.5414C16.7998 17.3618 16.5019 18.0018 15.906 18.4612C15.3143 18.9207 14.5089 19.1504 13.4898 19.1504H10.1182V10.1543ZM12.029 13.717H13.1384C13.6562 13.717 14.0301 13.6371 14.2603 13.4771C14.4945 13.3171 14.6116 13.0525 14.6116 12.6833C14.6116 12.3387 14.4842 12.0926 14.2294 11.9449C13.9788 11.7931 13.5802 11.7172 13.0337 11.7172H12.029V13.717ZM12.029 15.2308V17.5752H13.2741C13.8 17.5752 14.1884 17.4747 14.439 17.2736C14.6897 17.0726 14.815 16.765 14.815 16.3507C14.815 15.6041 14.2808 15.2308 13.2124 15.2308H12.029Z'
      fill='black'
    />
    <defs>
      <clipPath id='clip0_7089_20350'>
        <rect width='61.6457' height='19.6924' fill='white' transform='translate(205.596 145.254)' />
      </clipPath>
    </defs>
  </svg>
);
