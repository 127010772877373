import styled from 'styled-components';
import { List as MuiList, Typography } from '@mui/material';

const Header = styled.div`
  color: ${({
    theme: {
      palette: {
        common: { white },
      },
    },
  }) => white};
  padding: 60px 76px 28px 24px;
  /*! @noflip */
  background: ${({
    theme: {
      palette: {
        primary: { main },
      },
    },
  }) => main};

  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 64px 116px 56px;
    text-align: center;
  }
`;

const List = styled(MuiList)`
  padding: 0;
  li {
    border-bottom: 1px solid
      ${({
        theme: {
          palette: { grey },
        },
      }) => grey[400]};

    &:last-child {
      border: 0;
    }
  }
  a {
    text-decoration: underline;
    color: ${({
      theme: {
        palette: {
          primary: { main },
        },
      },
    }) => main};
  }
`;

const Links = styled.div`
  p {
    padding-bottom: 8px;
    &:last-child {
      padding-bottom: 20px;
    }
  }
`;

const Link = styled(Typography)`
  a {
    font-weight: 700;
    text-decoration: underline;
    color: ${({
      theme: {
        palette: {
          primary: { main },
        },
      },
    }) => main};
  }
`;

export const HowItWorksModalStyles = {
  Header,
  List,
  Links,
  Link,
};
