import { useTranslation } from 'react-i18next';

import 'slick-carousel/slick/slick.css';

import 'slick-carousel/slick/slick-theme.css';

import { ChevronRightIcon, InfoIcon } from 'components';

import { appConfig } from 'App/appConfig/appConfig';

import { FAQSliderStyles } from './FAQSlider.styles';

type FAQSliderProps = {
  onClick: () => void;
  setFaqIndex: (index: number) => void;
};

export const FAQSlider = ({ onClick, setFaqIndex }: FAQSliderProps) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    variableWidth: true,
    draggable: false,
    responsive: [
      {
        breakpoint: 1264,
        settings: {
          draggable: true,
          dots: true,
          slidesToShow: 1,
        },
      },
    ],
  };

  const { t }: any = useTranslation();

  const descriptionContent = t('homePage:faqSlider', { returnObjects: true }).map((content: string, index: number) => (
    <FAQSliderStyles.Slide
      onClick={() => {
        if (index == 1 || index == 2) setFaqIndex(index == 1 ? 0 : 1);
        else setFaqIndex(-1);
        onClick();
      }}
    >
      <div>
        <InfoIcon color={appConfig.theme.palette.common.black} />
        <FAQSliderStyles.Content variant='subtitle3'>{content}</FAQSliderStyles.Content>
      </div>
      <ChevronRightIcon color={appConfig.theme.palette.primary.main} size={24} />
    </FAQSliderStyles.Slide>
  ));

  return (
    <FAQSliderStyles.Wrapper>
      <FAQSliderStyles.Title variant='subtitle2'>{t('homePage:faqSliderTitle')}</FAQSliderStyles.Title>
      <FAQSliderStyles.Slider {...settings}>{descriptionContent}</FAQSliderStyles.Slider>
    </FAQSliderStyles.Wrapper>
  );
};
