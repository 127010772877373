import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface IDKIconProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const IDKIcon = ({ props, isActive }: IDKIconProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_7253_31857)'>
        <path
          d='M21.4844 21.4844C21.4845 20.8399 21.6618 20.2079 21.9969 19.6573C22.3319 19.1068 22.8119 18.6589 23.3843 18.3627C23.9567 18.0665 24.5996 17.9334 25.2425 17.9779C25.8855 18.0223 26.5039 18.2427 27.03 18.6148C27.5562 18.987 27.97 19.4966 28.2261 20.088C28.4822 20.6794 28.5708 21.3299 28.4822 21.9683C28.3936 22.6066 28.1314 23.2085 27.7239 23.7078C27.3164 24.2071 26.7795 24.5848 26.1719 24.7996C25.829 24.9209 25.5321 25.1454 25.3223 25.4424C25.1124 25.7395 24.9998 26.0942 25 26.4578V27.6367'
          stroke={isActiveState ? '#ffffff' : main}
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M25 32.0312C24.7573 32.0312 24.5605 31.8345 24.5605 31.5918C24.5605 31.3491 24.7573 31.1523 25 31.1523'
          stroke={isActiveState ? '#ffffff' : main}
          stroke-width='1.5'
        />
        <path
          d='M25 32.0312C25.2427 32.0312 25.4395 31.8345 25.4395 31.5918C25.4395 31.3491 25.2427 31.1523 25 31.1523'
          stroke={isActiveState ? '#ffffff' : main}
          stroke-width='1.5'
        />
        <path
          d='M25 36C31.0751 36 36 31.0751 36 25C36 18.9249 31.0751 14 25 14C18.9249 14 14 18.9249 14 25C14 31.0751 18.9249 36 25 36Z'
          stroke={isActiveState ? '#ffffff' : main}
          stroke-width='1.5'
          stroke-miterlimit='10'
        />
      </g>
      <defs>
        <clipPath id='clip0_7253_31857'>
          <rect width='30' height='30' fill='white' transform='translate(10 10)' />
        </clipPath>
      </defs>
    </svg>
  );
};
