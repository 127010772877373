import { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import dompurify from 'dompurify';

import { Grid, Container } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { color } from 'App/style';

import { Header } from 'components';

import { handleSkipProfile } from 'App/utils';

import { Layout, BASIC_GRADIENT_BACKGROUND_HEIGHT, ProfileSettingsSlider, Modal } from 'App/components';

import { RoutePaths } from 'impactApp/routes/routePaths';

import { Button, ButtonAppearance, LogoutButton } from 'components';

import {
  ProfileSettingsIllustartion,
  ProfileSettingsIllustartion2,
  ProfileSettingsIllustartion3,
  ProfileSettingsIllustartion4,
} from './assets';

import { ProfileSettingsStyles } from './ProfileSettings.styles';

export const ProfileSettings = () => {
  const { t }: any = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();

  const { HOME, PROFILE_SETTINGS, CARD_PAGE } = RoutePaths;
  const [step, setStep] = useState(0);
  const [isSliderVisible, setIsSliderVisible] = useState(false);

  const sanitizer = dompurify.sanitize;

  const closeSlider = () => {
    setIsSliderVisible(false);
    navigate(HOME);
  };

  const profileSettingsSteps = t('profileSettings:steps', { returnObjects: true });

  const illustrationObject: {
    [key: number]: JSX.Element;
  } = {
    0: <ProfileSettingsIllustartion />,
    1: <ProfileSettingsIllustartion2 />,
    2: <ProfileSettingsIllustartion3 />,
    3: <ProfileSettingsIllustartion4 />,
  };

  const location = useLocation();

  useEffect(() => {
    document.title = 'CO2 Profile';
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const stepParam = queryParams.get('step');

    if (stepParam === '3') {
      setStep(3);
    }
  }, [location.search]);

  useEffect(() => {
    if (step === 3) {
      setIsSliderVisible(false);
    }
  }, [step]);

  return (
    <Layout
      bgHeight={BASIC_GRADIENT_BACKGROUND_HEIGHT}
      hideContainer
      bgColor
      isFullHeight
      header={
        isSliderVisible ? (
          <Header
            title={t('profileSettings:sliderTitle')}
            transparent
            isWhite
            hideNavbar
            showDesktopNavigation
            hideLeftButton
            hideRightButton
          />
        ) : step === 4 ? (
          <Header
            transparent
            hideLeftButton
            rightButton={
              <ProfileSettingsStyles.IconWrapper>
                <LogoutButton />
              </ProfileSettingsStyles.IconWrapper>
            }
          />
        ) : undefined
      }
    >
      {step !== 0 && (
        <ProfileSettingsStyles.CloseIcon hoverColor={color.highlightRed} onClick={() => setModalOpen(true)} />
      )}
      <Modal
        slug='SKIPPROFILE'
        setOpen={setModalOpen}
        isOpen={isModalOpen}
        onCancel={() => {
          handleSkipProfile(PROFILE_SETTINGS);
          closeSlider();
        }}
        onClose={() => setModalOpen(false)}
        content={t('common:closeProfileSettingsModal:content')}
        successButtonText={t('common:closeProfileSettingsModal:ja')}
        cancelButtonText={t('common:closeProfileSettingsModal:nein')}
      />
      {isSliderVisible ? (
        <ProfileSettingsSlider setStep={setStep} />
      ) : (
        <ProfileSettingsStyles.CurveWrapper>
          <Container>
            <Grid
              container
              columns={{ sm: 5, md: 8, lg: 12 }}
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              textAlign='center'
            >
              <ProfileSettingsStyles.ImgWrapper>
                <ProfileSettingsStyles.Img>{illustrationObject[step]}</ProfileSettingsStyles.Img>
              </ProfileSettingsStyles.ImgWrapper>
              <Grid item md={5}>
                <ProfileSettingsStyles.TextWrapper variant='h6'>
                  {profileSettingsSteps[step].title}
                </ProfileSettingsStyles.TextWrapper>
                <ProfileSettingsStyles.TextWrapper
                  variant='body1'
                  dangerouslySetInnerHTML={{ __html: sanitizer(profileSettingsSteps[step].text) }}
                />
                {step === 3 && (
                  <ProfileSettingsStyles.LinkWrapper
                    onClick={() => navigate(CARD_PAGE)}
                    variant='subtitle3'
                    dangerouslySetInnerHTML={{ __html: sanitizer(t('profileSettings:cardButton')) }}
                  />
                )}
              </Grid>
              {profileSettingsSteps[step].buttonText && (
                <ProfileSettingsStyles.ButtonWrapper>
                  <Button
                    appearance={ButtonAppearance.SECONDARY}
                    onClick={() => (step === 2 ? setIsSliderVisible(true) : setStep(step + 1))}
                    text={profileSettingsSteps[step].buttonText}
                  />
                </ProfileSettingsStyles.ButtonWrapper>
              )}
            </Grid>
          </Container>
        </ProfileSettingsStyles.CurveWrapper>
      )}
    </Layout>
  );
};
