import { routesNavigationList, NavigationItem, NavigationRoutes, isNavigationItem } from 'App/routes';

import { Logo } from 'components/Logo';

import { useContext } from 'react';

import { FeedbackContext } from 'App/context';

import { Typography } from '@mui/material';

import { RoutePaths } from 'impactApp/routes/routePaths';

import { NavLink, useLocation } from 'react-router-dom';

import { handleNavigationLink } from 'App/utils';

import { useTranslation } from 'react-i18next';

import { NavbarStyles } from './Navbar.styles';

export const Navbar = () => {
  const { t }: any = useTranslation();

  const { HOME, LOGOUT_PAGE } = RoutePaths;

  const { setIsFeedbackModalVisible } = useContext(FeedbackContext);

  const { pathname } = useLocation();

  const renderNavigationItem = () => (item: NavigationRoutes | NavigationItem) => {
    const { id, name } = item;

    if (isNavigationItem(item)) {
      return (
        <NavbarStyles.LinkItem key={id} to={HOME} onClick={() => handleNavigationLink(pathname, name, HOME)}>
          {t(name)}
        </NavbarStyles.LinkItem>
      );
    }

    const { link, outlink } = item;

    if (outlink)
      return (
        <NavbarStyles.LinkItem
          key={id}
          to={{ pathname: link }}
          target='_blank'
          onClick={() => handleNavigationLink(pathname, t(name), link)}
        >
          <Typography variant='subtitle3'> {t(name)} </Typography>
        </NavbarStyles.LinkItem>
      );

    if (link === LOGOUT_PAGE)
      return (
        <NavbarStyles.PlainLink
          key={id}
          onClick={() => {
            if (setIsFeedbackModalVisible) setIsFeedbackModalVisible(true);
            handleNavigationLink(pathname, t(name), link);
          }}
        >
          <Typography variant='subtitle3'> {t(name)} </Typography>
        </NavbarStyles.PlainLink>
      );

    return (
      <NavbarStyles.LinkItem key={id} to={link} onClick={() => handleNavigationLink(pathname, t(name), link)}>
        <Typography variant='subtitle3'> {t(name)} </Typography>
      </NavbarStyles.LinkItem>
    );
  };

  const navigation = routesNavigationList.map(renderNavigationItem());

  return (
    <NavbarStyles.Wrapper>
      <NavbarStyles.LogoWrapper>
        <NavLink to={HOME}>
          <Logo />
        </NavLink>
        <NavbarStyles.Title variant='subtitle2'>{t('homePage:appName')}</NavbarStyles.Title>
      </NavbarStyles.LogoWrapper>
      <NavbarStyles.LinksWrapper>{navigation}</NavbarStyles.LinksWrapper>
    </NavbarStyles.Wrapper>
  );
};
