import { useEffect, useState } from 'react';
import {
  useQueryData,
  GetTotalCO2MonthlyResponse,
  MonthYearInput,
  DEFAULT_OVERRIDE_CACHE_TIME,
  MetaCategoryType,
  useGetCO2Monthly,
} from '@ecolytiq/js-data-provider';

import { TotalCo2Monthly } from 'impactApp/modules/HomePage';
import { useDeps } from 'App/context';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from 'impactApp/routes';

export const useCO2MonthlyData = (month: MonthYearInput, category: MetaCategoryType) => {
  const { httpClientService } = useDeps();
  const [response, setResponse] = useState<Omit<TotalCo2Monthly, 'isLoading'>>({
    totalCo2ByMonth: {},
  });
  const { totalCo2ByMonth } = response;
  const navigate = useNavigate();
  const { SSO_PAGE } = RoutePaths;
  const { isLoading, isFetching, data } = useQueryData<{ data: GetTotalCO2MonthlyResponse }>(
    useGetCO2Monthly(httpClientService, month, category),
    {
      staleTime: DEFAULT_OVERRIDE_CACHE_TIME,
      cacheTime: 0,
    },
  );

  useEffect(() => {
    if (data?.data.totalCo2ByMonth) {
      setResponse({
        totalCo2ByMonth: data.data.totalCo2ByMonth,
      });
    } else if (!isLoading && !data) {
      navigate(SSO_PAGE);
    }
  }, [data, setResponse, navigate, SSO_PAGE, isLoading]);

  return {
    isLoading: isLoading || isFetching,
    totalCo2ByMonth,
  };
};
