export const routes = {
  homePage: 'Dashboard',
  reports: 'Reports',
  transactions: 'Transactions',
  transactionsEdit: 'TransactionsEdit',
  profileSettings: 'ProfileSettings',
  foodSettings: 'FoodSettings',
  logoutPage: 'Logout',
  ssoPage: 'SSO',
  takeAction: 'Take action',
  landingPage: 'LandingPage',
  cardPage: 'CardPage',
  feedbackPage: 'FeedbackPage',
  faqPage: 'FAQPage',
  registerComplete: 'RegisterComplete',
  accountConfirmation: 'AccountConfirmation',
  passwordReset: 'PasswordReset',
  passwordRecovery: 'PasswordRecovery',
  pageNotFound: 'PageNotFound',
  staticPage: 'StaticPage',
  helpPage: 'HelpPage',
};
