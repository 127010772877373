import styled from 'styled-components';
import { Typography } from '@mui/material';
import { Button } from 'components';

const LogoWrapper = styled.div`
  padding: 56px 0;
  width: 216px;
  margin: 0 auto;
  img {
    width: 100%;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 60px 0 48px;
    width: 280px;
  }
`;

const SVGWrapper = styled.div`
  width: 320px;
  height: 320px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 50%;
    margin: 0 auto;
  }
`;

const Title = styled(Typography)`
  color: ${({ theme }) => `${theme.palette.common.white}`};
  text-align: center;
  margin-bottom: 40px;
  padding: 0 32px;
`;

const Text = styled(Typography)`
  color: ${({ theme }) => `${theme.palette.common.white}`};
  text-align: center;
  margin-bottom: 50px;
  padding: 0 32px;
  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-bottom: 60px;
    padding: 0 128px;
  }
`;

const ButtonWrapper = styled(Button)`
  margin: 20px auto 0;
`;

export const PageNotFoundStyles = {
  Container,
  Title,
  Text,
  ButtonWrapper,
  LogoWrapper,
  SVGWrapper,
};
