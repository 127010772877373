import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface DieselProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const Diesel = ({ props, isActive }: DieselProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.6428 24.0179L29.4643 33.8393'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M29.4643 24.0179L19.6428 33.8393'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M35.3571 36.7857C35.3571 37.3067 35.1502 37.8063 34.7818 38.1747C34.4134 38.5431 33.9138 38.75 33.3929 38.75H15.7143C15.1933 38.75 14.6937 38.5431 14.3253 38.1747C13.957 37.8063 13.75 37.3067 13.75 36.7857V17.1429C13.75 16.6219 13.957 16.1223 14.3253 15.7539C14.6937 15.3855 15.1933 15.1786 15.7143 15.1786H28.4821L35.3571 22.0536V36.7857Z'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M15.7144 15.1786V13.2143C15.7144 12.6933 15.9213 12.1937 16.2897 11.8253C16.6581 11.457 17.1577 11.25 17.6786 11.25H23.5715C24.0925 11.25 24.5921 11.457 24.9605 11.8253C25.3288 12.1937 25.5358 12.6933 25.5358 13.2143V15.1786'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M29.4644 16.1607L31.0214 14.6037C31.2038 14.4212 31.4204 14.2764 31.6588 14.1776C31.8972 14.0788 32.1527 14.028 32.4108 14.028C32.6688 14.028 32.9244 14.0788 33.1628 14.1776C33.4012 14.2764 33.6178 14.4212 33.8002 14.6037L35.9321 16.7356C36.1146 16.918 36.2594 17.1346 36.3582 17.373C36.457 17.6114 36.5079 17.867 36.5079 18.125C36.5079 18.3831 36.457 18.6386 36.3582 18.877C36.2594 19.1154 36.1146 19.332 35.9321 19.5144L34.3751 21.0715'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
