import styled from 'styled-components';
import { footerHeight, footerMobileHeight } from 'App/style/theme';
import { Typography } from '@mui/material';
import { CheckOutlineIcon } from 'components/Icons/CheckOutlineIcon';

const Icon = styled(CheckOutlineIcon)`
  margin: 0 auto 20px;
  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-bottom: 40px;
  }
`;

const Wrapper = styled.div`
  min-height: calc(100vh - ${footerMobileHeight});
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 32px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    min-height: calc(100vh - ${footerHeight});
    max-width: 444px;
    align-items: center;
    margin: 0 auto;
    text-align: center;
  }
`;

const Title = styled(Typography)`
  color: ${({ theme }) => `${theme.palette.common.white}`};
  margin-bottom: 20px;
  text-align: center;
  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-bottom: 30px;
  }
`;

const TextWrapper = styled(Typography)`
  color: ${({ theme }) => `${theme.palette.common.white}`};
  margin-bottom: 50px;
  text-align: center;
`;

export const PasswordResetStyles = {
  Wrapper,
  Icon,
  Title,
  TextWrapper,
};
