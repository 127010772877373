import { Typography } from '@mui/material';

import { useContext } from 'react';

import { useNavigate } from 'react-router-dom';

import { FeedbackContext, setSessionLogoutAction, useUserContext } from 'App/context';

import { Button, ButtonAppearance, CloseIcon } from 'components';

import { useTranslation } from 'react-i18next';

import { handleUserLogout } from '../../utils';

import { FeedbackIllutstration } from './assets';

import { FeedBackModalStyles } from './FeedbackModal.styles';

type FeedbackModalProps = {
  feedbackUrl: string;
};

export const FeedbackModal = ({ feedbackUrl }: FeedbackModalProps) => {
  const { t }: any = useTranslation();

  const { isFeedbackModalVisible, setIsFeedbackModalVisible } = useContext(FeedbackContext);

  const [{}, dispatch] = useUserContext();

  const navigate = useNavigate();

  const hideModal = () => {
    if (setIsFeedbackModalVisible) setIsFeedbackModalVisible(false);
  };

  const handleLogout = () => {
    hideModal();
    dispatch(setSessionLogoutAction(true));
    handleUserLogout();
  };

  const handleFeedback = () => {
    hideModal();
    navigate(feedbackUrl);
  };

  return (
    <FeedBackModalStyles.DialogWrapper scroll='paper' open={isFeedbackModalVisible} onClose={hideModal}>
      <FeedBackModalStyles.Content>
        <FeedBackModalStyles.IconWrapper onClick={hideModal}>
          <CloseIcon />
        </FeedBackModalStyles.IconWrapper>
        <FeedBackModalStyles.Header>
          <Typography color='grey[600]' variant='subtitle1'>
            {t('feedback:modal:title')}
          </Typography>
        </FeedBackModalStyles.Header>
        <FeedBackModalStyles.IllustrationWrapper>
          <FeedbackIllutstration />
        </FeedBackModalStyles.IllustrationWrapper>
        <FeedBackModalStyles.Text color='grey[600]' variant='body2'>
          {t('feedback:modal:text')}
        </FeedBackModalStyles.Text>
        <Button
          appearance={ButtonAppearance.PRIMARY}
          text={t('feedback:modal:submitButton')}
          onClick={handleFeedback}
        />
        <FeedBackModalStyles.CancelButton color='primary.main' onClick={handleLogout} variant='subtitle2'>
          {t('feedback:modal:laterButton')}
        </FeedBackModalStyles.CancelButton>
      </FeedBackModalStyles.Content>
    </FeedBackModalStyles.DialogWrapper>
  );
};
