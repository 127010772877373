import styled from 'styled-components';

import { Typography } from '@mui/material';

export const CustomTooltipStyles = styled(Typography)`
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
  background: ${({ theme }) => `${theme.palette.primary.light}`};
  color: ${({ theme }) => `${theme.palette.grey[400]}`};
`;
