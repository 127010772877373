export const handleUserLogin = (userId: string) => {
  // eslint-disable-next-line no-underscore-dangle
  window?._mtm?.push({
    'eco_account-id': userId,
  });
};

export const handleUserLogout = () => {
  // eslint-disable-next-line no-underscore-dangle
  window?._mtm?.push({
    'eco_account-id': undefined,
  });
};

export const handlePageRoute = (pathName: string) => {
  // eslint-disable-next-line no-underscore-dangle
  window?._mtm?.push({
    event: 'pageview',
    'eco_page-path': pathName, // Fill in the url slug of the current page
  });
};

export const handleFootPrintRefinement = (pathName: string, category: string) => {
  // eslint-disable-next-line no-underscore-dangle
  window?._mtm?.push({
    event: 'footprint-refined',
    'eco_page-path': pathName, // Fill in the url slug of the current page
    eco_category: category, // Fill in the category of the transaction if available
  });
};

export const handleClickedButton = (text: string, pathName: string, newPathName: string) => {
  // eslint-disable-next-line no-underscore-dangle
  window?._mtm?.push({
    event: 'button_click',
    'eco_click-text': text, // Fill in the text of the button clicked
    'eco_page-path': pathName, // Fill in the url slug of the current page
    'eco_click-path': newPathName, // Fill in the url slug of the url, where the button points to
  });
};

export const handleOpenedOverlay = (slug: string, pathName: string) => {
  // eslint-disable-next-line no-underscore-dangle
  window?._mtm?.push({
    event: 'overlay_pageview',
    'eco_page-path': pathName, // Fill in the url slug of the current page
    'eco_overlay-path': slug, // Give each overlay a virtual url slugs to track the overlays as pages
  });
};

export const handleOutlink = (outlink: string, pathName: string) => {
  // eslint-disable-next-line no-underscore-dangle
  window?._mtm?.push({
    event: 'outlink',
    'eco_page-path': pathName, // Fill in the url slug of the current page
    'eco_goal-outlink': outlink, // Fill in the url of the outlink
  });
};

export const handleNavigationLink = (pathName: string, text: string, newLink: string) => {
  // eslint-disable-next-line no-underscore-dangle
  window?._mtm?.push({
    event: 'navigation_click',
    'eco_page-path': pathName, // Fill in the url slug of the current page
    'eco_click-text': text, // Fill in the text of the nav item clicked
    'eco_click-path': newLink, // Fill in the url slug of the url that has been clicked
  });
};

export const handleInfoIcon = (pathName: string, text: string) => {
  // eslint-disable-next-line no-underscore-dangle
  window?._mtm?.push({
    event: 'INFO',
    'eco_page-path': pathName, // Fill in the url slug of the current page
    'eco_click-text': text, // Fill in the text of the nav item clicked
  });
};

export const handleSkipProfile = (pathName: string) => {
  // eslint-disable-next-line no-underscore-dangle
  window?._mtm?.push({
    event: 'NOPROFILE',
    'eco_page-path': pathName, // Fill in the url slug of the current page
  });
};
