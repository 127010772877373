import styled from 'styled-components';
import SlickSlider from 'react-slick';
import { Typography } from '@mui/material';

const Wrapper = styled.div`
  padding: 40px 24px 64px;
  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 40px 0;
  }
`;

const Slider = styled(SlickSlider)`
  margin: 0 -24px;
  .slick-list {
    padding: 0 24px;
  }
  .slick-track {
    display: flex;
    gap: 8px;
    ${(props) => props.theme.breakpoints.up('md')} {
      gap: 24px;
    }
  }
  .slick-dots {
    li {
      margin: 0;
    }

    button {
      &:before {
        font-size: 10px;
        color: ${({ theme }) => `${theme.palette.grey[300]}`};
        opacity: 1;
      }
    }

    .slick-active {
      button {
        &:before {
          opacity: 1;
          color: ${({ theme }) => `${theme.palette.primary.main}`};
        }
      }
    }
  }
`;

const Content = styled(Typography)`
  white-space: nowrap;
  margin-left: 8px;
`;

const Slide = styled.div`
  cursor: pointer;
  color: ${({ theme }) => `${theme.palette.common.black}`};
  background: ${({ theme }) => `${theme.palette.common.white}`};
  border: 1px solid ${({ theme }) => `${theme.palette.common.black}`};
  border-radius: 12px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  min-height: 76px;
  padding-left: 24px;
  padding-right: 10px;
  min-width: 327px;
  > div {
    display: flex;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
    }
  }
  svg {
    width: 24px;
    height: 24px;
    * {
      stroke: ${({ theme }) => `${theme.palette.primary.main}`};
    }
  }
`;

const Title = styled(Typography)`
  color: ${({ theme }) => `${theme.palette.common.black}`};
  margin-bottom: 24px;

  ${(props) => props.theme.breakpoints.up('md')} {
    color: ${({ theme }) => `${theme.palette.common.white}`};
  }
`;

export const FAQSliderStyles = {
  Wrapper,
  Slider,
  Slide,
  Title,
  Content,
};
