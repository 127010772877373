import { useEffect } from 'react';

import { Layout } from 'App/components';

import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { ButtonAppearance } from 'components';

import { appConfig } from 'App/appConfig/appConfig';

import { PageNotFoundStyles } from './PageNotFound.styles';
import { NotFoundIllustration } from './assets/NotFoundIllustration';

export const PageNotFound = () => {
  const { t }: any = useTranslation(['pageNotFound']);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'CO2 404';
  });

  return (
    <Layout bgColor isFullHeight>
      <PageNotFoundStyles.LogoWrapper>
        <img src={appConfig.logo} />
      </PageNotFoundStyles.LogoWrapper>
      <PageNotFoundStyles.Container>
        <PageNotFoundStyles.SVGWrapper>
          <NotFoundIllustration />
        </PageNotFoundStyles.SVGWrapper>

        <PageNotFoundStyles.Title variant='h6'>{t('pageNotFound:title')}</PageNotFoundStyles.Title>
        <PageNotFoundStyles.Text>{t('pageNotFound:text')}</PageNotFoundStyles.Text>
        <PageNotFoundStyles.ButtonWrapper
          appearance={ButtonAppearance.SECONDARY}
          onClick={() => navigate(-1)}
          text={t('pageNotFound:button')}
        />
      </PageNotFoundStyles.Container>
    </Layout>
  );
};
