import { RequestHandler } from 'msw';
import { setupServer, SetupServerApi } from 'msw/node';

type ServerService = {
  service: SetupServerApi | null;
  server: SetupServerApi | null;
  resetHandlers: () => void;
  close: () => void;
  start: (handlers: RequestHandler[]) => void;
  listen: (options: Record<string, unknown>) => void;
  addHandlers: (handlers: RequestHandler[]) => void;
};

export const serverService: ServerService = {
  service: null,
  set server(server) {
    this.service = server;
  },
  get server() {
    return this.service;
  },
  start(handlers: RequestHandler[]) {
    if (!this.server) {
      this.server = setupServer(...handlers);
    }
  },
  listen(options) {
    if (this.server) {
      this.server.listen(options);
    }
  },
  resetHandlers() {
    if (this.server) {
      this.server.resetHandlers();
    }
  },
  close() {
    if (this.server) {
      this.server.close();
    }
  },
  addHandlers(handlers: RequestHandler[]) {
    if (!this.server) {
      throw new Error("can't add handlers");
    }

    this.server.use(...handlers);
  },
};
