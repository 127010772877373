import { Config } from 'App/config';

import { useMutateData, PostSessionResponse, useLogoutSession } from '@ecolytiq/js-data-provider';

import { useNavigate } from 'react-router-dom';

import { useEffect } from 'react';

import { useUserContext, useDeps, resetStateAction } from 'App/context';

import { useCookies } from 'react-cookie';

import { RoutePaths } from 'impactApp/routes/routePaths';

import { appConfig } from 'App/appConfig/appConfig';

export const useLogoutSessionData = () => {
  const { httpClientServiceOAuth } = useDeps();

  const [{ sessionLogout }, dispatch] = useUserContext();

  const { REACT_APP_ECOLYTIQ_KEYCLOAK_CLIENT_ID } = Config.getAll();

  const navigate = useNavigate();
  const { SSO_PAGE, LOGOUT_PAGE } = RoutePaths;

  const { REACT_APP_ACCESS_TOKEN_COOKIE_NAME, REACT_APP_REFRESH_TOKEN_COOKIE_NAME } = appConfig;

  const [cookies, , removeCookie] = useCookies([REACT_APP_REFRESH_TOKEN_COOKIE_NAME]);

  const refreshToken = cookies[REACT_APP_REFRESH_TOKEN_COOKIE_NAME];

  const { mutate: sessionMutate } = useMutateData<PostSessionResponse>(
    useLogoutSession(httpClientServiceOAuth, refreshToken, REACT_APP_ECOLYTIQ_KEYCLOAK_CLIENT_ID as string),
    {
      onSuccess: async () => {
        if (cookies[REACT_APP_ACCESS_TOKEN_COOKIE_NAME]) {
          removeCookie(REACT_APP_ACCESS_TOKEN_COOKIE_NAME, { path: '/' });
          removeCookie(REACT_APP_REFRESH_TOKEN_COOKIE_NAME, { path: '/' });
        }
        dispatch(resetStateAction());
        navigate(LOGOUT_PAGE);
      },
      onError: async () => {
        navigate(SSO_PAGE);
      },
    },
  );
  useEffect(() => {
    if (sessionLogout) sessionMutate();
  }, [sessionLogout, sessionMutate]);
};
