import { parse, format, parseISO } from 'date-fns';

const formatMonthFromDate = (date: Date) => format(date, 'MMMM');
const formatToSimpleDate = (date: string) => format(new Date(date), 'yyyy-MM-dd');
const formatFullDate = (date: string, locale: string) => {
  const updatedDate = date[date.length - 1] === 'Z' ? date.substring(0, date.length - 1) : date;
  return format(
    parse(formatToSimpleDate(updatedDate), 'yyyy-MM-dd', new Date()),
    locale === 'en' ? 'MMMM dd, yyyy' : 'dd MMMM yyyy',
  );
};
const formatMonthAndYear = (date: string) => format(parseISO(date), 'MMMM yyyy');
const formatMonthAndYearFromDate = (date: Date) => format(date, 'MMMM yyyy');
const formatFullISO = (date: string) => format(parseISO(date), "yyyy MM dd'T'HH:mm:ss'Z'");
const formatEnrichedDataDate = (date: string): string => format(parseISO(date), "yyyy-MM-dd'T'HH:mm:ss'Z'");

export {
  formatFullDate,
  formatMonthAndYear,
  formatFullISO,
  formatEnrichedDataDate,
  formatMonthFromDate,
  formatMonthAndYearFromDate,
};
