import styled from 'styled-components';
import { Typography } from '@mui/material';
import { Paper } from 'components';

const Wrapper = styled(Paper.RoundedTop)`
  padding: 20px 8px 24px;
  z-index: 1;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 465px;
    padding: 56px 28px;
    box-shadow: 0px 8px 16px ${({ theme }) => theme.palette.custom.boxShadow.color};
  }
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  > div {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: auto;
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${({
    theme: {
      palette: { grey },
    },
  }) => grey[400]};
  margin: 0px 20px 12px;
  ${(props) => props.theme.breakpoints.up('md')} {
    margin-left: 4px;
    margin-right: 4px;
  }
`;

const Text = styled(Typography)`
  text-align: center;
  color: ${({ theme }) => theme.palette.common.white};
  font-weight: 600;
  padding: 36px 0;
`;

const ArrowWrapper = styled.div`
  position: absolute;
  z-index: 3;
  top: 36px;
  left: 24px;
  cursor: pointer;
  path {
    stroke: ${({ theme }) => theme.palette.common.white};
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    top: 56px;
  }
`;

export const ProfileSettingsSliderStyles = {
  Wrapper,
  ButtonWrapper,
  Text,
  Divider,
  Container,
  ArrowWrapper,
};
