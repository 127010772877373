import { useEffect } from 'react';

import { Layout } from 'App/components';

import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { ButtonAppearance } from 'components';

import dompurify from 'dompurify';

import { appConfig } from 'App/appConfig/appConfig';

import { LogoutStyles } from './LogoutPage.styles';

export const LogoutPage = () => {
  const { t }: any = useTranslation(['logout']);
  const sanitizer = dompurify.sanitize;
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'CO2 Logout';
  });

  return (
    <Layout bgColor isFullHeight>
      <LogoutStyles.LogoWrapper>
        <img src={appConfig.logo} />
      </LogoutStyles.LogoWrapper>
      <LogoutStyles.Wrapper>
        <LogoutStyles.Icon />
        <LogoutStyles.Title
          variant='h6'
          dangerouslySetInnerHTML={{
            __html: sanitizer(t('logout:title')),
          }}
        />

        <LogoutStyles.TextWrapper>{t('logout:text')}</LogoutStyles.TextWrapper>
        <LogoutStyles.ButtonWrapper
          appearance={ButtonAppearance.SECONDARY}
          onClick={() => navigate('/')}
          text={t('logout:button')}
        />
      </LogoutStyles.Wrapper>
    </Layout>
  );
};
