import { SVGProps } from 'react';

export const CheckOutlineIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg {...props} width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clip-path='url(#clip0_4285_27608)'>
      <path
        d='M6.65625 12L10.3132 15.751L18.1562 8.5'
        stroke='white'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1.15625 12C1.15625 14.9174 2.31518 17.7153 4.37808 19.7782C6.44098 21.8411 9.23887 23 12.1563 23C15.0736 23 17.8715 21.8411 19.9344 19.7782C21.9973 17.7153 23.1563 14.9174 23.1563 12C23.1563 9.08262 21.9973 6.28473 19.9344 4.22183C17.8715 2.15893 15.0736 1 12.1563 1C9.23887 1 6.44098 2.15893 4.37808 4.22183C2.31518 6.28473 1.15625 9.08262 1.15625 12V12Z'
        stroke='white'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_4285_27608'>
        <rect width='24' height='24' fill='white' transform='translate(0.15625)' />
      </clipPath>
    </defs>
  </svg>
);
