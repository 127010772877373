import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface RoundTripProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const RoundTrip = ({ props, isActive }: RoundTripProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} width='51' height='51' viewBox='0 0 51 51' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M36.9258 33.604C37.392 33.604 37.8391 33.4188 38.1687 33.0892C38.4984 32.7595 38.6836 32.3124 38.6836 31.8462C38.6836 31.38 38.4984 30.9329 38.1687 30.6033C37.8391 30.2736 37.392 30.0884 36.9258 30.0884H34.2891L30.7734 24.815H29.0156L29.8945 30.0884H25.5L22.8633 29.2095V34.4829L25.5 33.604H29.8945L29.0156 38.8775H31.6523L34.2891 33.604H36.9258Z'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M19.3477 31.8462H17.5898'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M14.0742 31.8462H12.3164'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M14.0742 21.2993C13.608 21.2993 13.1609 21.1141 12.8313 20.7845C12.5016 20.4548 12.3164 20.0077 12.3164 19.5415C12.3164 19.0753 12.5016 18.6282 12.8313 18.2986C13.1609 17.9689 13.608 17.7837 14.0742 17.7837H16.7109L20.2266 12.5103H21.9844L21.1055 17.7837H25.5L28.1367 16.9048V22.1783L25.5 21.2993H21.1055L21.9844 26.5728H19.3477L16.7109 21.2993H14.0742Z'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M31.6523 19.5415H33.4102'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M36.9258 19.5415H38.6836'
        stroke={isActiveState ? '#ffffff' : main}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
