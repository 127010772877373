import { Link, useLocation, useNavigate } from 'react-router-dom';

import { handleOutlink } from 'App/utils';

import { appConfig } from 'App/appConfig/appConfig';

import { useMediaQuery } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { Logo, LanguageSwitcher } from 'components';

import { theme } from '../../App/style/theme';

import { useStaticPagesData } from './useStaticPagesData';

import { Footer } from './Footer.style';

export const FooterSection = ({ helpPageUrl }: { helpPageUrl: string }) => {
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const { pathname } = useLocation();
  const { t }: any = useTranslation(['footer', 'poc']);
  const { staticPages } = useStaticPagesData();
  const navigate = useNavigate();

  const footerElements = staticPages.map(
    (page) =>
      page.attributes.footerTitle && (
        <Footer.Element key={page.attributes.slug} title={page.attributes.title}>
          <Footer.Link
            color='common.white'
            onClick={() => navigate(`/page/${page.id}`)}
            variant={isMd ? 'caption' : 'label'}
          >
            {page.attributes.footerTitle}
          </Footer.Link>
        </Footer.Element>
      ),
  );
  return (
    <Footer.Wrapper>
      <Footer.List>
        <Footer.Element>
          <Link
            to={{ pathname: t('footer:link') }}
            target='_blank'
            onClick={() => handleOutlink(t('footer:link'), pathname)}
          >
            <Logo $infooter />
          </Link>
        </Footer.Element>
        {footerElements}
        <Footer.Element>
          <Footer.Link color='common.white' onClick={() => navigate(helpPageUrl)} variant={isMd ? 'caption' : 'label'}>
            {t('helpPage:title')}
          </Footer.Link>
        </Footer.Element>
        {appConfig.languageSwitcher && <LanguageSwitcher />}
      </Footer.List>
    </Footer.Wrapper>
  );
};
