import { RoutePaths as visaRoutePaths } from 'impactApp/routes/routePaths';

import { NavigationRoutes, NavigationItem } from './types';

const { HOME, LOGOUT_PAGE } = visaRoutePaths;

export const routesNavigationList: Array<NavigationRoutes | NavigationItem> = [
  {
    name: 'routes:homePage',
    id: 'id_home_page',
    link: HOME,
  },

  {
    name: 'routes:logoutPage',
    id: 'id_logout_page',
    link: LOGOUT_PAGE,
  },
];
