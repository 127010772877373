import styled from 'styled-components';
import { Typography } from '@mui/material';

const Container = styled.div`
  ${(props) => props.theme.breakpoints.down('lg')} {
    background: ${({
      theme: {
        palette: {
          secondary: { main },
        },
      },
    }) => main};
  }
`;

const Wrapper = styled.div`
  background: ${({
    theme: {
      palette: {
        common: { white },
      },
    },
  }) => white};
  color: ${({
    theme: {
      palette: { grey },
    },
  }) => grey[600]};
  padding: 0 24px 60px;
  height: 100%;
  width: 100%;
  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 52px 0 56px;
  }
`;

const Text = styled(Typography)`
  padding: 26px 16px;
  text-align: center;
  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 40px 0 80px;
    max-width: 380px;
    margin: 0 auto;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: center;
`;

export const FeedbackPageStyles = {
  Wrapper,
  Text,
  ButtonWrapper,
  Container,
};
