import { useTheme } from 'styled-components';
import { TypographyProps } from '@mui/material/Typography';

import { AccordionAppearance } from '../enums';

export const useMapAppearanceToColor = (appearance: AccordionAppearance) => {
  const {
    palette: {
      common: { white },
      grey,
      primary: { main },
    },
  } = useTheme();

  const colorsMap = {
    [AccordionAppearance.LIGHT]: {
      iconColor: white,
      textColor: white as TypographyProps['color'],
    },
    [AccordionAppearance.DARK]: {
      iconColor: main,
      textColor: main as TypographyProps['color'],
    },
    [AccordionAppearance.GREY]: {
      iconColor: grey[600],
      textColor: grey[600] as TypographyProps['color'],
    },
  };

  return colorsMap[appearance];
};
