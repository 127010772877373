import { useEffect, useState } from 'react';

import { useGetFaqs, useQueryData, GetFaqsResponse, DEFAULT_OVERRIDE_CACHE_TIME } from '@ecolytiq/js-cms-provider';

import { FaqsData } from 'impactApp/modules/HomePage';

import { useDeps } from 'App/context';

export const useFaqsData = () => {
  const { strapiHttpClientService } = useDeps();

  const [response, setResponse] = useState<FaqsData>({
    faqs: [],
  });
  const { faqs } = response;

  const { data } = useQueryData<{ data: GetFaqsResponse }>(useGetFaqs(strapiHttpClientService), {
    staleTime: DEFAULT_OVERRIDE_CACHE_TIME,
    cacheTime: DEFAULT_OVERRIDE_CACHE_TIME,
  });

  useEffect(() => {
    if (data?.data) {
      setResponse({
        faqs: data.data.faqs.data,
      });
    }
  }, [data, setResponse]);

  return {
    faqs,
  };
};
