import { useEffect, useState } from 'react';
import { Grid, Container } from '@mui/material';

import { Layout, Teaser } from 'App/components';

import { useTranslation } from 'react-i18next';

import { Header, GoBackButton, Button, BackgroundWrapper } from 'components';

import { CheckIcon, WomanWithGroceries } from 'components/Icons';

import { useNavigate } from 'react-router-dom';

import { RoutePaths } from 'impactApp/routes/routePaths';

import { QuestionsStyles, Questions } from '../TransactionDetailsEdit/submodules/Questions';

import { FoodSettings } from './FoodSettings.style';

import { useCurrentAccountData } from './useCurrentAccountData';

export const FoodSettingsPage = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const navigate = useNavigate();
  const { REPORTS } = RoutePaths;
  const { t }: any = useTranslation();
  const { onboardingAnswers } = useCurrentAccountData();

  useEffect(() => {
    document.title = 'CO2 Profile Settings';
  });

  return (
    <FoodSettings.Container>
      <Layout
        hideContainer
        header={
          <Header
            title={t('foodSettings:headerTitle')}
            transparent
            isWhite
            showDesktopNavigation
            hideRightButton
            button={<GoBackButton />}
          />
        }
      >
        <Teaser
          isWhite
          hasFixedHeader
          isAlignCenter
          title={t('foodSettings:title')}
          subtitle={t('foodSettings:subtitle')}
        />
        <BackgroundWrapper>
          <Container maxWidth='xl' disableGutters>
            <Grid flexDirection='column' justifyContent='center' alignItems='center'>
              <Grid item>
                <FoodSettings.QuestionsWrapper>
                  {isSubmitted ? (
                    <Grid justifyContent='flex-start' alignItems='flex-start'>
                      <QuestionsStyles.Content>
                        <QuestionsStyles.SubmitWrapper>
                          <QuestionsStyles.SubmittedIconWrapper>
                            <CheckIcon />
                          </QuestionsStyles.SubmittedIconWrapper>
                          <QuestionsStyles.SubmittedTitle color='black' variant='h6'>
                            {t('foodSettings:submittedTitle')}
                          </QuestionsStyles.SubmittedTitle>
                          <QuestionsStyles.SubmittedSubtitle color='black' variant='subtitle2'>
                            {t('foodSettings:submittedSubtitle')}
                          </QuestionsStyles.SubmittedSubtitle>
                          <WomanWithGroceries />
                          <FoodSettings.ButtonWrapper>
                            <Button
                              iconRight
                              text={t('foodSettings:categoryButton')}
                              onClick={() => navigate(`${REPORTS}/food`)}
                            />
                          </FoodSettings.ButtonWrapper>
                        </QuestionsStyles.SubmitWrapper>
                      </QuestionsStyles.Content>
                    </Grid>
                  ) : (
                    <>
                      <QuestionsStyles.Content>
                        <Questions
                          submitButtonText={t('foodSettings:submitButton')}
                          saveChanges=''
                          subCategoryValue='Profile'
                          transactionAnswers={onboardingAnswers}
                          setIsSubmitted={setIsSubmitted}
                          isTransactionLoop={false}
                          category='PROFILE'
                        />
                      </QuestionsStyles.Content>
                    </>
                  )}
                </FoodSettings.QuestionsWrapper>
              </Grid>
            </Grid>
          </Container>
        </BackgroundWrapper>
      </Layout>
    </FoodSettings.Container>
  );
};
