export type CmsEnvironment = 'development' | 'staging' | 'production';

type CmsEnvironmentLinksObject = {
  fontRegular: string;
  fontSemiBold: string;
  fontBold: string;
  blackLogo: string;
  logo: string;
  loginImage: string;
  footerLogo: string;
};

type CmsEnvironmentLinks = {
  development: CmsEnvironmentLinksObject;
  staging: CmsEnvironmentLinksObject;
  production: CmsEnvironmentLinksObject;
};

export const cmsEnvironmentLinks: CmsEnvironmentLinks = {
  development: {
    fontRegular: `https://ecodeveuwcmsvisa.blob.core.windows.net/ecodeveuwcmsvisa/assets/Open_Sans_Regular_2_0bdb3364e2.woff2`,
    fontSemiBold: `https://ecodeveuwcmsvisa.blob.core.windows.net/ecodeveuwcmsvisa/assets/Open_Sans_Semi_Bold_1_862e4b04d1.woff2`,
    fontBold: `https://ecodeveuwcmsvisa.blob.core.windows.net/ecodeveuwcmsvisa/assets/Open_Sans_Bold_1_c278a133e1.woff2`,
    blackLogo: `https://ecodeveuwcmsvisa.blob.core.windows.net/ecodeveuwcmsvisa/assets/blue_logo_26dfd00a4d_40d0dbe18b.svg`,
    logo: `https://ecodeveuwcmsvisa.blob.core.windows.net/ecodeveuwcmsvisa/assets/Visa_X_ecolytiq_logos_9fd4db361c.svg`,
    loginImage: `https://ecodeveuwcmsvisa.blob.core.windows.net/ecodeveuwcmsvisa/assets/Frame_6149_4_36dcdebaad.png`,
    footerLogo: `https://ecodeveuwcmsvisa.blob.core.windows.net/ecodeveuwcmsvisa/assets/footer_logo_1_5b18ec30a2.svg`,
  },
  staging: {
    fontRegular: `https://ecostaeuwcmsvisa.blob.core.windows.net/ecostaeuwcmsvisa/assets/Open_Sans_Regular_248da31457.woff2`,
    fontSemiBold: `https://ecostaeuwcmsvisa.blob.core.windows.net/ecostaeuwcmsvisa/assets/Open_Sans_Semi_Bold_4b9a83d1cd.woff2`,
    fontBold: `https://ecostaeuwcmsvisa.blob.core.windows.net/ecostaeuwcmsvisa/assets/Open_Sans_Bold_587721f2a5.woff2`,
    blackLogo: `https://ecostaeuwcmsvisa.blob.core.windows.net/ecostaeuwcmsvisa/assets/blue_logo_26dfd00a4d_8775944f9a.svg`,
    logo: `https://ecostaeuwcmsvisa.blob.core.windows.net/ecostaeuwcmsvisa/assets/Visa_X_ecolytiq_logos_dffed74ada.svg`,
    loginImage: `https://ecostaeuwcmsvisa.blob.core.windows.net/ecostaeuwcmsvisa/assets/Frame_6149_3c90df13d6.png`,
    footerLogo: `https://ecostaeuwcmsvisa.blob.core.windows.net/ecostaeuwcmsvisa/assets/footer_logo_3e0d8f437b.svg`,
  },
  production: {
    fontRegular: `https://ecoprodeuwcmsvisa.blob.core.windows.net/ecoprodeuwcmsvisa/assets/Open_Sans_Regular_3_411e49bc68.woff2`,
    fontSemiBold: `https://ecoprodeuwcmsvisa.blob.core.windows.net/ecoprodeuwcmsvisa/assets/Open_Sans_Semi_Bold_2_fa1726938e.woff2`,
    fontBold: `https://ecoprodeuwcmsvisa.blob.core.windows.net/ecoprodeuwcmsvisa/assets/Open_Sans_Bold_2_afe66c42b4.woff2`,
    blackLogo: `https://ecoprodeuwcmsvisa.blob.core.windows.net/ecoprodeuwcmsvisa/assets/blue_logo_26dfd00a4d_1_9143fce372.svg`,
    logo: `https://ecoprodeuwcmsvisa.blob.core.windows.net/ecoprodeuwcmsvisa/assets/Visa_X_ecolytiq_logos_7e225f7808.svg`,
    loginImage: `https://ecoprodeuwcmsvisa.blob.core.windows.net/ecoprodeuwcmsvisa/assets/Frame_6149_5_3ec47f911c.png`,
    footerLogo: `https://ecoprodeuwcmsvisa.blob.core.windows.net/ecoprodeuwcmsvisa/assets/footer_logo_1_b9dd01951e.svg`,
  },
};
