import styled, { css } from 'styled-components';

const CircularProgress = styled('div')<{ isFullHeight?: boolean }>`
  ${({ isFullHeight }) =>
    isFullHeight
      ? css`
          height: 100vh;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        `
      : css`
          position: relative;
          width: 40px;
          margin: 0 auto;
        `};
`;

export const CircularLoadingStyles = {
  CircularProgress,
};
