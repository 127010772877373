import { Typography } from '@mui/material';

import dompurify from 'dompurify';

import { AccordionAppearance, useAccordion } from 'components';

import { ModalProps } from 'components/Modal/types';

import { useTranslation } from 'react-i18next';

import { HowItWorksModalStyles } from './HowItWorksModal.styles';

const sanitizer = dompurify.sanitize;

type HowItWorksModalProps = {
  Modal: ({ content, render }: ModalProps) => JSX.Element;
  faqIndex?: number;
};

export const HowItWorksModal = ({ Modal, faqIndex }: HowItWorksModalProps) => {
  const { t }: any = useTranslation();
  const accordionList = useAccordion(
    t('homePage:howItWorks:list', { returnObjects: true }),
    AccordionAppearance.GREY,
    undefined,
    faqIndex,
  );
  const descriptionContent = t('homePage:howItWorks:description', { returnObjects: true }).map((content: string) => (
    <Typography
      key={content.slice(0, 15)}
      color='grey.600'
      dangerouslySetInnerHTML={{
        __html: sanitizer(content),
      }}
      variant='body2'
    />
  ));

  const linksContent = t('homePage:howItWorks:links', { returnObjects: true }).map((content: string) => (
    <HowItWorksModalStyles.Link
      key={content.slice(0, 15)}
      color='grey.500'
      dangerouslySetInnerHTML={{
        __html: sanitizer(content, { ADD_ATTR: ['target'] }),
      }}
      variant='body2'
    />
  ));

  return (
    <Modal
      slug={t('homePage:howItWorks:slug')}
      content={[
        descriptionContent,
        <HowItWorksModalStyles.Links> {linksContent} </HowItWorksModalStyles.Links>,
        <HowItWorksModalStyles.List key='content' id='how-it-works-list'>
          {accordionList}
        </HowItWorksModalStyles.List>,
      ]}
      render={({ closeButton, content }) => (
        <>
          <HowItWorksModalStyles.Header>
            <Typography variant='h6'>{t('homePage:howItWorks:title')}</Typography>
            {closeButton}
          </HowItWorksModalStyles.Header>
          {content}
        </>
      )}
    />
  );
};
