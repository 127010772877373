import { Layout } from 'App/components';

import { useTranslation } from 'react-i18next';

import dompurify from 'dompurify';

import { PasswordResetStyles } from './PasswordReset.styles';

export const PasswordReset = () => {
  const { t }: any = useTranslation();
  const sanitizer = dompurify.sanitize;

  return (
    <Layout bgColor isFullHeight>
      <PasswordResetStyles.Wrapper>
        <PasswordResetStyles.Icon />
        <PasswordResetStyles.Title
          variant='h6'
          dangerouslySetInnerHTML={{
            __html: sanitizer(t('passwordReset:title')),
          }}
        />
        <PasswordResetStyles.TextWrapper>{t('passwordReset:text')}</PasswordResetStyles.TextWrapper>
      </PasswordResetStyles.Wrapper>
    </Layout>
  );
};
