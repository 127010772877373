import { useEffect } from 'react';

import { Grid, Container, useMediaQuery, Typography } from '@mui/material';

import { theme } from 'App/style';

import { SubCategoriesImages, SubCategories, CategoriesTranslation } from 'App/types';

import {
  Header as LayoutHeader,
  CircularLoading,
  GoBackButton,
  Button,
  NavLink,
  ComparableText,
  MerchantWrapper,
  BackgroundWrapper,
  ButtonAppearance,
} from 'components';

import { HeaderWithValue, Layout, BARS_HEIGHT } from 'App/components';

import {
  convertGramsToLargerUnits,
  formatCurrency,
  formatFullDate,
  useRandomComparablesByIdData,
  useRandomInsightsByIdData,
} from 'App/utils';

import { CurrencySymbol } from 'App/translations';

import { useLocation } from 'react-router-dom';

import { Transaction } from '@ecolytiq/js-data-provider';

import { useTranslation } from 'react-i18next';

import { RoutePaths } from 'impactApp/routes';

import { DidYouKnow } from '../ReportDetails/submodules';

import { TransactionDetails } from './TransactionDetails.style';

export const TransactionDetailsPage = () => {
  const location = useLocation();

  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  const transaction: Transaction = location?.state?.transaction;

  const { i18n, t }: any = useTranslation();

  const { randomInsight } = useRandomInsightsByIdData(transaction?.id);

  const { randomComparable, isLoading } = useRandomComparablesByIdData(transaction?.id);

  useEffect(() => {
    document.title = 'CO2 Transaction Details';
  });

  const insight: any = randomInsight;

  const Insight = insight?.title ? (
    <DidYouKnow
      isLoading={isLoading}
      category={SubCategoriesImages[transaction?.category.value as keyof typeof SubCategoriesImages]}
      content={insight?.text}
      title={insight?.title}
    />
  ) : (
    ''
  );

  if (!transaction) {
    return (
      <Layout header={<LayoutHeader button={<GoBackButton />} />}>
        <TransactionDetails.Wrapper alignItems='center' height={`calc(100vh - ${BARS_HEIGHT})`}>
          <CircularLoading />
        </TransactionDetails.Wrapper>
      </Layout>
    );
  }

  const {
    id,
    date,
    merchant,
    metaCategory,
    co2Footprint: { value: co2Value },
    amount: { value, currencyCode },
    category: { value: subCategoryValue },
  } = transaction;
  const footprint = convertGramsToLargerUnits(co2Value);
  const feedbackLoopCategory = SubCategories[subCategoryValue as keyof typeof SubCategories];

  const { TRANSACTIONS, REPORTS } = RoutePaths;

  const redirectUrlBack = `${REPORTS}/${metaCategory.toLowerCase()}`;

  return (
    <Layout
      hideContainer
      header={
        <LayoutHeader
          transparent
          title={t(CategoriesTranslation[metaCategory as keyof typeof CategoriesTranslation])}
          showDesktopNavigation
          hideRightButton
          button={<GoBackButton url={redirectUrlBack} />}
        />
      }
    >
      <Grid flexDirection='column' justifyContent='center' alignItems='center'>
        <TransactionDetails.Wrapper
          item
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          width='100%'
        >
          <TransactionDetails.Details color='grey.600' variant='h2' sx={{ typography: { lg: 'h1' } }}>
            {formatCurrency(value, currencyCode as CurrencySymbol)}
          </TransactionDetails.Details>
          <HeaderWithValue value={footprint} unitVariant='h6' color='grey.600' valueColor='grey.600' />
          {(randomComparable as any)?.text && (
            <TransactionDetails.ComparableWrapper>
              <ComparableText text={(randomComparable as any)?.text} />
            </TransactionDetails.ComparableWrapper>
          )}
          <Typography marginBottom='28px' maxWidth='196px' color='secondary.main' variant='caption' textAlign='center'>
            {t('transactionDetails:textHelper')}
          </Typography>
          <MerchantWrapper
            date={formatFullDate(date, i18n.language)}
            merchant={merchant?.name || t('common:merchantPlaceholder')}
          />
        </TransactionDetails.Wrapper>
      </Grid>

      <BackgroundWrapper>
        <Container maxWidth={false} disableGutters>
          <TransactionDetails.PaperContainer>
            {feedbackLoopCategory && (
              <NavLink redirectURL={`${TRANSACTIONS}/${id}/edit`} transaction={transaction}>
                <TransactionDetails.ButtonWrapper>
                  <Button
                    appearance={isMd ? ButtonAppearance.SECONDARY : ButtonAppearance.PRIMARY}
                    text={t('transactionDetails:feedbackLoopButton')}
                  />
                </TransactionDetails.ButtonWrapper>
              </NavLink>
            )}

            <TransactionDetails.InsightsWrapper>
              <TransactionDetails.InsightsContainer>{Insight}</TransactionDetails.InsightsContainer>
            </TransactionDetails.InsightsWrapper>
          </TransactionDetails.PaperContainer>
        </Container>
      </BackgroundWrapper>
    </Layout>
  );
};
