import { SVGProps } from 'react';

export const EditIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg {...props} xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M2.62509 21.9938C2.46009 21.9938 2.29926 21.9272 2.1826 21.8105C2.02677 21.6547 1.96427 21.4247 2.0201 21.2105L3.53674 15.4273C3.54007 15.4156 3.54424 15.4014 3.55007 15.3881C3.55341 15.3748 3.55924 15.3581 3.56674 15.3414C3.57257 15.3281 3.58507 15.3039 3.59257 15.2914C3.59507 15.2856 3.60257 15.2714 3.61174 15.2573C3.63174 15.2256 3.65424 15.1956 3.6784 15.1681C3.68507 15.1598 3.70757 15.1365 3.7109 15.1331L15.774 3.07248C16.4599 2.38333 17.3748 2.0025 18.3498 2C19.3306 2 20.2456 2.37749 20.934 3.06248C20.9373 3.06582 20.9473 3.07582 20.9498 3.07832C21.6381 3.7833 22.0106 4.70746 21.9998 5.68244C21.9881 6.65659 21.5981 7.56824 20.9006 8.24906L8.85249 20.2947C8.83915 20.308 8.81082 20.3314 8.79499 20.3422C8.78915 20.348 8.77332 20.3597 8.75582 20.3714C8.69832 20.4114 8.63499 20.4405 8.56916 20.458L2.78425 21.973C2.73259 21.9863 2.67925 21.9938 2.62509 21.9938ZM3.50091 20.493L7.55417 19.4314L6.65752 17.3389L4.56256 16.4448L3.50091 20.493ZM8.62499 18.7547L17.4948 9.88654L16.244 8.63656L7.875 17.0039L8.62499 18.7547ZM6.99002 16.1214L15.3599 7.75324L14.1099 6.50409L5.23838 15.3731L6.99002 16.1214ZM18.379 9.00322L19.2356 8.14657L15.8507 4.76329L14.9941 5.61994L18.379 9.00322ZM20.1181 7.26075C20.5198 6.82242 20.7431 6.26243 20.7498 5.66744C20.7573 5.02662 20.5148 4.42163 20.0673 3.9633C20.064 3.95997 20.0548 3.94997 20.0531 3.9483C19.5998 3.49748 18.9982 3.24915 18.3598 3.24915L18.3532 2.93665L18.354 3.24915C17.749 3.25081 17.1798 3.47414 16.7374 3.87997L20.1181 7.26075Z'
      fill='#7B4EFA'
    />
  </svg>
);
