import { SVGProps } from 'react';

interface ENFlagProps {
  props?: SVGProps<SVGSVGElement>;
}

export const ENFlag = ({ props }: ENFlagProps): JSX.Element => (
  <svg {...props} width='20' height='14' viewBox='0 0 20 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0.814941 0.111076H18.8149V12.9682H0.814941V0.111076Z' fill='#41479B' />
    <path
      d='M18.8149 11.2941L12.1587 6.53966L18.8149 1.78519V0.111076H16.4712L9.81494 4.86555L3.15869 0.111076H0.814941V1.78518L7.4712 6.53966L0.814941 11.2941V12.9682H3.15872L9.81494 8.21377L16.4712 12.9682H18.8149V11.2941Z'
      fill='#E9E9E9'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.814941 0.780923V0.111076H1.75216L9.81469 5.87004L17.8772 0.111076H18.8147L19.1522 0.539674L10.7522 6.53969L19.1522 12.5397L18.4772 13.3968L9.81469 7.20933L1.1522 13.3968L0.814941 12.9686V12.2984L8.87719 6.53969L0.814941 0.780923Z'
      fill='#DC251C'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.56494 4.39679V0.111076H12.0649V4.39679H18.8149V8.6825H12.0649V12.9682H7.56494V8.6825H0.814941V4.39679H7.56494Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.68994 5.46822V0.111076H10.9399V5.46822H18.8149V7.61108H10.9399V12.9682H8.68994V7.61108H0.814941V5.46822H8.68994Z'
      fill='#DC251C'
    />
  </svg>
);
