import { Avatar as MuiAvatar } from '@mui/material';
import styled from 'styled-components';

import { AvatarSize } from './types';

const AVATAR_SIZE = {
  [AvatarSize.SMALL]: {
    size: '28px',
    icon: '18px',
  },
  [AvatarSize.MEDIUM]: {
    size: '40px',
    icon: '24px',
  },
  [AvatarSize.LARGE]: {
    size: '40px',
    icon: '28px',
  },
};

export const AvatarStyles = styled(MuiAvatar)<{
  color?: string;
  $avatarSize: AvatarSize;
  $backgroundIcon?: string;
}>`
  color: ${({
    theme: {
      palette: {
        common: { white },
      },
    },
  }) => white};

  background: ${({ $backgroundIcon, theme }) => $backgroundIcon || theme.palette.common.white};

  width: ${({ $avatarSize }) => AVATAR_SIZE[$avatarSize].size};
  height: ${({ $avatarSize }) => AVATAR_SIZE[$avatarSize].size};

  svg {
    width: ${({ $avatarSize }) => AVATAR_SIZE[$avatarSize].icon};
    height: ${({ $avatarSize }) => AVATAR_SIZE[$avatarSize].icon};
    path {
      stroke: ${({ color }) => color};
    }
  }
`;
