import { Typography } from '@mui/material';
import styled from 'styled-components';

export const ComparableTextWrapper = styled(Typography)`
  background: ${({ theme }) => `${theme.palette.custom.comparable.background}33`};
  color: ${({ theme }) => theme.palette.secondary.main};
  max-width: 252px;
  padding: 8px 20px;
  width: fit-content;
  margin: 0 auto;
  border-radius: 4px;
  text-align: center;
`;
