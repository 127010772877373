import { SVGProps, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

interface LeafProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const Leaf = ({ props, isActive }: LeafProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg {...props} width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_7253_31520)'>
        <path
          d='M28.0223 35.2586C28.0223 35.2586 29.4508 38.1824 31.8543 37.9633C36.727 37.518 36.4012 31.041 38.1825 28.7535C36.6756 28.0031 35.0125 27.6198 33.3291 27.6351C31.6458 27.6504 29.9899 28.0638 28.4969 28.8414C25.9727 30.2734 25.7934 32.6734 28.0223 35.2586Z'
          stroke={isActiveState ? '#ffffff' : main}
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M31.4688 32.2879C31.4688 32.2879 28.3048 32.6805 26.0513 38.1836'
          stroke={isActiveState ? '#ffffff' : main}
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M32.9102 24.1223V18.8488L26.7578 21.8957V18.8488L21.4844 21.9016V18.8547L16.2109 21.9016L15.332 17.0969H12.6953L11.8164 22.3703V31.1594C11.8164 31.6256 12.0016 32.0727 12.3313 32.4023C12.6609 32.732 13.108 32.9172 13.5742 32.9172H22.3633'
          stroke={isActiveState ? '#ffffff' : main}
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M14.4531 14.4531C14.4531 14.4531 14.4531 12.6953 18.8477 12.6953C22.3633 12.6953 23.2422 11.8164 23.2422 11.8164'
          stroke={isActiveState ? '#ffffff' : main}
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_7253_31520'>
          <rect width='30' height='30' fill='white' transform='translate(10 10)' />
        </clipPath>
      </defs>
    </svg>
  );
};
